import React, { FocusEvent, KeyboardEvent, ChangeEvent, useCallback, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isFirefox } from "react-device-detect";
import axios, { AxiosError } from "axios";

import { getCurrentYearMonth, isCybersourceTimedout, getTimedoutDummyToken, keyUpDateEvent } from "../../utils/calendarUtil";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import { isValidState, isValidUSZip } from "../../utils/UsStates";
import { extractDomain } from "../../utils/utils";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tceppsProxyActions } from "../../redux-store/reducer/tceppsProxy_slice";
import {
  tokenCreatedAction,
  microformInitializedAction,
  // permTokenCreateAction,
  unmountAction,
  contextLoadedAction,
} from "../../redux-store/reducer/paymentSlice";
import { config } from "../../config/uiconfig";
import PaymentService from "../../service/paymentService";

import "./balloontip.css";
import classesRoss from "./S21RossComponent.module.css";

import {
  ezd_mergeForCommon,
  ezd_customLoadHandler,
  ezd_addListener,
  checkBrowser,
  keyCheck,
  ezd_addBalloonTipLoadHandler,
  tceppsBalloonTipHandler,
  ezd_hideBalloonTooTip,
  ezd_showBalloonTooTip,
  ezd_hideBalloonTooTipWithoutEvent,
} from "./creditCardForm";
import { profileAdd, newOrder } from "../../resources/creditCardFormPage/js/tcepps";
import { parseToken } from "../../utils/utils";
import { componentVarObject, ComponentVarProps } from "../../utils/ComponentConstant";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
let testingToBeApp = false;
var creditCardMicroNumber: any;

const FORMINITIZING = "Browser initializing. Please wait and retry.";
const INVALIDAXIOSMESSAGE = "AxiosError. ";
const OTHERSERVICEOPERATION = ["toggleAddProfile", "addressValidate", "clearCreditCard"];

const inputStyle = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
} as React.CSSProperties;
const inputStyleCard = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
  color: "grey",
} as React.CSSProperties;
const tableStyle = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
  align: "left",
  display: "flex",
  tableLayout: "fixed",
} as React.CSSProperties;
const creditCardStyle = {
  fontSize: "8pt",
  fontFamily: "'Arial',sans-serif",
  height: "20px",
  width: "140px", //"163px"
  margin: "0",
  paddingLeft: "2",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  backgroundColor: "field",
  border: "1px solid #858585",
  borderImage: "initial",
  borderWidth: "1px",
  orderStyle: "inset",
} as React.CSSProperties;
const flexStyles = {
  input: {
    "font-size": "8pt",
    "font-family": "'Arial',sans-serif",
    cursor: "text",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "black" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;

const toDay = new Date();

export function S21RossComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [addressline1, setAddressline1] = useState("");
  const [addressline2, setAddressline2] = useState("");
  const [billingName, setBillingName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [creditCardType, setCreditCardType] = useState("");
  const [divisionCd, setDivisionCd] = useState("");
  const [expirationMonthYear, setExpirationMonthYear] = useState("");
  const [postCode, setPostCode] = useState("");
  const [state, setState] = useState("");
  const [serviceOperation, setServiceOperation] = useState("");

  const [addressline1Disabled, setAddressline1Disabled] = useState(false);
  const [addressline2Disabled, setAddressline2Disabled] = useState(false);
  const [billingNameDisabled, setBillingNameDisabled] = useState(false);
  const [cityDisabled, setCityDisabled] = useState(false);
  // const [countryDisabled, setCountryDisabled] = useState(false);
  const [creditCardTypeDisabled, setCreditCardTypeDisabled] = useState(false);
  const [divisionCdDisabled, setDivisionCdDisabled] = useState(false);
  const [expirationMonthYearDisabled, setExpirationMonthYearDisabled] = useState(false);
  const [postCodeDisabled, setPostCodeDisabled] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(false);

  const addressline1Ref = useRef<HTMLInputElement>(null);
  const addressline2Ref = useRef<HTMLInputElement>(null);
  const billingNameRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const creditCardTypeRef = useRef<HTMLSelectElement>(null);
  const divisionCdRef = useRef<HTMLSelectElement>(null);
  const expirationMonthYearRef = useRef<HTMLInputElement>(null);
  const postCodeRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const topDivRef = useRef<HTMLDivElement>(null);
  const adjustedAmountRef = useRef<string>("");
  const orderIdRef = useRef<string>("");
  const orbitalConnectionUsernameRef = useRef<string>("");
  const orbitalConnectionPasswordRef = useRef<string>("");
  const orderDefaultDescriptionRef = useRef<string>("");
  const orderDefaultAmountRef = useRef<string>("");
  const switchSoloIssueNumRef = useRef<string>("");
  const switchSoloCardStartDateRef = useRef<string>("");
  const authenticationECIIndRef = useRef<string>("");

  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);

  const [addressline1ClassName, setAddressline1ClassName] = useState("");
  const [billingNameClassName, setBillingNameClassName] = useState("");
  const [cityClassName, setCityClassName] = useState("");
  const [countryClassName, setCountryClassName] = useState("");
  const [divisionCdClassName, setDivisionCdClassName] = useState("");
  const [expirationMonthYearClassName, setExpirationMonthYearClassName] = useState("");
  const [postCodeClassName, setPostCodeClassName] = useState("");
  const [stateClassName, setStateClassName] = useState("");

  const [creditcardValid, setCreditcardValid] = useState(false);
  const [initializationComplete, setInitializationComplete] = useState(false);
  const [formReady, setFormReady] = useState(false);

  const [showBrowserMesssage, setShowBrowserMesssage] = useState(false);
  const [browserInitializingMesssage, setBrowserInitializingMesssage] = useState("");
  const [tceppsBorderStateChanged, setTceppsBorderStateChanged] = useState(false);
  const [proxyUrl, setProxyUrl] = useState("");

  const { appKey } = useParams();

  const clearCreditCard = () => {
    setAddressline1("");
    setAddressline2("");
    setBillingName("");
    setCity("");
    setCountry("US");
    // setCreditCardNumber("");
    setCreditCardType("");
    setDivisionCd("");
    setExpirationMonthYear("");
    setPostCode("");
    setState("");
  };

  // useEffect if screen/page input parameters change
  useEffect(() => {
    const clearCreditCard = () => {
      setAddressline1("");
      setAddressline2("");
      setBillingName("");
      setCity("");
      setCountry("US");
      // setCreditCardNumber("");
      setCreditCardType("");
      setDivisionCd("");
      setExpirationMonthYear("");
      setPostCode("");
      setState("");
    };

    const initToggleAddProfile = (addProfile: string) => {
      const disabled = addProfile === "true" ? false : true;

      setAddressline1Disabled(disabled);
      setAddressline2Disabled(disabled);
      setBillingNameDisabled(disabled);
      setCityDisabled(disabled);
      // setCountryDisabled(disabled);
      setCreditCardTypeDisabled(disabled);
      setDivisionCdDisabled(disabled);
      setExpirationMonthYearDisabled(disabled);
      setPostCodeDisabled(disabled);
      setStateDisabled(disabled);
      if (!disabled) {
        setCountry("US");
        setCreditCardType("");
        setDivisionCd("");
        if (testingToBeApp) {
          // console.log(TceppsTestData.ToBeAppTestData.S21ROSS);
          setBillingName(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.billingName"]);
          setExpirationMonthYear(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.expirationMonthYear"]);
          setAddressline1(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.addressline1"]);
          setAddressline2(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.addressline2"]);
          setCity(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.city"]);
          setState(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.state"]);
          setPostCode(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.postCode"]);
          setDivisionCd(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.division"]);
        }
      }

      if (addProfile === "false") {
        tceppsBalloonTipHandler.clearBalloonTips();
        clearCreditCard();
      }
    };

    if (tceppsState.app !== undefined && tceppsState.app.length > 0 && !componentObjRef.current.tceppsStateChanged) {
      try {
        initToggleAddProfile(tceppsState.enableNewCrCardFlag);
        // tceppsBalloonTipHandler.addToCheckElements("creditCardNumber");
        tceppsBalloonTipHandler.addToCheckElements("creditCardType");
        tceppsBalloonTipHandler.addToCheckElements("billingName");
        tceppsBalloonTipHandler.addToCheckElements("expirationMonthYear");
        tceppsBalloonTipHandler.addToCheckElements("divisionCd");
        tceppsBalloonTipHandler.addToCheckElements("addressline1");
        tceppsBalloonTipHandler.addToCheckElements("addressline2");
        tceppsBalloonTipHandler.addToCheckElements("city");
        tceppsBalloonTipHandler.addToCheckElements("state");
        tceppsBalloonTipHandler.addToCheckElements("postCode");
        tceppsBalloonTipHandler.addToCheckElements("country");
        ezd_addListener(document.body, "keydown", function (event: any) {
          keyCheck(event);
        });
        if (checkBrowser.isIe()) {
          ezd_addListener(document.body, "help", function (event: any) {
            return false;
          });
        }
      } catch (exception) {
        // console.log("Exception 1...1<" + exception + ">");
      }

      try {
        // console.log("calling....ezd_customLoadHandler");
        ezd_customLoadHandler();
        // console.log("calling....ezd_mergeForCommon");
        ezd_mergeForCommon("S21ROSS");
      } catch (exception) {
        // console.log("Exception 2...<" + exception + ">");
      }

      componentObjRef.current.tceppsStateChanged = true;
      // console.log("return useEffect....1");
    }
  }, [tceppsState.app, tceppsState.enableNewCrCardFlag, dispatch]);

  const addressValidate = useCallback(() => {
    let warnMessage = "addressValidate";
    tceppsBalloonTipHandler.validateError("city", warnMessage, "code99", "warn");
    tceppsBalloonTipHandler.validateError("state", warnMessage, "code99", "warn");
    tceppsBalloonTipHandler.validateError("postCode", warnMessage, "code99", "warn");
    tceppsBalloonTipHandler.validateError("country", warnMessage, "code99", "warn");
    ezd_addBalloonTipLoadHandler(tceppsBalloonTipHandler.validateElements);

    // cancel(document.getElementById("tcepps"));
  }, []);

  // useEffect if screen/page input parameters change
  useEffect(() => {
    if (tceppsState.app !== undefined && tceppsState.app.length > 0 && !tceppsBorderStateChanged) {
      try {
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.border = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.margin = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.padding = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.position = "relative";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.overflow = "auto";
        (document.querySelector("#root") as HTMLDivElement).style.border = "0";
        (document.querySelector("#body") as HTMLDivElement).style.border = "0";
        (document.querySelector("#root") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#body") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#root") as HTMLDivElement).style.padding = "0";
        (document.querySelector("#body") as HTMLDivElement).style.padding = "0";
      } catch (exception) {}

      setTceppsBorderStateChanged(true);
    }
  }, [tceppsState.app, tceppsState.enableNewCrCardFlag, tceppsBorderStateChanged]);

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";
    if (formReady) {
      let setFocusSet = false;
      tceppsBalloonTipHandler.clearBalloonTips();
      if (componentObjRef.current.cardMessage !== "" || !creditcardValid) {
        // tceppsBalloonTipHandler.validateError("creditCardNumber", "Please enter valid Credit Card Number.", "code01", "err");
        tceppsBalloonTipHandler.validateError("creditCardType", "Please enter valid Credit Card Number.", "code01", "err");
        errorMessage += componentObjRef.current.cardMessage;
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.backgroundColor = "#ff6060";
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.backgroundColor = "white";
      }
      if (
        expirationMonthYearRef.current?.value === "" ||
        expirationMonthYearRef.current?.value.length !== 7 ||
        !expirationMonthYearRef.current?.value.match("^((0[1-9])|(1[0-2]))/([2-9][0-9][0-9][0-9])$")
      ) {
        tceppsBalloonTipHandler.validateError("expirationMonthYear", "Please enter valid Expiry Date.", "code03", "err");
        errorMessage += "[Invalid Expiry Date.]";
        setExpirationMonthYearClassName(classesRoss.pErr);
        (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          expirationMonthYearRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        let cardYearMonth = expirationMonthYearRef.current?.value.substring(3, 8) + expirationMonthYearRef.current?.value.substring(0, 2);
        if (cardYearMonth < getCurrentYearMonth()) {
          tceppsBalloonTipHandler.validateError("expirationMonthYear", "The card is expired.", "code03", "err");
          errorMessage += "[The card is expired.]";
          setExpirationMonthYearClassName(classesRoss.pErr);
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "#ff6060";
          if (!setFocusSet) {
            expirationMonthYearRef.current?.focus();
            setFocusSet = true;
          }
        } else {
          setExpirationMonthYearClassName("");
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "white";
        }
      }
      if (divisionCdRef.current?.value === "") {
        tceppsBalloonTipHandler.validateError("divisionCd", "Please select Division.", "code10", "err");
        errorMessage += "[Invalid Division .]";
        setDivisionCdClassName(classesRoss.pErr);
        (document.querySelector("#divisionCd") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          divisionCdRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        setDivisionCdClassName("");
        (document.querySelector("#divisionCd") as HTMLInputElement).style.backgroundColor = "white";
      }

      if (billingNameRef.current?.value === "") {
        tceppsBalloonTipHandler.validateError("billingName", "Please enter valid Card Holder Name.", "code04", "err");
        errorMessage += "[Invalid Card Holder Name.]";
        setBillingNameClassName(classesRoss.pErr);
        (document.querySelector("#billingName") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          billingNameRef.current.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#billingName") as HTMLInputElement).style.backgroundColor = "white";
        setBillingNameClassName("");
      }
      if (addressline1Ref.current?.value === "") {
        tceppsBalloonTipHandler.validateError("addressline1", "Please enter valid Addressline 1.", "code05", "err");
        errorMessage += "[Invalid Addressline 1.]";
        setAddressline1ClassName(classesRoss.pErr);
        (document.querySelector("#addressline1") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          addressline1Ref.current?.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#addressline1") as HTMLInputElement).style.backgroundColor = "white";
        setAddressline1ClassName("");
      }
      if (cityRef.current?.value === "") {
        tceppsBalloonTipHandler.validateError("city", "Please enter valid City.", "code06", "err");
        errorMessage += "[Invalid City.]";
        setCityClassName(classesRoss.pErr);
        (document.querySelector("#city") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          cityRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#city") as HTMLInputElement).style.backgroundColor = "white";
        setCityClassName("");
      }
      if (stateRef.current?.value === "" || !isValidState(stateRef.current?.value as string)) {
        tceppsBalloonTipHandler.validateError("state", "Please enter valid State.", "code07", "err");
        errorMessage += "[Invalid State.]";
        setStateClassName(classesRoss.pErr);
        (document.querySelector("#state") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          stateRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#state") as HTMLInputElement).style.backgroundColor = "white";
        setStateClassName("");
      }
      if (postCodeRef.current?.value === "" || !isValidUSZip(postCodeRef.current?.value as string)) {
        tceppsBalloonTipHandler.validateError("postCode", "Please enter valid Post Code.", "code08", "err");
        errorMessage += "[Invalid Post Code.]";
        setPostCodeClassName(classesRoss.pErr);
        (document.querySelector("#postCode") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          postCodeRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#postCode") as HTMLInputElement).style.backgroundColor = "white";
        setPostCodeClassName("");
      }

      if (countryRef.current?.value === "") {
        tceppsBalloonTipHandler.validateError("country", "Please enter valid Country.", "code09", "err");
        errorMessage += "[Invalid Country.]";
        setCountryClassName(classesRoss.pErr);
        (document.querySelector("#country") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          countryRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#country") as HTMLInputElement).style.backgroundColor = "white";
        setCountryClassName("");
      }
    } else {
      errorMessage = FORMINITIZING;
      setShowBrowserMesssage(true);
      setBrowserInitializingMesssage(FORMINITIZING);
      return false;
    }

    if (errorMessage === "") {
      return true;
    } else {
      ezd_addBalloonTipLoadHandler(tceppsBalloonTipHandler.validateElements);
      // cancel(document.getElementById("tcepps"));
      return false;
    }
  }, [creditcardValid, formReady]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardTypeDisabled(disabled);
    setExpirationMonthYearDisabled(disabled);
    setDivisionCdDisabled(disabled);
    setBillingNameDisabled(disabled);
    setAddressline1Disabled(disabled);
    setAddressline2Disabled(disabled);
    setCityDisabled(disabled);
    setStateDisabled(disabled);
    setPostCodeDisabled(disabled);
    // setCountryDisabled(disabled);

    (document.getElementById("creditCardType") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("expirationMonthYear") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("divisionCd") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("billingName") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("addressline1") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("addressline2") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("city") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("state") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("postCode") as HTMLInputElement).disabled = !disabled;
    // (document.getElementById("country") as HTMLInputElement).disabled = !disabled;
  }, []);

  const toggleAddProfile = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardTypeDisabled(disabled);
    setExpirationMonthYearDisabled(disabled);
    setDivisionCdDisabled(disabled);
    setBillingNameDisabled(disabled);
    setAddressline1Disabled(disabled);
    setAddressline2Disabled(disabled);
    setCityDisabled(disabled);
    setStateDisabled(disabled);
    setPostCodeDisabled(disabled);
    // setCountryDisabled(disabled);
    if (!disabled) {
      setCountry("US");
      setCreditCardType("");
      setDivisionCd("");
      if (testingToBeApp) {
        // console.log(TceppsTestData.ToBeAppTestData.S21ROSS);
        setBillingName(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.billingName"]);
        setExpirationMonthYear(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.expirationMonthYear"]);
        setAddressline1(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.addressline1"]);
        setAddressline2(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.addressline2"]);
        setCity(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.city"]);
        setState(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.state"]);
        setPostCode(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.postCode"]);
        setDivisionCd(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.division"]);
      }
    }

    if (addProfile === "false") {
      tceppsBalloonTipHandler.clearBalloonTips();
      clearCreditCard();
    }
    // cancel(tcepps);
    // console.log("toggleAddProfile cancel");
    //cancel(document.getElementById("tcepps"));
    // console.log("toggleAddProfile cancel...out");
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    // console.log("micoFormtoken<" + micoFormtoken + ">");
    const tokenArray = micoFormtoken.split(".");
    // console.log("tokenArray<" + tokenArray[1] + ">");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    // console.log("JSON-->" + decodedString);
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    // console.log("JSON.jti-->" + jti);
    // console.log("JSON.maskedValue-->" + lastFourDigits);
    return [jti, lastFourDigits];
  };

  const invokeNewOrder_Reversal = useCallback(
    (pno: any, micoFormtoken: string, tcepps: any) => {
      componentObjRef.current.processingForm = true;
      let expMonthYear = "";
      if (expirationMonthYearRef.current?.value) {
        expMonthYear = expirationMonthYearRef.current?.value.substring(3, 8) + expirationMonthYearRef.current?.value.substring(0, 2);
      }
      pno.cardBrand.value = creditCardTypeRef.current?.value;
      pno.division.value = divisionCdRef.current?.value;
      // pno.ccAccountNum.value = creditCardNumberRef.current?.value;
      const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);
      pno.ccAccountNum.value = "TT=" + jti;
      pno.ccExp.value = expMonthYear;
      pno.avsName.value = billingNameRef.current?.value;
      pno.avsAddress1.value = addressline1Ref.current?.value;
      pno.avsAddress2.value = addressline2Ref.current?.value;
      pno.avsCity.value = cityRef.current?.value;
      pno.avsState.value = stateRef.current?.value;
      pno.avsZip.value = postCodeRef.current?.value;
      pno.avsCountryCode.value = countryRef.current?.value;
      pno.merchantID.value = divisionCdRef.current?.value;
      pno.orderID.value = orderIdRef.current;
      tcepps.lastFourDigits.value = lastFourDigits;
      tcepps.serviceOperation.value = "newOrder_reversal";
      if (developmentEnv) {
        const address = pno.avsAddress1.value + pno.avsAddress2.value;
        let amount = "000";
        if (adjustedAmountRef.current) {
          amount = adjustedAmountRef.current;
        }
        if (address) {
          if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
            amount = "40100";
          }
          if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
            amount = "283600";
          }
          if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
            amount = "700500";
            // cvv = "111";
          }
          // if (address.toLowerCase().indexOf("testapifailure") >= 0) {
          //   email = "abcd@";
          // }
          if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
            amount = "250900"; //2509 2521
          }
          if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
            amount = "252100"; //2509 2521
          }

          if (amount !== "000") {
            pno.amount.value = amount;
          }
        }
      }
      toggleFields("false");
      try {
        newOrder(document.getElementById("tcepps"), pno);
      } catch (err: any) {
        // console.log(err);
        let errorMessage = "Call to submit form <NewOrder_Reversal> failed. " + err.message + ". Please check console.";
        tceppsBalloonTipHandler.validateError("billingName", errorMessage, "code04", "err");
        setBillingNameClassName(classesRoss.pErr);
        if (billingNameRef.current) {
          billingNameRef.current.focus();
        }
        ezd_addBalloonTipLoadHandler(tceppsBalloonTipHandler.validateElements);
      }
    },
    [toggleFields]
  );

  const invokeProfileAdd = useCallback(
    (ppa: any, micoFormtoken: string, tcepps: any) => {
      componentObjRef.current.processingForm = true;
      let expMonthYear = "";
      if (expirationMonthYearRef.current?.value) {
        expMonthYear = expirationMonthYearRef.current?.value.substring(3, 8) + expirationMonthYearRef.current?.value.substring(0, 2);
      }
      ppa.cardBrand.value = creditCardTypeRef.current?.value;
      ppa.division.value = divisionCdRef.current?.value;
      // ppa.ccAccountNum.value = creditCardNumberRef.current?.value;
      const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);
      ppa.ccAccountNum.value = "TT=" + jti;
      ppa.ccExp.value = expMonthYear;
      ppa.customerName.value = billingNameRef.current?.value;
      ppa.customerAddress1.value = addressline1Ref.current?.value;
      ppa.customerAddress2.value = addressline2Ref.current?.value;
      ppa.customerCity.value = cityRef.current?.value;
      ppa.customerState.value = stateRef.current?.value;
      ppa.customerZIP.value = postCodeRef.current?.value;
      ppa.customerCountryCode.value = countryRef.current?.value;
      ppa.merchantID.value = divisionCdRef.current?.value;
      tcepps.lastFourDigits.value = lastFourDigits;
      tcepps.serviceOperation.value = "profileAdd";
      if (developmentEnv) {
        const address = ppa.customerAddress1.value + ppa.customerAddress2.value;
        let amount = "0.00";
        if (address) {
          if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
            amount = "40100";
          }
          if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
            amount = "283600";
          }
          if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
            amount = "700500";
            // cvv = "111";
          }
          // if (address.toLowerCase().indexOf("testapifailure") >= 0) {
          //   email = "abcd@";
          // }
          if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
            amount = "250900"; //2509 2521
          }
          if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
            amount = "252100"; //2509 2521
          }

          if (amount !== "0.00") {
            ppa.amount.value = amount;
          }
        }
      }
      // console.log("window.location.origin<" + window.location.origin + ">");
      toggleFields("false");
      try {
        profileAdd(document.getElementById("tcepps"), ppa);
      } catch (err: any) {
        // console.log(err);
        let errorMessage = "Call to submit form <ProfileAdd> failed. " + err.message + ". Please check console.";
        tceppsBalloonTipHandler.validateError("billingName", errorMessage, "code04", "err");
        setBillingNameClassName(classesRoss.pErr);
        if (billingNameRef.current) {
          billingNameRef.current.focus();
        }
        ezd_addBalloonTipLoadHandler(tceppsBalloonTipHandler.validateElements);
      }
    },
    [toggleFields]
  );

  const createCybersourceToken = useCallback(
    (event: any) => {
      if (isCybersourceTimedout(toDay)) {
        const { token, jti, lastFourDigits } = getTimedoutDummyToken();
        dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
      } else {
        componentObjRef.current.cardMessage = "";

        const options = {
          expirationMonth: expirationMonthYearRef.current?.value.substring(0, 2),
          expirationYear: expirationMonthYearRef.current?.value.substring(3, 8),
        };

        // async function createMicroformToken(options: any) {
        paymentInfo.microform.createToken(options, (err: Error, token: any) => {
          if (err) {
            // console.log(err);
            // let varCreditCardMessage = "";
            // let varCvvMessage = "";
            let newErrMsg = "";
            // handle error
            // @ts-ignore
            switch (err.reason) {
              case "CREATE_TOKEN_NO_FIELDS_LOADED":
                newErrMsg = "991: Token creation error, no fields have been loaded." + err.message;
                break;
              case "CREATE_TOKEN_TIMEOUT":
                newErrMsg = "991: createToken call was unable to proceed." + err.message;
                break;
              case "CREATE_TOKEN_XHR_ERROR":
                newErrMsg = "991: Network error when attempting to create a token." + err.message;
                break;
              case "CREATE_TOKEN_NO_FIELDS":
                newErrMsg = "991: Data fields are unavailable for collection." + err.message;
                break;
              case "CREATE_TOKEN_VALIDATION_PARAMS":
                newErrMsg = "991: Issue with parameters supplied to createToken." + err.message;
                break;
              case "CREATE_TOKEN_VALIDATION_FIELDS":
                // @ts-ignore
                if (err.details && err.details.length > 0) {
                  // @ts-ignore
                  if (err.details.length > 1) {
                    // varCreditCardMessage = "Please enter a credit card number.";
                    // varCvvMessage = "Please check that your Credit card verification code is correct or contact your card issuer.";
                    newErrMsg =
                      "Please enter a credit card number. Please check that your Credit card verification code is correct or contact your card issuer.";
                    // @ts-ignore
                  } else if (err.details[0].location === "number") {
                    newErrMsg = "Please enter a credit card number.";
                    // varCreditCardMessage = newErrMsg;
                    // @ts-ignore
                  } else if (err.details[0].location === "securityCode") {
                    newErrMsg = "Please check that your Credit card verification code is correct or contact your card issuer.";
                    // varCvvMessage = newErrMsg;
                  } else {
                    newErrMsg = err.message;
                  }
                } else {
                  newErrMsg = err.message;
                }
                break;
              case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
                newErrMsg = "991: Server-side validation rejects the createToken request." + err.message;
                break;
              case "CREATE_TOKEN_UNABLE_TO_START":
                newErrMsg = "991: Loaded field was able to handle the createToken request." + err.message;
                break;
              default:
                newErrMsg = "991: Unknown error:" + err.message;
                break;
            }
            componentObjRef.current.cardMessage = newErrMsg;
          } else {
            const { jti, lastFourDigits } = parseToken(token);
            dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
            //   dispatch(tokenCreatedAction(token));
          }
          checkCreditCard();
        });
      }
    },
    [paymentInfo.microform, dispatch, checkCreditCard]
  );

  // useEffect when new token is generated submit form
  useEffect(() => {
    if (!componentObjRef.current.processingForm && paymentInfo.token) {
      if (checkCreditCard()) {
        if ((document.getElementById("serviceOperation") as HTMLInputElement).value === "profileAdd" || serviceOperation === "profileAdd") {
          invokeProfileAdd(document.getElementById("ppa"), paymentInfo.token, document.getElementById("tcepps"));
        } else if (
          (document.getElementById("serviceOperation") as HTMLInputElement).value === "newOrder_reversal" ||
          serviceOperation === "newOrder_reversal"
        ) {
          invokeNewOrder_Reversal(document.getElementById("pno"), paymentInfo.token, document.getElementById("tcepps"));
        }
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeProfileAdd, invokeNewOrder_Reversal, serviceOperation]);

  const invokeTceppsServiceOperation = useCallback(
    async (serviceOperation: string, addProfile: string, event: MessageEvent) => {
      // console.log("invokeTceppsServiceOperation....called " + serviceOperation);
      if (serviceOperation !== "") {
        // console.log("serviceOperation<" + serviceOperation + ">, addProfile<" + addProfile + ">,<");
        switch (serviceOperation) {
          case "toggleAddProfile":
            toggleAddProfile(addProfile);
            break;
          case "addressValidate":
            addressValidate();
            break;
          case "clearCreditCard":
            clearCreditCard();
            break;
          default:
            setServiceOperation(serviceOperation);
            // console.log("set serviceOperation<" + serviceOperation + ">");
            createCybersourceToken(event);
        }
        // console.log("invokeTceppsServiceOperation....called " + serviceOperation);
      }
    },
    [addressValidate, createCybersourceToken, toggleAddProfile]
  );

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (!formReady && event.data !== undefined && event.data.type && event.data.type === "processTransaction") {
        setShowBrowserMesssage(true);
        setBrowserInitializingMesssage(FORMINITIZING);
      } else if (
        formReady &&
        event.data &&
        event.data.type &&
        event.data.type === "processTransaction" &&
        event.data.payload &&
        event.data.payload.length > 0
      ) {
        if (
          (!OTHERSERVICEOPERATION.find((element) => element === event.data.payload) && checkCreditCard()) ||
          OTHERSERVICEOPERATION.find((element) => element === event.data.payload)
        ) {
          let windowsUrl = window.location.href;
          windowsUrl = windowsUrl.substring(0, windowsUrl.indexOf("/", 8));
          if (windowsUrl.indexOf(":", 8) > 1) {
            windowsUrl = windowsUrl.substring(0, windowsUrl.indexOf(":", 8));
          }
          if (windowsUrl.indexOf("//") > 1) {
            windowsUrl = windowsUrl.substring(windowsUrl.indexOf("//") + 2);
          }
          if (event.origin.startsWith("https://") && event.origin.indexOf(windowsUrl) > 0 && event.data.type === "processTransaction") {
            invokeTceppsServiceOperation(event.data.payload, event.data.addProfile, event);
          } else if (
            event.origin.startsWith("http://") &&
            event.origin.indexOf(windowsUrl) > 0 &&
            event.data.type === "processTransaction"
          ) {
            invokeTceppsServiceOperation(event.data.payload, event.data.addProfile, event);
          } else {
            return;
          }
        }
      } else if (
        event.data &&
        (event.data as any).data !== undefined &&
        (event.data as any).data &&
        ((event.data as any).data as any).focusShift !== undefined &&
        ((event.data as any).data as any).focusShift &&
        (event.data as any).event !== undefined &&
        (event.data as any).event === "blur" &&
        event.currentTarget &&
        (event.currentTarget as any).name !== undefined &&
        (event.currentTarget as any).name &&
        (event.currentTarget as any).name === "creditCardFormIFrame"
      ) {
        if (((event.data as any).data as any).focusShift === "next") {
          expirationMonthYearRef.current?.focus();
        } else if (((event.data as any).data as any).focusShift === "previous") {
          postCodeRef.current?.focus();
        }
      }
    },
    [invokeTceppsServiceOperation, checkCreditCard, formReady]
  );

  // useEffect for windows message
  useEffect(() => {
    // setCollapsed(true);
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    // console.log("added addEventListener");

    return () => {
      // console.log("REMOVING window message listener");
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  useEffect(() => {
    if (tceppsState.app && initializationComplete) {
      setFormReady(true);
      setShowBrowserMesssage(false);
      setBrowserInitializingMesssage("");
    }
  }, [tceppsState.app, initializationComplete]);

  // decrypt the input parameters
  const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
    let decryptParamsResponse: any;

    try {
      const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
      decryptParamsResponse = response.data;
    } catch (err: any) {
      // for this app don't show error
      // if (axios.isAxiosError(err)) {
      //   let errorMessage = INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ".";
      //   validationError(document.getElementById("tcepps"), errorMessage);
      // } else {
      //   // Just a tock error
      //   let errorMessage = "Error in getDecryptRequest. " + err.message + ".";
      //   validationError(document.getElementById("tcepps"), errorMessage);
      // }
      return false;
    }

    if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
      // check for proxy
      let iFrameUrl = document.referrer;
      if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
        let proxyUrlParam = decryptParamsResponse["tcepps.proxyUrl"];
        let successUrlParam = decryptParamsResponse["tcepps.successUrl"];
        if (proxyUrlParam && proxyUrlParam.trim().length > 0) {
          setProxyUrl(proxyUrlParam);
        } else if (successUrlParam && successUrlParam.trim().length > 0) {
          setProxyUrl(successUrlParam);
        }
      }

      adjustedAmountRef.current = decryptParamsResponse["prv.adjustedAmount"];
      orderIdRef.current = decryptParamsResponse["pno.orderID"];

      orbitalConnectionUsernameRef.current = decryptParamsResponse["pno.orbitalConnectionUsername"];
      orbitalConnectionPasswordRef.current = decryptParamsResponse["pno.orbitalConnectionPassword"];
      orderDefaultDescriptionRef.current = decryptParamsResponse["pno.orderDefaultDescription"];
      orderDefaultAmountRef.current = decryptParamsResponse["pno.orderDefaultAmount"];
      switchSoloIssueNumRef.current = decryptParamsResponse["pno.switchSoloIssueNum"];
      switchSoloCardStartDateRef.current = decryptParamsResponse["pno.switchSoloCardStartDate"];
      authenticationECIIndRef.current = decryptParamsResponse["pno.authenticationECIInd"];
      if (!orbitalConnectionUsernameRef.current) {
        orbitalConnectionUsernameRef.current = decryptParamsResponse["orbitalConnectionUsername"]
          ? decryptParamsResponse["orbitalConnectionUsername"]
          : "";
      }
      if (!orbitalConnectionPasswordRef.current) {
        orbitalConnectionPasswordRef.current = decryptParamsResponse["orbitalConnectionPassword"]
          ? decryptParamsResponse["orbitalConnectionPassword"]
          : "";
      }
      if (!orderDefaultDescriptionRef.current) {
        orderDefaultDescriptionRef.current = decryptParamsResponse["orderDefaultDescription"]
          ? decryptParamsResponse["orderDefaultDescription"]
          : "";
      }
      if (!orderDefaultAmountRef.current) {
        orderDefaultAmountRef.current = decryptParamsResponse["orderDefaultAmount"] ? decryptParamsResponse["orderDefaultAmount"] : "";
      }
      if (!switchSoloIssueNumRef.current) {
        switchSoloIssueNumRef.current = decryptParamsResponse["switchSoloIssueNum"] ? decryptParamsResponse["switchSoloIssueNum"] : "";
      }
      if (!switchSoloCardStartDateRef.current) {
        switchSoloCardStartDateRef.current = decryptParamsResponse["switchSoloCardStartDate"]
          ? decryptParamsResponse["switchSoloCardStartDate"]
          : "";
      }
      if (!authenticationECIIndRef.current) {
        authenticationECIIndRef.current = decryptParamsResponse["authenticationECIInd"]
          ? decryptParamsResponse["authenticationECIInd"]
          : "";
      }

      //don't save decrypted parameters
      // setDecryptParams(decryptParamsResponse);
      if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
        developmentEnv = true;
        testingToBeApp = true;
        setBillingName(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.billingName"]);
        setExpirationMonthYear(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.expirationMonthYear"]);
        setAddressline1(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.addressline1"]);
        setAddressline2(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.addressline2"]);
        setCity(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.city"]);
        setState(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.state"]);
        setPostCode(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.postCode"]);
        setDivisionCd(TceppsTestData.ToBeAppTestData.S21ROSS["to_be_app.division"]);
      }
    }
  };

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
      backgroundColor: "",
    };

    let proxyAction = false;

    // console.log("params:" + Object.keys(mydata));

    const getPortNumber = (inputUrl: string) => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };
    //find port if it exits
    const urlPortNumber = getPortNumber(window.location.href);
    // console.log("urlPortNumber<" + urlPortNumber + ">");

    Object.keys(mydata).forEach((key) => {
      // console.log("key<" + key + "> data:" + mydata[key]);
      switch (key) {
        case "tceppsProxy.app":
          proxyAction = true;
          requestParameter.app = mydata[key];
          break;
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tceppsProxy.serviceOperation":
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.background-color":
          requestParameter.backgroundColor = mydata[key];
          if (topDivRef.current !== null) {
            topDivRef.current.style.backgroundColor = mydata[key];
            topDivRef.current.style.margin = "0";
          }
          break;
        case "tceppsProxy.addProfile":
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber(requestParameter.cpgmuiApiUrl);
            // console.log("backendPortNumber<" + backendPortNumber + ">");
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    if (!proxyAction) {
      // console.log("initializeTceppsAction:" + requestParameter.app);
      dispatch(
        tceppsActions.initializeTceppsAction({
          app: requestParameter.app,
          token: requestParameter.token,
          sign: requestParameter.sign,
          serviceOperation: requestParameter.serviceOperation,
          encryptedRequest: requestParameter.encryptedRequest,
          encryptedRequestKey: requestParameter.encryptedRequestKey,
          enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
          cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
          cpgmuiEnv: requestParameter.cpgmuiEnv,
          backgroundColor: requestParameter.backgroundColor,
        })
      );
    } else {
      // console.log("proxyAction:" + requestParameter.app);
      dispatch(
        tceppsProxyActions.initializeTceppsAction({
          app: requestParameter.app,
          serviceOperation: requestParameter.serviceOperation,
          addProfile: requestParameter.addProfile,
        })
      );
    }

    // decrypt the input parameters
    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );
  }, [appKey, dispatch]);

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    function selectGivenCardType(cardName: string, cardValid: boolean, cardEmpty: boolean) {
      //		alert ('cardName: ' + cardName + ' cardValid: ' + cardValid + ' cardEmpty: ' + cardEmpty);

      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "VI";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "MC";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "AX";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            // (document.querySelector("#creditCardType") as HTMLInputElement).value = "DI";
            varCreditCardType = "DI";
            detectedCard = true;
            break;
        }
      }
      setCreditCardType(varCreditCardType);

      let errMessage = "Credit Card# needs to be entered.";
      if (!detectedCard) {
        errMessage = "Please enter a visa or a mastercard or amex or discover card#.";
      } else if (cardEmpty) {
        errMessage = "Credit Card# needs to be entered.";
      } else if (!cardValid) {
        errMessage = "Please enter a valid Credit card#.";
      } else if (cardValid) {
        errMessage = "";
      }
      componentObjRef.current.cardMessage = errMessage;
      setCreditcardValid(cardValid && detectedCard);
    }

    const loadCybersourceJS = (captureContext: any, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = config.cybersource.microformJs;
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const initializeMicroform = async (captureContext: any) => {
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "" });
      await creditCardMicroNumber.load("#creditCardNumber");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          setInitializationComplete(true);
          creditCardMicroNumber.focus();
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1.5px solid blue";
          }

          // if ((document.querySelector("#creditCardNumber") as HTMLDivElement).getAttribute("ezd_title") !== null) {
          //     ezd_showBalloonTooTip(document.getElementById("creditCardNumber") as HTMLDivElement);
          // }
          if ((document.getElementById("creditCardType") as HTMLSelectElement).getAttribute("ezd_title") !== null) {
            ezd_showBalloonTooTip(document.getElementById("creditCardType") as HTMLSelectElement);
          }
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "none";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #858585";
          }

          if ((document.querySelector("#creditCardNumber") as HTMLDivElement).getAttribute("ezd_title") !== null) {
            ezd_hideBalloonTooTipWithoutEvent();
          }
        });
        creditCardMicroNumber.on("inputSubmitRequest", function (data: any) {
          expirationMonthYearRef.current?.focus();
        });
      }
    };

    if (proxyUrl && proxyUrl.length > 0) {
      PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          const captureContext = resp.data.data;
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          // console.log(err);
          let errorMessage = "";
          if (axios.isAxiosError(err)) {
            // Access to config, request, and response
            errorMessage = INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ". Please check console.";
          } else {
            // Just a stock error
            errorMessage = "Initialize microForm failed. " + err.message + ". Please check console.";
          }
          tceppsBalloonTipHandler.validateError("billingName", errorMessage, "code04", "err");
          setBillingNameClassName(classesRoss.pErr);
          if (billingNameRef.current) {
            billingNameRef.current.focus();
          }
          ezd_addBalloonTipLoadHandler(tceppsBalloonTipHandler.validateElements);
        });
    }
  }, [dispatch, proxyUrl]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "addressline1":
        setAddressline1(event.target.value);
        break;
      case "addressline2":
        setAddressline2(event.target.value);
        break;
      case "billingName":
        setBillingName(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      case "country":
        setCountry(event.target.value);
        break;
      case "expirationMonthYear":
        setExpirationMonthYear(event.target.value);
        break;
      case "postCode":
        setPostCode(event.target.value);
        break;
      case "state":
        // console.log("state keydown:" + event.target.value);
        setState(event.target.value);
        break;
      case "divisionCd":
        setDivisionCd(event.target.value);
        break;
      case "creditCardType":
        setCreditCardType(event.target.value);
        break;
    }
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key >= "F1" && event.key <= "F12") {
      switch (identifier) {
        case "addressline1":
          setAddressline1(addressline1Ref.current!.value.toUpperCase());
          break;
        case "addressline2":
          setAddressline2(addressline2Ref.current!.value.toUpperCase());
          break;
        case "billingName":
          setBillingName(billingNameRef.current!.value.toUpperCase());
          break;
        case "city":
          setCity(cityRef.current!.value.toUpperCase());
          break;
        case "country":
          setCountry(countryRef.current!.value.toUpperCase());
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
          break;
        case "postCode":
          setPostCode(postCodeRef.current!.value.toUpperCase());
          break;
        case "state":
          setState(stateRef.current!.value.toUpperCase());
          break;
      }
    }
  };

  const onFocusHandler = (identifier: string, event: FocusEvent) => {
    // if (tceppsBalloonTipHandler.validateElements && tceppsBalloonTipHandler.validateElements.length > 0) {
    //   tceppsBalloonTipHandler.validateElements.forEach((item) => {
    //     if ((item as HTMLElement).id === identifier) {
    //       ezd_showBalloonTooTip(event.target);
    //     }
    //   });
    // }
    if (event.target.getAttribute("ezd_title") !== null) {
      ezd_showBalloonTooTip(event.target);
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "addressline1":
        setTimeout(function () {
          setAddressline1(event.target.value.toUpperCase().trim());
        }, 25);
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
      case "addressline2":
        setTimeout(function () {
          setAddressline2(event.target.value.toUpperCase().trimEnd());
        }, 25);
        break;
      case "billingName":
        setTimeout(function () {
          setBillingName(event.target.value.toUpperCase().trim());
        }, 25);
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
      case "city":
        setTimeout(function () {
          setCity(event.target.value.toUpperCase().trim());
        }, 25);
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
      case "country":
        setTimeout(function () {
          setCountry(event.target.value.toUpperCase().trim());
        }, 25);
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
      // case "creditCardNumber":
      //   setCreditCardNumber(event.target.value);
      //   if (event.target.getAttribute("ezd_title") !== null) {
      //     ezd_hideBalloonTooTip(event.target);
      //   }
      //   break;
      case "expirationMonthYear":
        setExpirationMonthYear(event.target.value);
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
      case "postCode":
        setTimeout(function () {
          setPostCode(event.target.value.toUpperCase().trim());
        }, 25);
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
      case "state":
        setTimeout(function () {
          setState(event.target.value.toUpperCase().trim());
        }, 25);
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
      case "divisionCd":
        if (event.target.getAttribute("ezd_title") !== null) {
          ezd_hideBalloonTooTip(event.target);
        }
        break;
    }
  };

  return (
    <>
      <div ref={topDivRef}>
        <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
          <input id="app" name="app" type="hidden" value={tceppsState.app} />
          <input id="token" name="token" type="hidden" value={tceppsState.token} />
          <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
          <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
          <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
          <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
          <input id="lastFourDigits" name="lastFourDigits" type="hidden" value="" />
        </form>
        <form id="ppa" name="ppa">
          <input id="cardBrand" name="cardBrand" type="hidden" value="" />
          <input id="division" name="division" type="hidden" value="" />
          <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
          <input id="ccExp" name="ccExp" type="hidden" value="" />
          <input id="customerName" name="customerName" type="hidden" value="" />
          <input id="customerAddress1" name="customerAddress1" type="hidden" value="" />
          <input id="customerAddress2" name="customerAddress2" type="hidden" value="" />
          <input id="customerCity" name="customerCity" type="hidden" value="" />
          <input id="customerState" name="customerState" type="hidden" value="" />
          <input id="customerZIP" name="customerZIP" type="hidden" value="" />
          <input id="customerCountryCode" name="customerCountryCode" type="hidden" value="" />
          <input id="amount" name="amount" type="hidden" value="" />
          <input id="merchantID" name="merchantID" type="hidden" value="" />
          <input id="orbitalConnectionUsername" name="" type="hidden" value={orbitalConnectionUsernameRef.current} />
          <input
            id="orbitalConnectionPassword"
            name="orbitalConnectionPassword"
            type="hidden"
            value={orbitalConnectionPasswordRef.current}
          />
          <input id="orderDefaultDescription" name="orderDefaultDescription" type="hidden" value={orderDefaultDescriptionRef.current} />
          <input id="orderDefaultAmount" name="orderDefaultAmount" type="hidden" value={orderDefaultAmountRef.current} />
        </form>
        <form id="pno" name="pno">
          <input id="cardBrand" name="cardBrand" type="hidden" value="" />
          <input id="division" name="division" type="hidden" value="" />
          <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
          <input id="ccExp" name="ccExp" type="hidden" value="" />
          <input id="avsName" name="avsName" type="hidden" value="" />
          <input id="avsAddress1" name="avsAddress1" type="hidden" value="" />
          <input id="avsAddress2" name="avsAddress2" type="hidden" value="" />
          <input id="avsCity" name="avsCity" type="hidden" value="" />
          <input id="avsState" name="avsState" type="hidden" value="" />
          <input id="avsZip" name="avsZip" type="hidden" value="" />
          <input id="avsCountryCode" name="avsCountryCode" type="hidden" value="" />
          <input id="merchantID" name="merchantID" type="hidden" value="" />
          <input id="amount" name="amount" type="hidden" value="" />
          <input id="orderID" name="orderID" type="hidden" value="" />
          <input id="orbitalConnectionUsername" name="" type="hidden" value={orbitalConnectionUsernameRef.current} />
          <input
            id="orbitalConnectionPassword"
            name="orbitalConnectionPassword"
            type="hidden"
            value={orbitalConnectionPasswordRef.current}
          />
          <input id="switchSoloIssueNum" name="switchSoloIssueNum" type="hidden" value={switchSoloIssueNumRef.current} />
          <input id="switchSoloCardStartDate" name="switchSoloCardStartDate" type="hidden" value={switchSoloCardStartDateRef.current} />
          <input id="authenticationECIInd" name="authenticationECIInd" type="hidden" value={authenticationECIIndRef.current} />
        </form>
        <div className={`${classesRoss.s21ross} ${classesRoss.divTop}`}>
          <form id="creditCard" name="creditCard">
            <table className={`${classesRoss.s21ross} ${classesRoss.table}`} style={tableStyle}>
              <tbody>
                <tr key={1}>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="creditCardNumber">Credit Card Account Number</label>
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <div id="creditCardNumber" style={creditCardStyle} tabIndex={1} autoFocus={true}></div>
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="billingName">Card Holder Name</label>
                  </td>
                  <td colSpan={3} className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="billingName"
                      name="billingName"
                      type="text"
                      size={30}
                      maxLength={30}
                      tabIndex={4}
                      value={billingName}
                      disabled={billingNameDisabled}
                      ref={billingNameRef}
                      onKeyDown={(event) => onKeyDownHandler("billingName", event)}
                      onChange={(event) => onChangeHandler("billingName", event)}
                      onFocus={(event) => onFocusHandler("billingName", event)}
                      onBlur={(event) => onBlurHandler("billingName", event)}
                      style={inputStyle}
                      className={billingNameClassName}
                    />
                  </td>
                </tr>
                <tr key={2}>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="creditCardType">Credit Card Type</label>
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <select
                      id="creditCardType"
                      name="creditCardType"
                      tabIndex={-1}
                      value={creditCardType}
                      disabled={creditCardTypeDisabled}
                      ref={creditCardTypeRef}
                      onChange={(event) => onChangeHandler("creditCardType", event)}
                      // onFocus={(event) => onFocusHandler("creditCardType", event)}
                      // onBlur={(event) => onBlurHandler("creditCardType", event)}
                      style={inputStyleCard}
                    >
                      <option value="" disabled={true}></option>
                      <option value="AX" disabled={true}>
                        AMEX
                      </option>
                      <option value="DI" disabled={true}>
                        DISCOVER
                      </option>
                      <option value="MC" disabled={true}>
                        MASTER
                      </option>
                      <option value="VI" disabled={true}>
                        VISA
                      </option>
                    </select>
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="addressline1">Addressline 1</label>
                  </td>
                  <td colSpan={3} className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="addressline1"
                      name="addressline1"
                      type="text"
                      size={30}
                      maxLength={30}
                      tabIndex={5}
                      value={addressline1}
                      disabled={addressline1Disabled}
                      ref={addressline1Ref}
                      onKeyDown={(event) => onKeyDownHandler("addressline1", event)}
                      onChange={(event) => onChangeHandler("addressline1", event)}
                      onFocus={(event) => onFocusHandler("addressline1", event)}
                      onBlur={(event) => onBlurHandler("addressline1", event)}
                      style={inputStyle}
                      className={`${addressline1ClassName}`}
                    />
                  </td>
                </tr>
                <tr key={3}>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="expirationMonthYear">Card Expiration Date</label>
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="expirationMonthYear"
                      name="expirationMonthYear"
                      type="text"
                      size={7}
                      maxLength={7}
                      tabIndex={2}
                      value={expirationMonthYear}
                      disabled={expirationMonthYearDisabled}
                      ref={expirationMonthYearRef}
                      onKeyDown={(event) => onKeyDownHandler("expirationMonthYear", event)}
                      onChange={(event) => onChangeHandler("expirationMonthYear", event)}
                      onFocus={(event) => onFocusHandler("expirationMonthYear", event)}
                      onBlur={(event) => onBlurHandler("expirationMonthYear", event)}
                      onKeyUp={keyUpDateEvent}
                      style={inputStyle}
                      className={expirationMonthYearClassName}
                    />
                    {" (MM/YYYY)"}
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="addressline2">Addressline 2</label>
                  </td>
                  <td colSpan={3} className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="addressline2"
                      name="addressline2"
                      type="text"
                      size={30}
                      maxLength={30}
                      tabIndex={6}
                      value={addressline2}
                      disabled={addressline2Disabled}
                      ref={addressline2Ref}
                      onKeyDown={(event) => onKeyDownHandler("addressline2", event)}
                      onChange={(event) => onChangeHandler("addressline2", event)}
                      onBlur={(event) => onBlurHandler("addressline2", event)}
                      style={inputStyle}
                    />
                  </td>
                </tr>
                <tr key={4}>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="divisionCd">Division</label>
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <select
                      id="divisionCd"
                      name="divisionCd"
                      value={divisionCd}
                      disabled={divisionCdDisabled}
                      ref={divisionCdRef}
                      tabIndex={3}
                      onChange={(event) => onChangeHandler("divisionCd", event)}
                      onFocus={(event) => onFocusHandler("divisionCd", event)}
                      onBlur={(event) => onBlurHandler("divisionCd", event)}
                      style={inputStyle}
                      className={divisionCdClassName}
                    >
                      <option value="" disabled></option>
                      <option value="GMD">GMD</option>
                      <option value="NAD">NAD</option>
                    </select>
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="city">City</label>
                  </td>
                  <td colSpan={1} className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="city"
                      name="city"
                      type="text"
                      size={20}
                      maxLength={20}
                      tabIndex={7}
                      value={city}
                      disabled={cityDisabled}
                      ref={cityRef}
                      onChange={(event) => onChangeHandler("city", event)}
                      onKeyDown={(event) => onKeyDownHandler("city", event)}
                      onFocus={(event) => onFocusHandler("city", event)}
                      onBlur={(event) => onBlurHandler("city", event)}
                      style={inputStyle}
                      className={cityClassName}
                    />
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="state">State</label>
                  </td>
                  <td colSpan={1} className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="state"
                      name="state"
                      type="text"
                      size={3}
                      maxLength={3}
                      tabIndex={8}
                      value={state}
                      disabled={stateDisabled}
                      ref={stateRef}
                      onChange={(event) => onChangeHandler("state", event)}
                      onKeyDown={(event) => onKeyDownHandler("state", event)}
                      onFocus={(event) => onFocusHandler("state", event)}
                      onBlur={(event) => onBlurHandler("state", event)}
                      style={inputStyle}
                      className={stateClassName}
                    />
                  </td>
                </tr>
                <tr key={5}>
                  <td></td>
                  <td></td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="postCode">Post Code</label>
                  </td>
                  <td colSpan={1} className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="postCode"
                      name="postCode"
                      type="text"
                      size={10}
                      maxLength={10}
                      tabIndex={9}
                      value={postCode}
                      disabled={postCodeDisabled}
                      ref={postCodeRef}
                      onChange={(event) => onChangeHandler("postCode", event)}
                      onKeyDown={(event) => onKeyDownHandler("postCode", event)}
                      onFocus={(event) => onFocusHandler("postCode", event)}
                      onBlur={(event) => onBlurHandler("postCode", event)}
                      style={inputStyle}
                      className={postCodeClassName}
                    />
                  </td>
                  <td className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <label htmlFor="country">Country</label>
                  </td>
                  <td colSpan={1} className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`}>
                    <input
                      id="country"
                      name="country"
                      type="text"
                      size={3}
                      maxLength={3}
                      tabIndex={10}
                      value={country}
                      disabled={true}
                      ref={countryRef}
                      onChange={(event) => onChangeHandler("country", event)}
                      onKeyDown={(event) => onKeyDownHandler("country", event)}
                      onFocus={(event) => onFocusHandler("country", event)}
                      onBlur={(event) => onBlurHandler("country", event)}
                      style={inputStyle}
                      className={countryClassName}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {showBrowserMesssage}
            <p className={`${classesRoss.s21ross} ${classesRoss.td} ${classesRoss.stab}`} style={{ color: "green" }}>
              {browserInitializingMesssage}
            </p>
          </form>
        </div>
      </div>
    </>
  );
}
