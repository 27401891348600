const DECIMAL_POINT = ".";

export const formatAsCurrency = (amount: string) => {
  return "$" + formatAmount(amount);
};

export const formatAmount = (amount: string) => {
  let number: any = amount;

  // Always ensure that the number is a minimum of 2 digits long so the process does not throw an exception if 1 cent (1) is passed in
  if (amount === undefined) {
    return "0.00";
  } else if (amount.length < 2) {
    number = "0" + amount;
  }

  // If the number does not contain a decimal, insert a decimal before the last two digits since decimals are implied
  if (number.indexOf(DECIMAL_POINT)) {
    number = number.substring(0, number.length - 2) + DECIMAL_POINT + number.substring(number.length - 2);
  }

  number = (Math.round(number * 100) / 100).toFixed(2);

  return number;
};

// https://stackblitz.com/edit/js-xmqg8r?file=index.js
