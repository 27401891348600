import "./rosspoc.css";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { RossPocInterface } from "../../interface/redux-state/RossPocInterface";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef } from "react";
import {
  errorRossPoc,
  cardTypeRossPoc,
  creditCardFocusRossPoc,
  loading,
  changeRossPoc,
  resetRossPoc,
  toggleCreditCardProfile,
} from "../../redux-store/reducer/rossPocSlice";
import { keyUpDateEvent } from "../../utils/calendarUtil";
import { isValidState, isValidUSZip } from "../../utils/UsStates";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import dayjs from "dayjs";
import PaymentService from "../../service/paymentService";
import { initializeCpgmui, resetCpgmui } from "../../redux-store/reducer/cpgmuiSlice";
import { classNames, extractRequestParameters, getCardType, getIframeUrl, loadCybersourceJS } from "../../utils/utils";
import { Cpgmui, CpgmuiEvents } from "../../utils/Cpgmui";
import { contextLoadedAction, microformInitializedAction } from "../../redux-store/reducer/paymentSlice";
import axios, { AxiosError } from "axios";
import CpgmuiService from "../../service/CpgmuiService";
import { createCybersourceToken } from "../../utils/cybersourceUtil";

const errorFlags = {
  ccExpMissing: false,
  ccExpMonthInvalid: false,
  ccExpExpired: false,
  customerNameMissing: false,
  customerAddress1Missing: false,
  cardBrandMissing: false,
  customerAddress2Missing: false, // always false.
  customerCityMissing: false,
  customerStateMissing: false,
  customerZIPMissing: false,
  customerCountryCodeMissing: false,
  creditCardMissing: false,
  creditCardInvalid: false,
  merchantIDMissing: false,
};
const APP_NAME = "ROSSPOC";

const SERVICE_OPERATION = {
  profileAdd: "profileAdd",
  newOrder: "newOrder",
  newOrderReversal: "newOrderReversal",
  toggleAddProfile: "toggleAddProfile",
};

type Microform = {
  microform: (options: any) => any;
};
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
    "font-size": "8pt",
    "font-family": '"Arial", sans-serif',
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
} as React.CSSProperties;

const inputStyle = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
} as React.CSSProperties;

export function RossPocComponent() {
  const rossPocState = useSelector((state: any) => state.rosspoc) as RossPocInterface;
  const cpgmuiState = useSelector((state: any) => state.cpgmui);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);
  const dispatch = useDispatch();
  const cpgmuiRef = useRef<Cpgmui>();
  const setupTestDataRef = useRef<boolean>(false);

  const { appKey } = useParams();
  //* initialize iframe -----------------------------
  useEffect(() => {
    const processRequest = async (req: any) => {
      const resp = await PaymentService.getDecryptRequest(req.app, req.encryptedRequestKey, req.encryptedRequest);
      req.unencryptedRequest = resp.data;
      cpgmuiRef.current = new Cpgmui(req.unencryptedRequest["tcepps.proxyUrl"]);
      dispatch(initializeCpgmui(req));
      return () => {
        dispatch(resetCpgmui());
        dispatch(resetRossPoc());
      };
    };

    const requestParameters = extractRequestParameters(appKey || "");
    processRequest(requestParameters).then(() => {
      cpgmuiRef.current?.iframeInitialized();
    });
  }, [dispatch, appKey]);

  //* message handler --------------------------------
  const validateForm = useCallback(() => {
    let temp = errorFlags;
    let hasError = false;
    if (!rossPocState.customerName) {
      temp = { ...temp, customerNameMissing: true };
      hasError = true;
    }
    if (!rossPocState.cardBrand) {
      temp = { ...temp, cardBrandMissing: true, creditCardInvalid: true };
      hasError = true;
    } else if (!rossPocState.cardValid) {
      temp = { ...temp, creditCardMissing: true };
      hasError = true;
    }
    if (!rossPocState.customerAddress1) {
      temp = { ...temp, customerAddress1Missing: true };
      hasError = true;
    }
    if (!rossPocState.customerCity) {
      temp = { ...temp, customerCityMissing: true };
      hasError = true;
    }
    if (!rossPocState.customerState || !isValidState(rossPocState.customerState)) {
      temp = { ...temp, customerStateMissing: true };
      hasError = true;
    }
    if (!rossPocState.customerZIP || !isValidUSZip(rossPocState.customerZIP)) {
      temp = { ...temp, customerZIPMissing: true };
      hasError = true;
    }
    if (!rossPocState.customerCountryCode) {
      temp = { ...temp, customerCountryCodeMissing: true };
      hasError = true;
    }
    if (!rossPocState.merchantID) {
      temp = { ...temp, merchantIDMissing: true };
      hasError = true;
    }
    if (!rossPocState.ccExp) {
      temp = { ...temp, ccExpMissing: true };
      hasError = true;
    } else {
      if (rossPocState.ccExp.length === 7) {
        const expirationMonth = Number(rossPocState.ccExp.substring(0, 2));
        const expirationYear = Number(rossPocState.ccExp.substring(3, 8));
        const currentMonth = dayjs().month() + 1;
        const currentYear = dayjs().year();

        if (currentMonth > 0 && currentMonth < 13) {
          if (
            !expirationMonth ||
            !expirationYear ||
            currentYear > expirationYear ||
            (currentYear === expirationYear && currentMonth > expirationMonth)
          ) {
            temp = { ...temp, ccExpExpired: true };
            hasError = true;
            // dispatch(errorAction("The credit card date is expired."));
          }
        } else {
          temp = { ...temp, ccExpMonthInvalid: true };
          hasError = true;
          //dispatch(errorAction("Expiration month is invalid"));
        }
      } else {
        temp = { ...temp, ccExpMissing: true };
        hasError = true;
      }
    }
    if (hasError) {
      dispatch(errorRossPoc(temp));
    }
    return {
      hasError,
      errors: temp,
    };
  }, [dispatch, rossPocState]);

  const buildProfileAddRequest = useCallback(
    (jti: string) => {
      const temp =
        cpgmuiState.unencryptedRequest && cpgmuiState.unencryptedRequest.orbitalConnectionUsername
          ? cpgmuiState.unencryptedRequest.orbitalConnectionUsername
          : "";
      return {
        "tcepps.app": APP_NAME,
        "tcepps.token": cpgmuiState.token,
        "tcepps.sign": cpgmuiState.sign,
        "tcepps.serviceOperation": "profileAdd",
        "ppa.cardBrand": rossPocState.cardBrand,
        "ppa.merchantID": rossPocState.merchantID,
        "ppa.ccAccountNum": `TT=${jti}`,
        "ppa.ccExp": rossPocState.ccExp,
        "ppa.customerName": rossPocState.customerName,
        "ppa.customerAddress1": rossPocState.customerAddress1,
        "ppa.customerAddress2": rossPocState.customerAddress2,
        "ppa.customerCity": rossPocState.customerCity,
        "ppa.customerState": rossPocState.customerState,
        "ppa.customerZIP": rossPocState.customerZIP,
        "ppa.customerCountryCode": rossPocState.customerCountryCode,
        "ppa.orbitalConnectionUsername": temp || "",
      };
    },
    [
      cpgmuiState.sign,
      cpgmuiState.token,
      cpgmuiState.unencryptedRequest,
      rossPocState.cardBrand,
      rossPocState.ccExp,
      rossPocState.customerAddress1,
      rossPocState.customerAddress2,
      rossPocState.customerCity,
      rossPocState.customerCountryCode,
      rossPocState.customerName,
      rossPocState.customerState,
      rossPocState.customerZIP,
      rossPocState.merchantID,
    ]
  );

  const buildNewOrder_Reversal = useCallback(
    (jti: string, lastFourDigits: string) => {
      const temp =
        cpgmuiState.unencryptedRequest && cpgmuiState.unencryptedRequest.orbitalConnectionUsername
          ? cpgmuiState.unencryptedRequest.orbitalConnectionUsername
          : "";
      return {
        "tcepps.app": APP_NAME,
        "tcepps.token": cpgmuiState.token,
        "tcepps.sign": cpgmuiState.sign,
        "tcepps.serviceOperation": "newOrder_reversal",
        "tcepps.lastFourDigits": lastFourDigits,
        "pno.cardBrand": rossPocState.cardBrand,
        "pno.amount": rossPocState.amount,
        "pno.merchantID": rossPocState.merchantID,
        "pno.division": rossPocState.merchantID,
        "pno.ccAccountNum": `TT=${jti}`,
        "pno.ccExp": rossPocState.ccExp,
        "pno.avsName": rossPocState.customerName,
        "pno.avsAddress1": rossPocState.customerAddress1,
        "pno.avsAddress2": rossPocState.customerAddress2,
        "pno.avsCity": rossPocState.customerCity,
        "pno.avsState": rossPocState.customerState,
        "pno.avsZip": rossPocState.customerZIP,
        "pno.avsCountryCode": rossPocState.customerCountryCode,
        "pno.orbitalConnectionUsername": temp || "",
        "pno.orderID": rossPocState.orderID,
      };
    },
    [
      cpgmuiState.sign,
      cpgmuiState.token,
      cpgmuiState.unencryptedRequest,
      rossPocState.cardBrand,
      rossPocState.ccExp,
      rossPocState.customerAddress1,
      rossPocState.customerAddress2,
      rossPocState.customerCity,
      rossPocState.customerCountryCode,
      rossPocState.customerName,
      rossPocState.customerState,
      rossPocState.customerZIP,
      rossPocState.merchantID,
      rossPocState.amount,
      rossPocState.orderID,
    ]
  );

  const submitProfileAdd = useCallback(async () => {
    try {
      const expirationMonth = rossPocState.ccExp.substring(0, 2);
      const expirationYear = rossPocState.ccExp.substring(3, 8);
      const resp = await createCybersourceToken(expirationMonth, expirationYear, paymentInfo.microform);
      const tokenInfo = resp as { jti: string; lastFourDigits: string };
      const profileAddRequest = buildProfileAddRequest(tokenInfo.jti);
      CpgmuiService.submitServiceOperation(profileAddRequest).then((resp: any) => {
        console.log(resp.data);
        cpgmuiRef.current!.response(resp.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, [buildProfileAddRequest, rossPocState.ccExp, paymentInfo.microform]);

  const submitNewOrderReversal = useCallback(async () => {
    try {
      const expirationMonth = rossPocState.ccExp.substring(0, 2);
      const expirationYear = rossPocState.ccExp.substring(3, 8);
      const resp = await createCybersourceToken(expirationMonth, expirationYear, paymentInfo.microform);
      const tokenInfo = resp as { jti: string; lastFourDigits: string };
      const newOrderReversalRequest = buildNewOrder_Reversal(tokenInfo.jti, tokenInfo.lastFourDigits);
      CpgmuiService.submitServiceOperation(newOrderReversalRequest).then((resp: any) => {
        console.log(resp.data);
        cpgmuiRef.current!.response(resp.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, [buildNewOrder_Reversal, rossPocState.ccExp, paymentInfo.microform]);

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.data.type === CpgmuiEvents.execute) {
        const serviceOperation = event.data.payload.serviceOperation;

        if (serviceOperation === SERVICE_OPERATION.profileAdd || SERVICE_OPERATION.newOrderReversal) {
          const validationResult = validateForm();
          if (validationResult.hasError) {
            cpgmuiRef.current?.validated(validationResult);
          } else {
            if (serviceOperation === SERVICE_OPERATION.profileAdd) {
              submitProfileAdd();
            } else if (serviceOperation === SERVICE_OPERATION.newOrderReversal) {
              submitNewOrderReversal();
            }
          }
        } else if (serviceOperation === SERVICE_OPERATION.toggleAddProfile) {
          dispatch(toggleCreditCardProfile());
        }
      } else if (event.data.type === CpgmuiEvents.validate) {
        const validationResult = validateForm();
        cpgmuiRef.current?.validated(validationResult);
      } else if (event.data.type === CpgmuiEvents.custom) {
        const customInfo = event.data.payload;
        if (customInfo.action === "testError") {
          const errorList = ["Something really bad happened.", "More bad news."];
          cpgmuiRef.current?.error(errorList);
        }
      }
    },
    [dispatch, validateForm, submitProfileAdd, submitNewOrderReversal]
  );

  useEffect(() => {
    if (cpgmuiState.unencryptedRequest && !setupTestDataRef.current && isTestingApp(cpgmuiState.unencryptedRequest["app.TESTINGAPP"])) {
      const changedState: { [index: string]: string | any } = { ...rossPocState };
      changedState["customerName"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.billingName"];
      changedState["ccExp"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.expirationMonthYear"];
      changedState["customerAddress1"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.addressline1"];
      changedState["customerAddress2"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.addressline2"];
      changedState["customerCity"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.city"];
      changedState["customerState"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.state"];
      changedState["customerZIP"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.postCode"];
      changedState["merchantID"] = TceppsTestData.ToBeAppTestData.ROSSPOC["to_be_app.division"];
      dispatch(changeRossPoc(changedState));
      setupTestDataRef.current = true;
    }
  }, [dispatch, rossPocState, cpgmuiState.unencryptedRequest]);

  useEffect(() => {
    // setCollapsed(true);
    window.addEventListener("message", windowMessageHandler, false);

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler]);

  //* setup microform...
  useEffect(() => {
    const updateCardBrand = (cardName: string, isValid: boolean, isEmpty: boolean) => {
      const cardBrand = getCardType(cardName);
      if (!isEmpty) {
        if (!cardBrand) {
          dispatch(errorRossPoc("Please enter a visa or a mastercard or amex or discover card number."));
        } else {
          dispatch(errorRossPoc(""));
        }
      }
      dispatch(cardTypeRossPoc({ cardBrand: cardBrand || "", cardValid: isValid ? "Y" : "N" }));
    };

    const initializeMicroform = async (captureContext: any) => {
      const flex = (await new (window as any).Flex(captureContext)) as Microform;
      const microform = await flex.microform({ styles: flexStyles });
      const creditCardMicroNumber = await microform.createField("number", { placeholder: "" });
      await creditCardMicroNumber.load("#creditCardNumber");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber) {
        creditCardMicroNumber.on("change", (data: any) => {
          if (data.card && data.card.length > 0) {
            updateCardBrand(data.card[0].name, data.valid, data.empty);
          } else {
            updateCardBrand("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          dispatch(creditCardFocusRossPoc(true));
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          dispatch(creditCardFocusRossPoc(false));
        });
        creditCardMicroNumber.on("load", function (data: any) {
          dispatch(loading(false));
          creditCardMicroNumber.focus();
        });
      }
    };

    const getCaptureContext = () => {
      const iframeUrl = getIframeUrl();
      // console.log("iframeUrl:" + iframeUrl);
      PaymentService.getCaptureContext(iframeUrl)
        .then((resp) => {
          const captureContext = resp.data.data;
          dispatch(contextLoadedAction(captureContext));
          if (!(window as any).Flex) {
            loadCybersourceJS(captureContext, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            dispatch(errorRossPoc("AxiosError: call to initialize microForm failed. " + err.message + ". Please check console."));
          } else {
            dispatch(errorRossPoc("Initialize microForm failed. " + err.message + ". Please check console."));
          }
        });
    };

    getCaptureContext();
  }, [dispatch]);

  const handleChange = (event: any) => {
    const field = event.target.name as string;
    const changedState: { [index: string]: string | any } = { ...rossPocState };
    changedState[field] = (event.target.value as string).toUpperCase();
    dispatch(changeRossPoc(changedState));
    const errorState: { [index: string]: boolean } = { ...rossPocState.error };
    errorState[field + "Missing"] = false;
    dispatch(errorRossPoc(errorState));
  };

  const getDateErrorMessage = (): string => {
    let msg = "";
    if (rossPocState.error.ccExpMissing) {
      msg = "Please enter credit card expiration date.";
    } else if (rossPocState.error.ccExpExpired) {
      msg = "Date is expired. Please enter valid date.";
    } else if (rossPocState.error.ccExpMonthInvalid) {
      msg = "Please enter a valid date.";
    }
    return msg;
  };

  const creditCardStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "17px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "150px", //"163px"
    margin: "0em",
    borderWidth: "1px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const tooltipStyle = {
    fontSize: "10pt",
    backgroundColor: "#ff6060",
  };

  return (
    <>
      <div className="grid-container">
        <div className="item1">Credit Card Account Number: </div>
        <div className="item2">
          <div
            id="creditCardNumber"
            style={creditCardStyle}
            tabIndex={1}
            className={classNames({
              fieldError: rossPocState.error.creditCardInvalid || rossPocState.error.creditCardMissing,
              fieldOK: !(rossPocState.error.creditCardInvalid || rossPocState.error.creditCardMissing),
            })}
          ></div>
          <Tooltip
            anchorSelect="#creditCardNumber"
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!rossPocState.error.creditCardMissing}
            content="Please enter a credit card number."
          />
          <Tooltip
            anchorSelect="#creditCardNumber"
            style={tooltipStyle}
            place="right-start"
            variant="error"
            hidden={!rossPocState.error.creditCardInvalid}
            content="Please enter a visa or a mastercard or amex or discover card number."
          />
        </div>
        <div className="item3">Card Holder Name:</div>
        <div className="item4">
          <input
            name="customerName"
            type="text"
            style={{ width: "150px" }}
            value={rossPocState.customerName}
            disabled={!rossPocState.newCreditCardProfileCreation}
            size={33}
            maxLength={30}
            tabIndex={4}
            onChange={handleChange}
            className={classNames({
              fieldError: rossPocState.error.customerNameMissing,
              fieldOK: !rossPocState.error.customerNameMissing,
            })}
          />
          <Tooltip
            anchorSelect="#customerName"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!rossPocState.error.customerNameMissing}
            content="Please enter card holder name."
          />
        </div>
        <div className="item5"> </div>
        <div className="item6"></div>

        <div className="item1">Credit Card Type: </div>
        <div className="item2">
          <select tabIndex={-1} id="cardBrand" name="cardBrand" disabled={true} value={rossPocState.cardBrand} onChange={handleChange}>
            <option value=""> </option>
            <option value="AX">AMEX</option>
            <option value="DI">DISCOVER</option>
            {/* <option value="DS">DISCOVER</option> */}
            <option value="MC">MASTER</option>
            <option value="VI">VISA</option>
          </select>
        </div>
        <div className="item3">Address Line 1:</div>
        <div className="item4">
          <input
            name="customerAddress1"
            type="text"
            style={{ width: "150px" }}
            size={33}
            maxLength={30}
            tabIndex={5}
            onChange={handleChange}
            value={rossPocState.customerAddress1}
            disabled={!rossPocState.newCreditCardProfileCreation}
            className={classNames({
              fieldError: rossPocState.error.customerAddress1Missing,
              fieldOK: !rossPocState.error.customerAddress1Missing,
            })}
          />
          <Tooltip
            anchorSelect="#customerAddress1"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!rossPocState.error.customerAddress1Missing}
            content="Please enter a customer address."
          />
        </div>
        <div className="item5"> </div>
        <div className="item6"></div>

        <div className="item1">Card Expiration Date: </div>
        <div className="item2">
          <input
            name="ccExp"
            type="text"
            // style={{ width: "150px" }}
            size={10}
            maxLength={7}
            tabIndex={2}
            onChange={handleChange}
            value={rossPocState.ccExp}
            onKeyUp={keyUpDateEvent}
            disabled={!rossPocState.newCreditCardProfileCreation}
            className={classNames({
              fieldError: rossPocState.error.ccExpMonthInvalid || rossPocState.error.ccExpExpired || rossPocState.error.ccExpMissing,
              fieldOK: !(rossPocState.error.ccExpMonthInvalid || rossPocState.error.ccExpExpired || rossPocState.error.ccExpMissing),
            })}
          />
          <Tooltip
            anchorSelect="#ccExp"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!(rossPocState.error.ccExpMonthInvalid || rossPocState.error.ccExpExpired || rossPocState.error.ccExpMissing)}
            content={getDateErrorMessage()}
          />
        </div>
        <div className="item3">Address Line 2:</div>
        <div className="item4">
          <input
            name="customerAddress2"
            type="text"
            style={{ width: "150px" }}
            size={33}
            maxLength={30}
            tabIndex={6}
            onChange={handleChange}
            value={rossPocState.customerAddress2}
            disabled={!rossPocState.newCreditCardProfileCreation}
          />
        </div>
        <div className="item5"> </div>
        <div className="item6"></div>

        <div className="item1">Division: </div>
        <div className="item2">
          <select
            id="merchantID"
            name="merchantID"
            tabIndex={3}
            style={inputStyle}
            value={rossPocState.merchantID}
            onChange={handleChange}
            disabled={!rossPocState.newCreditCardProfileCreation}
            className={classNames({
              fieldError: rossPocState.error.merchantIDMissing,
              fieldOK: !rossPocState.error.merchantIDMissing,
            })}
          >
            <option value=""></option>
            <option value="GMD">GMD</option>
            <option value="NAD">NAD</option>
          </select>
          <Tooltip
            anchorSelect="#merchantID"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!rossPocState.error.merchantIDMissing}
            content="Please select a division."
          />
        </div>
        <div className="item3">City:</div>
        <div className="item4">
          <input
            name="customerCity"
            type="text"
            style={{ width: "150px" }}
            size={22}
            maxLength={28}
            tabIndex={7}
            onChange={handleChange}
            value={rossPocState.customerCity}
            disabled={!rossPocState.newCreditCardProfileCreation}
            className={classNames({
              fieldError: rossPocState.error.customerCityMissing,
              fieldOK: !rossPocState.error.customerCityMissing,
            })}
          />
          <Tooltip
            anchorSelect="#customerCity"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!rossPocState.error.customerCityMissing}
            content="Please enter a city."
          />
        </div>
        <div className="item5">State</div>
        <div className="item6">
          <input
            name="customerState"
            type="text"
            style={{ width: "30px" }}
            size={2}
            maxLength={2}
            tabIndex={8}
            onChange={handleChange}
            value={rossPocState.customerState}
            disabled={!rossPocState.newCreditCardProfileCreation}
            className={classNames({
              fieldError: rossPocState.error.customerStateMissing,
              fieldOK: !rossPocState.error.customerStateMissing,
            })}
          />
          <Tooltip
            anchorSelect="#customerState"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!rossPocState.error.customerStateMissing}
            content="Please enter a state."
          />
        </div>

        <div className="item1"></div>
        <div className="item2"></div>
        <div className="item3">Postal Code:</div>
        <div className="item4">
          <input
            name="customerZIP"
            type="text"
            style={{ width: "150px" }}
            size={14}
            maxLength={10}
            tabIndex={9}
            onChange={handleChange}
            value={rossPocState.customerZIP}
            disabled={!rossPocState.newCreditCardProfileCreation}
            className={classNames({
              fieldError: rossPocState.error.customerZIPMissing,
              fieldOK: !rossPocState.error.customerZIPMissing,
            })}
          />
          <Tooltip
            anchorSelect="#customerZIP"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!rossPocState.error.customerZIPMissing}
            content="Please enter a zip code."
          />
        </div>
        <div className="item5">Country</div>
        <div className="item6">
          <input
            name="customerCountryCode"
            type="text"
            style={{ width: "30px" }}
            size={2}
            maxLength={2}
            tabIndex={10}
            value={rossPocState.customerCountryCode}
            onChange={handleChange}
            disabled={!rossPocState.newCreditCardProfileCreation}
            className={classNames({
              fieldError: rossPocState.error.customerCountryCodeMissing,
              fieldOK: !rossPocState.error.customerCountryCodeMissing,
            })}
          />
          <Tooltip
            anchorSelect="#customerCountryCode"
            style={tooltipStyle}
            place="bottom"
            variant="error"
            hidden={!rossPocState.error.customerCountryCodeMissing}
            content="Please enter a country code."
          />
        </div>
      </div>
    </>
  );
}
