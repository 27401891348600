import handleError from './httpErrorHandler';

export default function initInterceptors(httpService: any) {
    httpService.interceptors.request.use((request: any) => {
        return request;
    });

    httpService.interceptors.response.use(
        (res: any) => {
          return res
        },
        (error: any) => {
            if( error.response) {
                handleError((error.response.status));
            }
            return Promise.reject(error);
        }
    )
}
