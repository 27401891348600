import client from './api-client';

const apiHost = window.location.origin;


const init = async () => {
    return client.get(apiHost + '/api/config/all')
}

// init().then( response => {
//     console.log(response);
// })

const prestart = {
    init
}

export default prestart;
