import "./S21CsaStyles.css";
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { useCallback, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError } from "axios";
import { S21CsaInterface } from "../../interface/redux-state/S21CsaInterface";
import { ezfToUpperOnBlur, ezfToUpperOnKeyDown } from "./creditCardForm";
import { classNames, extractRequestParameters, getCardType, getIframeUrl, loadCybersourceJS, parseToken } from "../../utils/utils";
import {
  cardTypeAction,
  changeAction,
  creditCardFocusAction,
  errorAction,
  loadingAction,
  toggleCreditCardProfileAction,
} from "../../redux-store/reducer/s21CsaSlice";
import { contextLoadedAction, microformInitializedAction, tokenCreatedAction } from "../../redux-store/reducer/paymentSlice";
import PaymentService from "../../service/paymentService";
import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import _ from "lodash";
import dayjs from "dayjs";
import { profileAdd, newOrder } from "../../utils/cpgmuiUtil";
import { isCybersourceTimedout, getTimedoutDummyToken, keyUpDateEvent } from "../../utils/calendarUtil";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import { isValidState, isValidUSZip } from "../../utils/UsStates";

type Microform = {
  microform: (options: any) => any;
};
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
    "font-size": "8pt",
    "font-family": '"Arial", sans-serif',
  },
  "::placeholder": { color: "blue" },
  ":focus": { color: "blue" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;

const PROCESS_TRANSACTION = "processTransaction";
const TOGGLE_ADD_PROFILE = "toggleAddProfile";
const PROFILE_ADD = "profileAdd";
const NEW_ORDER_REVERSAL = "newOrder_reversal";

const toDay = new Date();

const errorFlags = {
  ccExpMissing: false,
  ccExpMonthInvalid: false,
  ccExpExpired: false,
  customerNameMissing: false,
  customerAddress1Missing: false,
  cardBrandMissing: false,
  customerAddress2Missing: false, // always false.
  customerCityMissing: false,
  customerStateMissing: false,
  customerZIPMissing: false,
  customerCountryCodeMissing: false,
  creditCardMissing: false,
  creditCardInvalid: false,
};

export function S21CsaComponent(this: any) {
  const dispatch = useDispatch();
  const temp = useSelector((state: any) => state.s21csa) as unknown;
  const s21CsaState = temp as S21CsaInterface;
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);
  const creditCardMicroNumberRef = useRef(null);
  const [errorState, setErrorState] = useState(errorFlags);
  const [loadedTestData, setLoadedTestData] = useState(false);

  const { appKey } = useParams();

  const validateForm = useCallback(() => {
    // console.log(JSON.stringify(s21CsaState));
    // const validateCardBrand = () => {
    //   let isGood = true;
    //   if( !s21CsaState.cardBrand ) {
    //     isGood = false;
    //     setErrorState({...errorState, cardBrandMissing: true})
    //   } else if( !s21CsaState.cardBrand) {
    //     isGood = false;
    //     setErrorState({...errorState, cardBrandMissing: true})
    //   } else {
    //     dispatch(errorAction(""));
    //   }
    //   return isGood;
    // }
    let temp = errorState;
    let hasError = false;
    if (!s21CsaState.customerName) {
      temp = { ...temp, customerNameMissing: true };
      hasError = true;
    }
    if (!s21CsaState.cardBrand) {
      temp = { ...temp, cardBrandMissing: true };
    } else if (!s21CsaState.cardValid) {
      temp = { ...temp, cardBrandMissing: true };
    }
    if (!s21CsaState.customerAddress1) {
      temp = { ...temp, customerAddress1Missing: true };
      hasError = true;
    }
    if (!s21CsaState.customerCity) {
      temp = { ...temp, customerCityMissing: true };
      hasError = true;
    }
    if (!s21CsaState.customerState || !isValidState(s21CsaState.customerState)) {
      temp = { ...temp, customerStateMissing: true };
      hasError = true;
    }
    if (!s21CsaState.customerZIP || !isValidUSZip(s21CsaState.customerZIP)) {
      temp = { ...temp, customerZIPMissing: true };
      hasError = true;
    }
    if (!s21CsaState.customerCountryCode) {
      temp = { ...temp, customerCountryCodeMissing: true };
      hasError = true;
    }
    if (!s21CsaState.ccExp) {
      temp = { ...temp, ccExpMissing: true };
      hasError = true;
    } else {
      if (s21CsaState.ccExp.length === 7) {
        const expirationMonth = Number(s21CsaState.ccExp.substring(0, 2));
        const expirationYear = Number(s21CsaState.ccExp.substring(3, 8));
        const currentMonth = dayjs().month() + 1;
        const currentYear = dayjs().year();

        if (currentMonth > 0 && currentMonth < 13) {
          if (
            !expirationMonth ||
            !expirationYear ||
            currentYear > expirationYear ||
            (currentYear === expirationYear && currentMonth > expirationMonth)
          ) {
            temp = { ...temp, ccExpExpired: true };
            hasError = true;
            // dispatch(errorAction("The credit card date is expired."));
          }
        } else {
          temp = { ...temp, ccExpMonthInvalid: true };
          hasError = true;
          //dispatch(errorAction("Expiration month is invalid"));
        }
      } else {
        temp = { ...temp, ccExpMissing: true };
        hasError = true;
      }
    }
    if (hasError) {
      setErrorState(temp);
    }
    return !hasError;
  }, [errorState, s21CsaState]);

  const captureTokenInformation = useCallback(
    (serviceOperation: string) => {
      const validateCardType = () => {
        let temp = errorState;
        let isGood = true;
        if (!s21CsaState.cardBrand) {
          isGood = false;
          temp = { ...temp, creditCardInvalid: true };
          dispatch(errorAction("Please enter a Visa or a Mastercard or amex or Discover card number."));
        } else if (!s21CsaState.cardValid) {
          isGood = false;
          temp = { ...temp, creditCardMissing: true };
          dispatch(errorAction("Please enter a valid Credit Card Number."));
        } else {
          dispatch(errorAction(""));
        }
        if (!isGood) {
          setErrorState(temp);
        }
        return isGood;
      };
      // there are 2 date validations. We do it here before we get the token from the microform.
      // If the date is invalid we skip createToken. Date validations are also done to show
      // screen errors... should refactor this to do it once.
      const validateDate = (cardExpirationDate: string) => {
        let isGood = true;
        if (cardExpirationDate) {
          if (cardExpirationDate.length === 7) {
            const expirationMonth = Number(cardExpirationDate.substring(0, 2));
            const expirationYear = Number(cardExpirationDate.substring(3, 8));
            const currentMonth = dayjs().month() + 1;
            const currentYear = dayjs().year();
            if (currentMonth > 0 && currentMonth < 13) {
              if (currentYear > expirationYear || (currentYear === expirationYear && currentMonth > expirationMonth)) {
                isGood = false;
                dispatch(errorAction("The credit card date is expired."));
              }
            } else {
              isGood = false;
              dispatch(errorAction("Expiration month is invalid"));
            }
          } else {
            isGood = false;
            dispatch(errorAction("Please enter a valid credit card month and date"));
          }
        } else {
          isGood = false;
          dispatch(errorAction("Please enter a valid Card Expiration Date."));
        }
        return isGood;
      };
      const { ccExp } = s21CsaState;
      if (validateDate(ccExp) && validateCardType()) {
        if (isCybersourceTimedout(toDay)) {
          const { token, jti, lastFourDigits } = getTimedoutDummyToken();
          dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
        } else {
          const options = {
            expirationMonth: ccExp.substring(0, 2),
            expirationYear: ccExp.substring(3, 8),
          };
          paymentInfo.microform.createToken(options, (err: Error, token: any) => {
            if (err) {
              console.log("error = " + JSON.stringify(err));
              //dispatch(errorAction(getCreateTokenError(err)));
            } else {
              // this will trigger the "submit" useEffect
              const { jti, lastFourDigits } = parseToken(token);
              dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
              dispatch(tceppsActions.serviceOperationAction({ ...s21CsaState, serviceOperation }));
            }
          });
        }
      }
    },
    [dispatch, paymentInfo.microform, s21CsaState, errorState]
  );

  const submitProfileAdd = () => {
    const tcepps = document.getElementsByName("tcepps")[0] as any;
    const ppa = document.getElementsByName("ppa")[0];
    tcepps.serviceOperation.value = "profileAdd";
    profileAdd(tcepps, ppa);
  };

  const submitNewOrderReversal = () => {
    const tcepps = document.getElementsByName("tcepps")[0] as any;
    const pno = document.getElementsByName("pno")[0];
    tcepps.serviceOperation.value = "newOrder_reversal";
    newOrder(tcepps, pno);
  };

  //submit
  useEffect(() => {
    if (paymentInfo.token) {
      if (tceppsState.serviceOperation === PROFILE_ADD) {
        submitProfileAdd();
      } else if (tceppsState.serviceOperation === NEW_ORDER_REVERSAL) {
        submitNewOrderReversal();
      }
    }

    // return () => {
    //   dispatch(resetS21CsaMycsaAction());
    //   dispatch(unmountAction());
    // }
  });

  // useEffect if screen/page input parameters change
  useEffect(() => {
    const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
      let decryptParamsResponse: any;

      try {
        const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
        decryptParamsResponse = response.data;
      } catch (err: any) {
        return false;
      }

      if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
        //don't save decrypted parameters
        // setDecryptParams(decryptParamsResponse);
        if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
          let newState = {
            ...s21CsaState,
            customerName: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.customerName"].toLocaleUpperCase(),
            customerAddress1: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.customerAddress1"].toLocaleUpperCase(),
            customerAddress2: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.customerAddress2"].toLocaleUpperCase(),
            customerCity: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.customerCity"].toLocaleUpperCase(),
            customerState: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.customerState"].toLocaleUpperCase(),
            customerZIP: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.customerZIP"].toLocaleUpperCase(),
            customerCountryCode: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.customerCountryCode"].toLocaleUpperCase(),
            ccExp: TceppsTestData.ToBeAppTestData.S21CSA["tceppsRequest.ppa.expirationMonthYear"].toLocaleUpperCase(),
          };
          dispatch(changeAction(newState));
        }
      }
    };

    if (tceppsState.app !== undefined && tceppsState.app.length > 0 && !loadedTestData) {
      try {
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.border = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.margin = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.padding = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.position = "relative";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.overflow = "auto";
        (document.querySelector("#root") as HTMLDivElement).style.border = "0";
        (document.querySelector("#body") as HTMLDivElement).style.border = "0";
        (document.querySelector("#root") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#body") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#root") as HTMLDivElement).style.padding = "0";
        (document.querySelector("#body") as HTMLDivElement).style.padding = "0";
      } catch (exception) {}

      decryptInputParameterAsync(tceppsState.app, tceppsState.encryptedRequestKey, tceppsState.encryptedRequest);
      setLoadedTestData(true);
    }
  }, [tceppsState.app, tceppsState.encryptedRequestKey, tceppsState.encryptedRequest, dispatch, s21CsaState, loadedTestData]);

  // for handling events from client iframes.
  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.data.type === PROCESS_TRANSACTION) {
        if (event.data.payload === TOGGLE_ADD_PROFILE) {
          dispatch(toggleCreditCardProfileAction());
        } else if (event.data.payload === PROFILE_ADD) {
          if (validateForm()) {
            captureTokenInformation(PROFILE_ADD);
          }
        } else if (event.data.payload === NEW_ORDER_REVERSAL) {
          if (validateForm()) {
            captureTokenInformation(NEW_ORDER_REVERSAL);
          }
        }
      }
    },
    [dispatch, validateForm, captureTokenInformation]
  );

  // to setup and tear down window event handler for messages.
  // useEffect for windows message
  useEffect(() => {
    window.addEventListener("message", windowMessageHandler, false);

    return () => {
      // console.log("REMOVING window message listener");
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler]);

  // load request parameters
  useEffect(() => {
    const processRequest = async (req: any) => {
      const resp = await PaymentService.getDecryptRequest(req.app, req.encryptedRequestKey, req.encryptedRequest);
      req.unencryptedRequest = resp.data;
      dispatch(tceppsActions.initializeTceppsAction(req));

      return () => {
        dispatch(tceppsActions.resetTceppsAction());
      };
    };
    const requestParameters = extractRequestParameters(appKey || "");
    processRequest(requestParameters).then(() => {
      // console.log( 'requestParameters = ' + JSON.stringify(requestParameters));
    });
  }, [dispatch, appKey]);

  //* setup microform...
  useEffect(() => {
    const updateCardBrand = (cardName: string, isValid: boolean, isEmpty: boolean) => {
      const cardBrand = getCardType(cardName);
      if (!isEmpty) {
        if (!cardBrand) {
          dispatch(errorAction("Please enter a visa or a mastercard or amex or discover card number."));
        } else {
          dispatch(errorAction(""));
        }
      }
      dispatch(cardTypeAction({ cardBrand: cardBrand || "", cardValid: isValid ? "Y" : "N" }));
    };

    const initializeMicroform = async (captureContext: any) => {
      const flex = (await new (window as any).Flex(captureContext)) as Microform;
      const microform = await flex.microform({ styles: flexStyles });
      const creditCardMicroNumber = await microform.createField("number", { placeholder: "" });
      creditCardMicroNumberRef.current = creditCardMicroNumber;
      await creditCardMicroNumber.load("#creditCardNumber");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber) {
        creditCardMicroNumber.on("change", (data: any) => {
          if (data.card && data.card.length > 0) {
            updateCardBrand(data.card[0].name, data.valid, data.empty);
          } else {
            updateCardBrand("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          dispatch(creditCardFocusAction(true));
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          dispatch(creditCardFocusAction(false));
        });
        creditCardMicroNumber.on("load", function (data: any) {
          dispatch(loadingAction(false));
          creditCardMicroNumber.focus();
        });
      }
    };

    const getCaptureContext = () => {
      const iframeUrl = getIframeUrl();
      // console.log("iframeUrl:" + iframeUrl);
      PaymentService.getCaptureContext(iframeUrl)
        .then((resp) => {
          const captureContext = resp.data.data;
          dispatch(contextLoadedAction(captureContext));
          if (!(window as any).Flex) {
            loadCybersourceJS(captureContext, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            dispatch(errorAction("AxiosError: call to initialize microForm failed. " + err.message + ". Please check console."));
          } else {
            dispatch(errorAction("Initialize microForm failed. " + err.message + ". Please check console."));
          }
        });
    };

    getCaptureContext();
  }, [dispatch]);

  const handleChange = (event: any) => {
    const field = event.target.name as string;
    const changedState: { [index: string]: string | boolean } = { ...s21CsaState };
    changedState[field] = (event.target.value as string).toUpperCase();
    dispatch(changeAction(changedState));
    _.set(errorState, field + "Missing", false);
    setErrorState(errorState);
  };

  const creditCardStyle = {
    fontSize: "8px",
    fontFamily: "'Arial',sans-serif",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "17px", //"20px"
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    width: "163px", //"163px"
    margin: "0em",
    borderWidth: "1px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  const tooltipStyle = {
    fontSize: "10pt",
    backgroundColor: "#ff6060",
  };

  const getDateErrorMessage = (): string => {
    let msg = "";
    if (errorState.ccExpMissing) {
      msg = "Please enter credit card expiration date.";
    } else if (errorState.ccExpExpired) {
      msg = "Date is expired. Please enter valid date.";
    } else if (errorState.ccExpMonthInvalid) {
      msg = "Please enter a valid date.";
    }
    return msg;
  };

  return (
    <div className="s21csa">
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="lastFourDigits" name="lastFourDigits" type="hidden" value={paymentInfo.lastFourDigits || ""} />
      </form>

      <form id="ppa" name="ppa">
        <input id="cardBrand" name="cardBrand" type="hidden" value={s21CsaState.cardBrand} />
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value={"TT=" + (paymentInfo.jti || "")} />
        <input
          id="ccExp"
          name="ccExp"
          type="hidden"
          value={s21CsaState.ccExp ? s21CsaState.ccExp.substring(3, 8) + s21CsaState.ccExp.substring(0, 2) : ""}
        />
        <input id="customerName" name="customerName" type="hidden" value={s21CsaState.customerName} />
        <input id="customerAddress1" name="customerAddress1" type="hidden" value={s21CsaState.customerAddress1} />
        <input id="customerAddress2" name="customerAddress2" type="hidden" value={s21CsaState.customerAddress2} />
        <input id="customerCity" name="customerCity" type="hidden" value={s21CsaState.customerCity} />
        <input id="customerState" name="customerState" type="hidden" value={s21CsaState.customerState} />
        <input id="customerZIP" name="customerZIP" type="hidden" value={s21CsaState.customerZIP} />
        <input id="customerCountryCode" name="customerCountryCode" type="hidden" value={s21CsaState.customerCountryCode} />
        <input id="merchantID" name="merchantID" type="hidden" value={s21CsaState.merchantID} />
        <input id="profileOverrideFlag" name="profileOverrideFlag" type="hidden" value={s21CsaState.profileOverrideFlag} />
        <input id="orbitalConnectionUsername" name="orbitalConnectionUsername" type="hidden" value="" />
        <input id="orbitalConnectionPassword" name="orbitalConnectionPassword" type="hidden" value="" />
        <input id="orderDefaultDescription" name="orderDefaultDescription" type="hidden" value="" />
        <input id="orderDefaultAmount" name="orderDefaultAmount" type="hidden" value="" />
      </form>

      <form id="pno" name="pno">
        <input id="cardBrand" name="cardBrand" type="hidden" value={s21CsaState.cardBrand} />
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value={"TT=" + (paymentInfo.jti || "")} />
        <input
          id="ccExp"
          name="ccExp"
          type="hidden"
          value={s21CsaState.ccExp ? s21CsaState.ccExp.substring(3, 8) + s21CsaState.ccExp.substring(0, 2) : ""}
        />
        <input id="avsName" name="avsName" type="hidden" value={s21CsaState.customerName} />
        <input id="avsAddress1" name="avsAddress1" type="hidden" value={s21CsaState.customerAddress1} />
        <input id="avsAddress2" name="avsAddress2" type="hidden" value={s21CsaState.customerAddress2} />
        <input id="avsCity" name="avsCity" type="hidden" value={s21CsaState.customerCity} />
        <input id="avsState" name="avsState" type="hidden" value={s21CsaState.customerState} />
        <input id="avsZip" name="avsZip" type="hidden" value={s21CsaState.customerZIP} />
        <input id="avsCountryCode" name="avsCountryCode" type="hidden" value={s21CsaState.customerCountryCode} />
        <input id="merchantID" name="merchantID" type="hidden" value={s21CsaState.merchantID} />
        <input id="orbitalConnectionUsername" name="orbitalConnectionUsername" type="hidden" value="" />
        <input id="orbitalConnectionPassword" name="orbitalConnectionPassword" type="hidden" value="" />
        <input id="switchSoloIssueNum" name="switchSoloIssueNum" type="hidden" value="" />
        <input id="switchSoloCardStartDate" name="switchSoloCardStartDate" type="hidden" value="" />
        <input id="authenticationECIInd" name="authenticationECIInd" type="hidden" value="" />
        <input id="addProfileFromOrder" name="addProfileFromOrder" type="hidden" value="" />
        <input id="profileOrderOverideInd" name="profileOrderOverideInd" type="hidden" value="" />
      </form>

      <div className="s21csa s21csaDivTop">
        <form id="creditCard" name="creditCard">
          <table className="inputStyle" border={0} cellSpacing={1}>
            <tbody>
              <tr>
                <td>Credit Card Account Number</td>
                <td width="180px">
                  <div
                    className={classNames({
                      inputFocused: s21CsaState.creditCardHasFocus,
                      inputNotFocused: !s21CsaState.creditCardHasFocus,
                    })}
                  >
                    <div
                      id="creditCardNumber"
                      style={creditCardStyle}
                      className={classNames({
                        fieldError: errorState.creditCardInvalid || errorState.creditCardMissing,
                        fieldOK: !(errorState.creditCardInvalid || errorState.creditCardMissing),
                      })}
                      tabIndex={1}
                    >
                      <Tooltip
                        anchorSelect="#creditCardNumber"
                        style={tooltipStyle}
                        place="right-start"
                        variant="error"
                        hidden={!errorState.creditCardMissing}
                        content="Please enter a credit card number."
                      />
                      <Tooltip
                        anchorSelect="#creditCardNumber"
                        style={tooltipStyle}
                        place="right-start"
                        variant="error"
                        hidden={!errorState.creditCardInvalid}
                        content="Please enter a visa or a mastercard or amex or discover card number."
                      />
                    </div>
                  </div>
                </td>
                <td className="stab">Card Holder Name</td>
                <td colSpan={3} className="stab">
                  <input
                    className={classNames({
                      textInput: true,
                      fieldError: errorState.customerNameMissing,
                      fieldOK: !errorState.customerNameMissing,
                    })}
                    tabIndex={4}
                    id="customerName"
                    name="customerName"
                    type="text"
                    size={30}
                    maxLength={30}
                    onBlur={ezfToUpperOnBlur}
                    onKeyDown={ezfToUpperOnKeyDown}
                    value={s21CsaState.customerName}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                    onChange={handleChange}
                  />
                  <Tooltip
                    anchorSelect="#customerName"
                    style={tooltipStyle}
                    place="bottom"
                    variant="error"
                    hidden={!errorState.customerNameMissing}
                    content="Please enter card holder name."
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="stab" align="left">
                  &nbsp;&nbsp;&nbsp;&nbsp; <span> </span>
                  <input
                    className="textInput"
                    tabIndex={2}
                    type="checkbox"
                    id="profileOverrideFlag"
                    name="profileOverrideFlag"
                    value={s21CsaState.profileOverrideFlag}
                    onChange={handleChange}
                  />{" "}
                  is this a single charge P-Card?
                </td>
                <td className="stab">Addressline 1</td>
                <td colSpan={3}>
                  <input
                    className={classNames({
                      textInput: true,
                      fieldError: errorState.customerAddress1Missing,
                      fieldOK: !errorState.customerAddress1Missing,
                    })}
                    tabIndex={5}
                    id="customerAddress1"
                    name="customerAddress1"
                    type="text"
                    size={30}
                    maxLength={30}
                    onBlur={ezfToUpperOnBlur}
                    onKeyDown={ezfToUpperOnKeyDown}
                    value={s21CsaState.customerAddress1}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                    onChange={handleChange}
                  />
                  <Tooltip
                    anchorSelect="#customerAddress1"
                    style={tooltipStyle}
                    place="bottom"
                    variant="error"
                    hidden={!errorState.customerAddress1Missing}
                    content="Please enter a customer address."
                  />
                </td>
              </tr>
              <tr>
                <td className="stab">Credit Card Type</td>
                <td>
                  <select
                    className="textInput"
                    tabIndex={-1}
                    id="cardBrand"
                    name="cardBrand"
                    disabled={true}
                    value={s21CsaState.cardBrand}
                    onChange={handleChange}
                  >
                    <option value=""> </option>
                    <option value="AX"> AMEX</option>
                    <option value="DI">DISCOVER</option>
                    <option value="DS">DISCOVER</option>
                    <option value="MC">MASTER</option>
                    <option value="VI">VISA</option>
                  </select>
                </td>
                <td className="stab">Addressline 2</td>
                <td colSpan={3}>
                  <input
                    className="textInput"
                    tabIndex={6}
                    id="customerAddress2"
                    name="customerAddress2"
                    type="text"
                    size={30}
                    maxLength={30}
                    onBlur={ezfToUpperOnBlur}
                    onKeyDown={ezfToUpperOnKeyDown}
                    value={s21CsaState.customerAddress2}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="stab">Card Expiration Date</td>
                <td className="stab">
                  <input
                    className={classNames({
                      textInput: true,
                      fieldError: errorState.ccExpMonthInvalid || errorState.ccExpExpired || errorState.ccExpMissing,
                      fieldOK: !(errorState.ccExpMonthInvalid || errorState.ccExpExpired || errorState.ccExpMissing),
                    })}
                    tabIndex={3}
                    id="ccExp"
                    name="ccExp"
                    type="text"
                    size={7}
                    maxLength={7}
                    value={s21CsaState.ccExp}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                    onChange={handleChange}
                    onKeyUp={keyUpDateEvent}
                  />
                  &nbsp;&nbsp; (MM/YYYY)
                  <Tooltip
                    anchorSelect="#ccExp"
                    style={tooltipStyle}
                    place="bottom"
                    variant="error"
                    hidden={!(errorState.ccExpMonthInvalid || errorState.ccExpExpired || errorState.ccExpMissing)}
                    content={getDateErrorMessage()}
                  />
                </td>
                <td className="stab">City</td>
                <td>
                  <input
                    className={classNames({
                      textInput: true,
                      fieldError: errorState.customerCityMissing,
                      fieldOK: !errorState.customerCityMissing,
                    })}
                    tabIndex={7}
                    id="customerCity"
                    name="customerCity"
                    type="text"
                    size={20}
                    maxLength={20}
                    onBlur={ezfToUpperOnBlur}
                    onKeyDown={ezfToUpperOnKeyDown}
                    value={s21CsaState.customerCity}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                    onChange={handleChange}
                  />
                  <Tooltip
                    anchorSelect="#customerCity"
                    style={tooltipStyle}
                    place="bottom"
                    variant="error"
                    hidden={!errorState.customerCityMissing}
                    content="Please enter a city."
                  />
                </td>
                <td className="stab">State</td>
                <td>
                  <input
                    className={classNames({
                      textInput: true,
                      fieldError: errorState.customerStateMissing,
                      fieldOK: !errorState.customerStateMissing,
                    })}
                    tabIndex={8}
                    id="customerState"
                    name="customerState"
                    type="text"
                    size={2}
                    maxLength={2}
                    onBlur={ezfToUpperOnBlur}
                    onKeyDown={ezfToUpperOnKeyDown}
                    onChange={handleChange}
                    value={s21CsaState.customerState}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                  />
                  <Tooltip
                    anchorSelect="#customerState"
                    style={tooltipStyle}
                    place="bottom"
                    variant="error"
                    hidden={!errorState.customerStateMissing}
                    content="Please enter a state."
                  />
                </td>
              </tr>
              <tr>
                <td> </td>
                <td> </td>
                <td className="stab">Post Code</td>
                <td>
                  <input
                    className={classNames({
                      textInput: true,
                      fieldError: errorState.customerZIPMissing,
                      fieldOK: !errorState.customerZIPMissing,
                    })}
                    tabIndex={9}
                    id="customerZIP"
                    name="customerZIP"
                    type="text"
                    size={10}
                    maxLength={10}
                    onBlur={ezfToUpperOnBlur}
                    onKeyDown={ezfToUpperOnKeyDown}
                    onChange={handleChange}
                    value={s21CsaState.customerZIP}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                  />
                  <Tooltip
                    anchorSelect="#customerZIP"
                    style={tooltipStyle}
                    place="bottom"
                    variant="error"
                    hidden={!errorState.customerZIPMissing}
                    content="Please enter a zip code."
                  />
                </td>
                <td className="stab">Country</td>
                <td>
                  <input
                    className={classNames({
                      textInput: true,
                      fieldError: errorState.customerCountryCodeMissing,
                      fieldOK: !errorState.customerCountryCodeMissing,
                    })}
                    tabIndex={10}
                    id="customerCountryCode"
                    name="customerCountryCode"
                    type="text"
                    size={2}
                    maxLength={2}
                    onBlur={ezfToUpperOnBlur}
                    onKeyDown={ezfToUpperOnKeyDown}
                    onChange={handleChange}
                    value={s21CsaState.customerCountryCode}
                    disabled={!s21CsaState.newCreditCardProfileCreation}
                  />
                  <Tooltip
                    anchorSelect="#customerCountryCode"
                    style={tooltipStyle}
                    place="bottom"
                    variant="error"
                    hidden={!errorState.customerCountryCodeMissing}
                    content="Please enter a country code."
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}
