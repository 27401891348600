import { createSlice } from "@reduxjs/toolkit";
import { PaymentStateInterface } from "../../interface/redux-state/PaymentStateInterface";

const initialState: PaymentStateInterface = {
  isLoading: false,
  isSuccessful: false,
  error: {},
  context: null,
  options: {
    expirationMonth: null,
    expirationYear: null,
  },
  microform: null,
  token: null,
  lastFourDigits: null,
  jti: null,
  permToken: {
    id: null,
    last4: null,
  },
};

export const paymentSlice = createSlice({
  name: "paymentInfo",
  initialState,
  reducers: {
    microformInitializedAction: (state, action) => {
      // console.log("microformInitializedAction payload<" + action.payload + ">");
      // console.log(action.payload);
      state.microform = action.payload;
      state.isLoading = false;
      state.isSuccessful = true;
    },
    contextLoadedAction: (state, action) => {
      state.context = action.payload;
    },
    monthChangedAction: (state, action) => {
      state.options.expirationMonth = action.payload;
    },
    yearChangedAction: (state, action) => {
      state.options.expirationYear = action.payload;
    },
    tokenCreatedAction: (state, action) => {
      state.token = action.payload.token;
      state.lastFourDigits = action.payload.lastFourDigits;
      state.jti = action.payload.jti;
    },
    permTokenCreateAction: (state) => {
      state.isLoading = true;
    },
    permTokenCreateSuccessAction: (state, action) => {
      state.permToken.id = action.payload.token;
      state.permToken.last4 = action.payload.last4;
      state.isLoading = false;
    },
    getContextFailedAction: (state) => {
      state.isLoading = false;
      state.isSuccessful = false;
      state.context = null;
      state.microform = null;
    },
    unmountAction: (state) => {
      state.isLoading = initialState.isLoading;
      state.isSuccessful = initialState.isSuccessful;
      state.error = initialState.error;
      state.context = initialState.context;
      state.options = initialState.options;
      state.microform = initialState.microform;
      state.token = initialState.token;
      state.permToken = initialState.permToken;
    },
    paymentErrorAction: (state, action) => {
      state.error = action.payload.error;
    },
  },
});

export const {
  contextLoadedAction,
  monthChangedAction,
  yearChangedAction,
  tokenCreatedAction,
  microformInitializedAction,
  permTokenCreateAction,
  permTokenCreateSuccessAction,
  getContextFailedAction,
  paymentErrorAction,
  unmountAction,
} = paymentSlice.actions;

export default paymentSlice;
