import ps from "../pre-start";

export interface IUIConfigInterface {
  common: {
    env: string;
    apiUrl: string;
  };
  logging: {
    level: string;
    enabled: string;
  };
  cybersource: {
    microformJs: string;
  };
  cpgmui: {
    publicKey: string;
    expire: number;
  };
}

let config: IUIConfigInterface;

const init = async () => {
  if( !config) {
    const response = await ps.init();
    config = response.data.config;
    console.log('config = ' + JSON.stringify(config));
  }
  return Promise.resolve();
};

init().then(() => {
  // console.log('initialized.')
});

export { config };
