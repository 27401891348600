import { createSlice, Draft } from "@reduxjs/toolkit";
import { ConfigInterface } from "../../interface/redux-state/ConfigInterface";

const initialState: ConfigInterface = {
  isLoading: false,
  isSuccessful: false,
  count: 0,
  config: null,
  error: {},
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    loadConfigAction: (state: any) => {
      state.isLoading = true;
    },
    tryAgainAction: (state: any) => {
      state.count = state.count + 1;
    },
    setConfigAction: (state: Draft<ConfigInterface>, action) => {
      state.count = state.count === 0 ? 1 : state.count;
      state.config = action.payload;
      state.isSuccessful = true;
      state.isLoading = false;
    },
    loadFailedAction: (state: Draft<ConfigInterface>, action) => {
      state.isLoading = false;
      state.isSuccessful = false;
      state.error = action.payload;
    },
  },
});

export const { loadConfigAction, tryAgainAction, setConfigAction, loadFailedAction } = configSlice.actions;

export default configSlice;
