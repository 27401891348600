import {catchError, from, map, mergeMap, of} from 'rxjs';
import {ofType} from 'redux-observable';
import {
  loadConfigAction,
  setConfigAction,
  loadFailedAction
} from '../reducer/configSlice';
import ConfigService from '../../service/configService';

export const loadConfigEpic = (action$: any, state$:any) => {
  return action$.pipe(
    ofType(loadConfigAction),
    mergeMap( (action: any) => {
        return from(
          ConfigService.loadConfig()
        ).pipe(
          map((response: any) => {
            if (response.data) {
              return setConfigAction(response.data.config);
            } else {
              throw response;
            }
          }),
          catchError((err) => {
            let result = {
              message: err
            }
            return of(loadFailedAction(result));
          })
        );
      }
    )
  );
}

