import {configureStore} from '@reduxjs/toolkit'
import rootReducer from './reducer/rootReducer';
import {createEpicMiddleware} from 'redux-observable';
import {rootEpic} from './epic/rootEpic';

const epicMiddleware = createEpicMiddleware();
const AppReduxStore = configureStore({
    reducer: rootReducer(),
    middleware: [epicMiddleware]
});
epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof AppReduxStore.getState>
export type AppDispatch = typeof AppReduxStore.dispatch

export default AppReduxStore;



