import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {S21CsaInterface} from "../../interface/redux-state/S21CsaInterface";

const initialState: S21CsaInterface = {
  loading: false,
  cardBrand: '',
  ccAccountNum: '',
  cardValid: true,
  creditCardHasFocus: false,
  ccExp: '',
  customerName: '',
  customerAddress1: '',
  customerAddress2: '',
  customerCity: '',
  customerState: '',
  customerZIP: '',
  customerCountryCode: 'US',
  merchantID: '',
  profileOverrideFlag: '',
  newCreditCardProfileCreation: true,
  error: ''

};

export const s21CsaSlice = createSlice({
  name: "s21csa",
  initialState,
  reducers: {
    resetS21CsaAction: (state: Draft<S21CsaInterface>) => {
      state.loading = initialState.loading;
      state.cardBrand = initialState.cardBrand;
      state.ccAccountNum = initialState.ccAccountNum;
      state.cardValid = initialState.cardValid;
      state.creditCardHasFocus = initialState.creditCardHasFocus;
      state.ccExp = initialState.ccExp;
      state.customerAddress1 = initialState.customerAddress1;
      state.customerAddress2 = initialState.customerAddress2;
      state.customerCity = initialState.customerCity;
      state.customerState = initialState.customerState;
      state.customerZIP = initialState.customerZIP;
      state.customerCountryCode = initialState.customerCountryCode;
      state.merchantID = initialState.merchantID;
      state.profileOverrideFlag = initialState.profileOverrideFlag;
      state.newCreditCardProfileCreation = initialState.newCreditCardProfileCreation;
      state.error = initialState.error;
    },
    changeAction: (state:Draft<S21CsaInterface>, action) =>{
      state.loading = action.payload.loading;
      state.cardBrand = action.payload.cardBrand;
      state.ccAccountNum = action.payload.ccAccountNum;
      state.cardValid = action.payload.cardValid;
      state.ccExp = action.payload.ccExp;
      state.customerName = action.payload.customerName;
      state.customerAddress1 = action.payload.customerAddress1;
      state.customerAddress2 = action.payload.customerAddress2;
      state.customerCity = action.payload.customerCity;
      state.customerState = action.payload.customerState;
      state.customerZIP = action.payload.customerZIP;
      state.customerCountryCode = action.payload.customerCountryCode;
      state.merchantID = action.payload.merchantID;
      state.profileOverrideFlag = action.payload.profileOverrideFlag;
      state.newCreditCardProfileCreation = action.payload.newCreditCardProfileCreation;
      state.error = action.payload.error;
    },
    cardTypeAction:(state:Draft<S21CsaInterface>, action) =>{
      state.cardValid = action.payload.cardValid;
      state.cardBrand = action.payload.cardBrand;
    },
    creditCardFocusAction: (state:Draft<S21CsaInterface>, action) => {
      state.creditCardHasFocus = action.payload;
    },
    loadingAction: (state:Draft<S21CsaInterface>, action) => {
      state.loading = action.payload;
    },
    toggleCreditCardProfileAction: (state:Draft<S21CsaInterface>) => {
      state.newCreditCardProfileCreation = !state.newCreditCardProfileCreation;
      if( !state.newCreditCardProfileCreation) {
        state.ccExp = '';
        state.customerName = '';
        state.customerAddress1 = '';
        state.customerAddress2 = '';
        state.customerCity = '';
        state.customerState = '';
        state.customerZIP = '';
        state.customerCountryCode = '';
      }
    },
    errorAction: (state:Draft<S21CsaInterface>, action) =>{
      state.error = action.payload;
    }
  }
});

export const {
  resetS21CsaAction,
  changeAction,
  cardTypeAction,
  creditCardFocusAction,
  loadingAction,
  errorAction,
  toggleCreditCardProfileAction
} = s21CsaSlice.actions;

export default s21CsaSlice;
