import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {S21CsaMyCsaInterface} from "../../interface/redux-state/S21CsaMyCsaInterface";

const initialState: S21CsaMyCsaInterface = {
  loading: true,
  creditCardHasFocus: false,
  cardValid: '',
  creditCardType: '',
  cardExpirationDate: '',
  error: ""
};

export const s21CsaMyCsaSlice = createSlice({
  name: "s21csamycsa",
  initialState,
  reducers: {
    resetS21CsaMycsaAction: (state: Draft<S21CsaMyCsaInterface>) => {
      state.loading = initialState.loading;
      state.creditCardHasFocus = initialState.creditCardHasFocus;
      state.cardValid = initialState.cardValid;
      state.creditCardType = initialState.creditCardType;
      state.cardExpirationDate = initialState.cardExpirationDate;
      state.error = initialState.error;
    },
    changeAction: (state:Draft<S21CsaMyCsaInterface>, action) =>{
      state.loading = action.payload.loading;
      state.creditCardHasFocus = action.payload.creditCardHasFocus;
      state.cardValid = action.payload.cardValid;
      state.creditCardType = action.payload.creditCardType;
      state.cardExpirationDate = action.payload.cardExpirationDate;
      state.error = action.payload.error;
    },
    cardTypeAction:(state:Draft<S21CsaMyCsaInterface>, action) =>{
      state.cardValid = action.payload.cardValid;
      state.creditCardType = action.payload.creditCardType;
    },
    creditCardFocusAction: (state:Draft<S21CsaMyCsaInterface>, action) => {
      state.creditCardHasFocus = action.payload;
    },
    loadingAction: (state:Draft<S21CsaMyCsaInterface>, action) => {
      state.loading = action.payload;
    },
    errorAction: (state:Draft<S21CsaMyCsaInterface>, action) =>{
      state.error = action.payload;
    }
  }
});

export const {
  resetS21CsaMycsaAction,
  changeAction,
  cardTypeAction,
  creditCardFocusAction,
  loadingAction,
  errorAction
} = s21CsaMyCsaSlice.actions;

export default s21CsaMyCsaSlice;
