import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TceppsProxyStateInterface } from "../../interface/redux-state/TceppsProxyStateInterface";

const initialState: TceppsProxyStateInterface = {
  app: "",
  serviceOperation: "",
  addProfile: "",
};

export const tceppsProxySlice = createSlice({
  name: "tceppsProxy",
  initialState,
  reducers: {
    initializeTceppsAction: (state: Draft<TceppsProxyStateInterface>, action) => {
      state.app = action.payload.app;
      state.serviceOperation = action.payload.serviceOperation;
      state.addProfile = action.payload.addProfile;
    },
  },
});

export const tceppsProxyActions = tceppsProxySlice.actions;

export default tceppsProxySlice;
