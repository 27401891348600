import { ApiConstants } from "../utils/ApiConstants";
import axios from "axios";

export class LogService {
  static logMessage = async (id: string, level: string, message: string) => {
    level = level.toLowerCase();
    return await axios.post(ApiConstants.logMessage, {
      id,
      level,
      message,
    });
  };
}

export const LOGINFOLEVEL = "info";
export const LOGERRORLEVEL = "error";
