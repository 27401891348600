export const AppEvents = {
  iframeInitialized: "iframeInitialized",
  validated: "validated",
  response: "response",
  error: "error",
};

export const CpgmuiEvents = {
  execute: "execute",
  validate: "validate",
  custom: "custom",
};

export const ServiceOperations = {
  profileAdd: "profileAdd",
  newOrder: "newOrder",
  newOrderReversal: "newOrderReversal",
};

type CpgmuiEvent = {
  type: string;
  data: any;
};

// type ValidationInfo = {
//   hasError: boolean;
//   errors: {
//     ccExpMissing: boolean;
//     ccExpMonthInvalid: boolean;
//     ccExpExpired: boolean;
//     customerNameMissing: boolean;
//     customerAddress1Missing: boolean;
//     cardBrandMissing: boolean;
//     customerAddress2Missing: boolean; // always false.
//     customerCityMissing: boolean;
//     customerStateMissing: boolean;
//     customerZIPMissing: boolean;
//     customerCountryCodeMissing: boolean;
//     creditCardMissing: boolean;
//     creditCardInvalid: boolean;
//     merchantIDMissing: boolean;
//   };
// };

export class Cpgmui {
  private targetOrigin;

  constructor(url: string) {
    const index = url.indexOf("/", "https://".length);
    this.targetOrigin = url.substring(0, index);
  }

  public iframeInitialized() {
    const cpgmuiEvent = {
      type: AppEvents.iframeInitialized,
      data: null,
    };
    this.postMessage(cpgmuiEvent);
  }
  public validated(data: any) {
    const cpgmuiEvent = {
      type: AppEvents.validated,
      data,
    };
    this.postMessage(cpgmuiEvent);
  }

  public response(data: any) {
    const cpgmuiEvent = {
      type: AppEvents.response,
      data,
    };
    this.postMessage(cpgmuiEvent);
  }

  public error(errors: Array<string>) {
    const cpgmuiEvent = {
      type: AppEvents.error,
      data: {
        errors,
      },
    };
    this.postMessage(cpgmuiEvent);
  }

  private postMessage = (cpgmuiEvent: CpgmuiEvent) => {
    // window.top!.postMessage(cpgmuiEvent, this.targetOrigin);
    window.parent.postMessage(cpgmuiEvent, "*");
  };
}
