import { FocusEvent, ChangeEvent, KeyboardEvent, useCallback, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isFirefox } from "react-device-detect";
import axios, { AxiosError } from "axios";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tokenCreatedAction, microformInitializedAction, unmountAction, contextLoadedAction } from "../../redux-store/reducer/paymentSlice";
import { config } from "../../config/uiconfig";
import { LogService, LOGERRORLEVEL } from "../../service/logService";
import PaymentService from "../../service/paymentService";
import {
  decryptParamType,
  getYearsFromToday,
  getCnaUsStates,
  isCybersourceTimedout,
  getTimedoutDummyToken,
} from "../../utils/calendarUtil";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import { parseToken, extractDomain } from "../../utils/utils";
import { isValidUSZip, isValidState } from "../../utils/UsStates";
import { newOrder1 } from "../../resources/creditCardFormPage/js/tcepps";
import { ComponentVarProps, componentVarObject } from "../../utils/ComponentConstant";

import classesCna from "./Cna.module.css";
import "./Cnabody.css";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
var creditCardMicroNumber: any;
const dialogTimeoutInMins = 3; // 3 minutes

const creditCardStyle = {
  fontSize: "9pt",
  fontFamily: "Arial",
  paddingLeft: "4px",
  marginRight: "20px",
  height: "22px",
  width: "190px",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  // backgroundColor: "#f8f8f8",
  margin: "0em",
  borderWidth: "0px",
  // width: "100%",
  borderStyle: "inset",
  // borderColor: "-internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
  backgroundColor: "white",
  borderImage: "initial",
} as React.CSSProperties;
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;
const poStyleObj = {
  fontSize: "small",
  width: "25%",
  textAlign: "right",
  align: "right",
  border: "1px solid rgb(0, 0, 0)",
} as React.CSSProperties;
const copyWriteStyleObj = {
  fontSize: "x-small",
  fontFace: "Arial",
  color: "rgb(169, 169, 169)",
  textAlign: "center",
  align: "center",
} as React.CSSProperties;
const questionStyleObj = {
  fontSize: "small",
  textAlign: "center",
  align: "center",
} as React.CSSProperties;
const tableMainStyle = {
  border: "0",
  borderCollapse: "collapse",
  borderSpacing: "0",
  padding: "0",
  width: "100%",
} as React.CSSProperties;
const tableSecondStyle = {
  // border: "0",
  width: "100%",
} as React.CSSProperties;
const tableThirdStyle = { border: "1px solid rgb(0, 0, 0)", borderColor: "black", width: "100%" } as React.CSSProperties;
const tdInputStyle = { wordWrap: "break-word", width: "100%", border: "1px solid rgb(0, 0, 0)" } as React.CSSProperties;

const toDay = new Date();
const currentMonthYear = toDay.toLocaleString("en-US", { year: "numeric" }) + toDay.toLocaleString("en-US", { month: "2-digit" });
const currentYear = toDay.toLocaleString("en-US", { year: "numeric" });
const copyWrite = "Copyright@;1999 -" + currentYear + " Canon U.S.A., Inc. All Rights Reserved.";

const EMPTYADDRESS1MESSAGE = "[Invalid Billing Street Address.]";
const EMPTYCITYMESSAGE = "[Invalid Billing City.]";
const EMPTYAVSNAMEMESSAGE = "[Invalid Credit Card Name.]";
const EMPTYSTATEMESSAGE = "[Invalid Billing State.]";
const EMPTYZIPMESSAGE = "[Invalid Billing Zip Code.]";
const FORMINITIZING = "Browser initializing. Please wait and retry.";
const INVALIDCARDMESSAGE = "[Invalid Credit Card Number.]";
const INVALIDDATEMESSAGE = "[Invalid Expiration Month. Credit Card has Expired.]";
const INVALIDMONTHMESSAGE = "[Invalid Expiration Month.]";
const INVALIDYEARMESSAGE = "[Invalid Expiration Year.]";
const INVALIDMESSAGEEND = " Please resubmit the order.";

const INVALIDAXIOSMESSAGE = "AxiosError. ";

export function CnaComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [avsName, setAvsName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [transactionAmount, setTransactionAmount] = useState("");
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");

  const [creditCardNumberDisabled, setCreditCardNumberDisabled] = useState(false);
  const [expirationMonthDisabled, setExpirationMonthDisabled] = useState(false);
  const [expirationYearDisabled, setExpirationYearDisabled] = useState(false);
  const [avsNameDisabled, setAvsNameDisabled] = useState(false);
  const [address1Disabled, setAddress1Disabled] = useState(false);
  const [address2Disabled, setAddress2Disabled] = useState(false);
  const [cityDisabled, setCityeDisabled] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(false);
  const [zipDisabled, setZipDisabled] = useState(false);
  const [transactionAmountDisabled] = useState(true);
  const [tceppsCancelUrl, setTceppsCancelUrl] = useState("");

  const creditCardNumberRef = useRef<HTMLDivElement>(null);
  const expirationMonthRef = useRef<HTMLSelectElement>(null);
  const expirationYearRef = useRef<HTMLSelectElement>(null);
  const errorMessagesRef = useRef<HTMLTextAreaElement>(null);
  const avsNameRef = useRef<HTMLInputElement>(null);
  const address1Ref = useRef<HTMLInputElement>(null);
  const address2Ref = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const zipRef = useRef<HTMLInputElement>(null);
  const transactionAmountRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const orbitalConnectionUsernameRef = useRef<string>("");
  const orbitalConnectionPasswordRef = useRef<string>("");
  const switchSoloIssueNumRef = useRef<string>("");
  const switchSoloCardStartDateRef = useRef<string>("");
  const authenticationECIIndRef = useRef<string>("");
  const parameter1Ref = useRef<string>("");
  const parameter2Ref = useRef<string>("");
  const parameter3Ref = useRef<string>("");
  const partsOrderCureffRef = useRef<string>("");
  const partsOrderShipToIdRef = useRef<string>("");

  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);

  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const countDownRef = useRef(-1);

  const logErrorRef = useRef<string>("CNA IFRAME");

  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);

  const [creditcardValid, setCreditcardValid] = useState(false);

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);
  const [proxyUrl, setProxyUrl] = useState("");

  const { appKey } = useParams();

  const confirmCancelDialog = document.getElementById("confirmCancelDialog") as HTMLDialogElement;

  function setCreditCardType(newValue: string) {
    switch (newValue) {
      case "VI":
      case "visa":
        componentObjRef.current.glblCreditCardType = "VI";
        break;
      case "MC":
      case "mastercard":
        componentObjRef.current.glblCreditCardType = "MC";
        break;
      case "AX":
      case "amex":
        componentObjRef.current.glblCreditCardType = "AX";
        break;
      case "DI":
      case "discover":
        componentObjRef.current.glblCreditCardType = "DI";
        break;
      default:
        componentObjRef.current.glblCreditCardType = "";
        break;
    }
  }

  // Close the transaction processing dialog
  const closeDialog = useCallback(() => {
    // $("div#transactionProcessing").dialog().dialog("close");
    if (dialogRef) dialogRef.current?.close();

    // Always destroy the countdown timer object when the dialog is closed
    if (countDownRef.current && countDownRef.current !== -1) clearInterval(countDownRef.current);
    // $("#durationMessage").countdown("destroy");

    return false;
  }, [dialogRef]);

  const openConfirmCancelDialog = () => {
    if (confirmCancelDialog) confirmCancelDialog.showModal();
  };
  const closeConfirmCancelDialog = () => {
    if (confirmCancelDialog) confirmCancelDialog.close();
  };

  const expiredDuration = () => {
    closeDialog();
    // $("#errorMessages").text("Order was not processed. Please resubmit");
    setErrorMessages("Order was not processed. Please resubmit");
  };

  /*
   ** Countdown timer related code START
   */
  const getTimeRemaining = (e: Date) => {
    return Date.parse(e.toDateString()) - Date.parse(new Date().toDateString());
  };

  const startTimer = (e: Date) => {
    let totalTimeLeft = getTimeRemaining(e);
    if (totalTimeLeft < 0) {
      // do action
      expiredDuration();
    }
  };

  const startTimerForClosingTransactionProcessingDialog = (e: Date) => {
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (countDownRef.current && countDownRef.current !== -1) clearInterval(countDownRef.current);
    const id = window.setInterval(() => {
      startTimer(e);
    }, 1000);
    countDownRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setMinutes(deadline.getMinutes() + dialogTimeoutInMins);
    return deadline;
  };

  /*
   ** Countdown timer related code END
   */

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";

    if (decryptParams && Object.keys(decryptParams).length > 0 && submitButtonEnable) {
      let setFocusSet = false;

      if (componentObjRef.current.cardMessage !== "" || !creditcardValid) {
        errorMessage += INVALIDCARDMESSAGE;
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
          setFocusSet = true;
        }
      } else {
        if (componentObjRef.current.otherErrMsg !== "") {
          errorMessage += INVALIDCARDMESSAGE;
          if (!setFocusSet) {
            if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
              creditCardMicroNumber.focus();
            }
          }
          setFocusSet = true;
        }
      }

      if (expirationMonthRef.current?.value === "" || expirationMonthRef.current?.value === "MM") {
        errorMessage += INVALIDMONTHMESSAGE;
        if (!setFocusSet) expirationMonthRef.current?.focus();
        setFocusSet = true;
      }

      if (expirationYearRef.current?.value === "" || expirationYearRef.current?.value === "YYYY") {
        errorMessage += INVALIDYEARMESSAGE;
        if (!setFocusSet) expirationYearRef.current?.focus();
        setFocusSet = true;
      }

      let userMonth = expirationYearRef.current?.value + "" + expirationMonthRef.current?.value;
      if (userMonth < currentMonthYear) {
        errorMessage += INVALIDDATEMESSAGE;
        if (!setFocusSet) expirationMonthRef.current?.focus();
        setFocusSet = true;
      }

      if (avsNameRef.current?.value === "") {
        errorMessage += EMPTYAVSNAMEMESSAGE;
        if (!setFocusSet) avsNameRef.current?.focus();
        setFocusSet = true;
      }

      if (address1Ref.current?.value === "") {
        errorMessage += EMPTYADDRESS1MESSAGE;
        if (!setFocusSet) address1Ref.current?.focus();
        setFocusSet = true;
      }

      if (cityRef.current?.value === "") {
        errorMessage += EMPTYCITYMESSAGE;
        if (!setFocusSet) cityRef.current?.focus();
        setFocusSet = true;
      }

      if (stateRef.current?.value.trim() === "") {
        errorMessage += EMPTYSTATEMESSAGE;
        if (!setFocusSet) stateRef.current?.focus();
        setFocusSet = true;
      }

      if (zipRef.current?.value === "" || !isValidUSZip(zipRef.current?.value as string)) {
        errorMessage += EMPTYZIPMESSAGE;
        if (!setFocusSet) zipRef.current?.focus();
        setFocusSet = true;
      }
    } else {
      errorMessage = FORMINITIZING;
    }

    if (errorMessage === "") {
      setErrorMessages("");
      // isBackgroundRed = false;
      return true;
    } else {
      setErrorMessages(errorMessage + INVALIDMESSAGEEND);
      componentObjRef.current.processingForm = false;
      // isBackgroundRed = true;
      // Close the transaction processing dialog for all error conditions
      closeDialog();
      return false;
    }
  }, [creditcardValid, decryptParams, submitButtonEnable, closeDialog]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardNumberDisabled(disabled);
    setExpirationMonthDisabled(disabled);
    setExpirationYearDisabled(disabled);
    setAvsNameDisabled(disabled);
    setAddress1Disabled(disabled);
    setAddress2Disabled(disabled);
    setCityeDisabled(disabled);
    setStateDisabled(disabled);
    setZipDisabled(disabled);
    setClickSubmit(disabled);
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    const tokenArray = micoFormtoken.split(".");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    return [jti, lastFourDigits];
  };

  const invokeNewOrder = useCallback(
    async (pno: any, micoFormtoken: string, tcepps: any) => {
      if (componentObjRef.current.processingForm) {
        let expYearMonth = "";
        if (
          expirationMonthRef.current?.value &&
          expirationMonthRef.current?.value.length > 0 &&
          expirationYearRef.current?.value &&
          expirationYearRef.current?.value.length > 0
        ) {
          expYearMonth = expirationYearRef.current?.value + expirationMonthRef.current?.value;
        }

        const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);

        let successUrl = decryptParams["tcepps.successUrl"];
        let orderID = decryptParams["pno.orderID"];
        let amount = decryptParams["pno.amount"];
        let email = decryptParams["email"];
        if (!(email && email.length > 0)) {
          // email = DEFUSEREMAIL;
          email = decryptParams["pno.email"];
        }
        if (!(email && email.length > 0)) {
          // email = DEFUSEREMAIL;
          email = decryptParams["pno.customerEmail"];
        }
        let countryCd = decryptParams["country"];
        if (!(countryCd && countryCd.length > 0)) {
          countryCd = "US";
        }
        if (developmentEnv) {
          let address = address1Ref.current?.value;
          if (address2Ref.current?.value) {
            address = address + " " + address2Ref.current?.value;
          }
          if (address) {
            if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
              amount = "40100";
            }
            if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
              amount = "283600";
            }
            if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
              amount = "700500";
              // cvv = "111";
            }
            if (address.toLowerCase().indexOf("testapifailure") >= 0) {
              email = "abcd@";
            }
            if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
              amount = "250900"; //2509 2521
            }
            if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
              amount = "252100"; //2509 2521
            }
          }
        }
        let customerName = avsNameRef.current?.value;
        customerName = customerName ? customerName : "";
        customerName = customerName.replaceAll("  ", " ");
        tcepps.serviceOperation.value = "newOrder";
        pno.ccAccountNum.value = "TT=" + jti;
        pno.ccExp.value = expYearMonth;
        pno.avsName.value = customerName;
        pno.avsZip.value = zipRef.current?.value;
        pno.avsAddress1.value = address1Ref.current?.value;
        pno.avsAddress2.value = address2Ref.current?.value;
        pno.avsCity.value = cityRef.current?.value;
        pno.avsState.value = stateRef.current?.value;
        orderID = orderID.replace(/\D/g, "");
        pno.retryTrace.value = orderID;
        pno.amount.value = amount;
        pno.customerEmail.value = email ? email.trim() : "";
        pno.avsCountryCode.value = countryCd.toUpperCase().trim();
        pno.cardBrand.value = componentObjRef.current.glblCreditCardType;
        pno.merchantID.value = decryptParams["pno.merchantID"];
        pno.comments.value = decryptParams["pno.comments"];
        pno.priorAuthCd.value = decryptParams["pno.priorAuthCd"];
        tcepps.lastFourDigits.value = lastFourDigits;
        tcepps.successUrl.value = successUrl;
        tcepps.cancelUrl.value = tceppsCancelUrl !== null ? tceppsCancelUrl : "";
        tcepps.app.value = tceppsState.app.toUpperCase().trim();
        tcepps.token.value = tceppsState.token;
        tcepps.sign.value = tceppsState.sign;
        toggleFields("false");

        try {
          newOrder1(tcepps, pno);
        } catch (err: any) {
          // console.log(err);
          closeDialog();
          setErrorMessages("Call to submit form <NewOrder_Reversal> failed. " + err.message);
          componentObjRef.current.processingForm = false;
          // setSubmitBackground("");
          LogService.logMessage(
            logErrorRef.current,
            LOGERRORLEVEL,
            "Call to newOrder1 failed:" + (err ? JSON.stringify(err.message) : "null")
          );
        }
      }
    },
    [toggleFields, tceppsState.app, tceppsState.token, tceppsState.sign, decryptParams, closeDialog, tceppsCancelUrl]
  );

  const invokeCancel = (creditCard: any) => {
    creditCard.action = tceppsCancelUrl;
    creditCard.submit();
  };

  const clearMessages = () => {
    setErrorMessages("Cancel Transaction....");
    setErrorMessages("");
  };

  const clearForm = (creditCard: any) => {
    creditCard.address1.value = "";
    creditCard.address2.value = "";
    creditCard.city.value = "";
    creditCard.zip.value = "";
    creditCard.transactionAmount.value = "";
    creditCard.avsName.value = "";
    setExpirationYear("");
    setExpirationMonth("");
    setAvsName("");
    closeDialog();
    clearMessages();
  };

  const cancelOrder = () => {
    closeConfirmCancelDialog();
    clearForm(document.getElementById("creditCard"));
    invokeCancel(document.getElementById("creditCard"));
  };

  const createCybersourceToken = useCallback(async () => {
    if (isCybersourceTimedout(toDay)) {
      const { token, jti, lastFourDigits } = getTimedoutDummyToken();
      dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
    } else {
      componentObjRef.current.cardMessage = "";

      const options = {
        expirationMonth: expirationMonthRef.current?.value,
        expirationYear: expirationYearRef.current?.value,
      };

      // async function createMicroformToken(options: any) {
      await paymentInfo.microform.createToken(options, (err: Error, token: any) => {
        if (err) {
          // console.log(err);
          componentObjRef.current.otherErrMsg = "";
          componentObjRef.current.cardMessage = "";
          let foundCardMessage = true;
          // handle error
          // @ts-ignore
          switch (err.reason) {
            case "CREATE_TOKEN_NO_FIELDS_LOADED":
              componentObjRef.current.otherErrMsg = "Token creation error, no fields have been loaded." + err.message;
              break;
            case "CREATE_TOKEN_TIMEOUT":
              componentObjRef.current.otherErrMsg = "createToken call was unable to proceed." + err.message;
              break;
            case "CREATE_TOKEN_XHR_ERROR":
              componentObjRef.current.otherErrMsg = "Network error when attempting to create a token." + err.message;
              break;
            case "CREATE_TOKEN_NO_FIELDS":
              componentObjRef.current.otherErrMsg = "Data fields are unavailable for collection." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_PARAMS":
              componentObjRef.current.otherErrMsg = "Issue with parameters supplied to createToken." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_FIELDS":
              // @ts-ignore
              if (err.details && err.details.length > 0) {
                // @ts-ignore
                if (err.details.length > 1) {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "number") {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  // @ts-ignore
                } else {
                  componentObjRef.current.otherErrMsg = err.message;
                }
              } else {
                componentObjRef.current.otherErrMsg = err.message;
              }
              break;
            case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
              componentObjRef.current.otherErrMsg = "Server-side validation rejects the createToken request." + err.message;
              break;
            case "CREATE_TOKEN_UNABLE_TO_START":
              componentObjRef.current.otherErrMsg = "Loaded field was able to handle the createToken request." + err.message;
              break;
            default:
              componentObjRef.current.otherErrMsg = "Unknown error:" + err.message;
              break;
          }
          if (!foundCardMessage) componentObjRef.current.cardMessage = "";
          checkCreditCard();
          componentObjRef.current.processingForm = false;
        } else {
          const { jti, lastFourDigits } = parseToken(token);
          dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
          //   dispatch(tokenCreatedAction(token));
        }
      });
    }
    // createToken(options);
  }, [paymentInfo.microform, dispatch, checkCreditCard]);

  // useEffect when new token is generated submit form
  useEffect(() => {
    // console.log("useEffect submit form...2");
    if (componentObjRef.current.processingForm && paymentInfo.token) {
      if (checkCreditCard()) {
        invokeNewOrder(document.getElementById("pno"), paymentInfo.token, document.getElementById("tcepps"));
      } else {
        componentObjRef.current.processingForm = false;
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeNewOrder]);

  // Open the transaction processing dialog
  const openDialog = () => {
    // If the dialog stays open for a certain amount of time, close it because it means an exception happened and user will then
    // be able to re-submit if it's within the token expiration timeframe
    startTimerForClosingTransactionProcessingDialog(getDeadTime());
    if (dialogRef) dialogRef.current?.showModal();

    //$("div#transactionProcessing").dialog().dialog("open");
    //(document.getElementById("errorMessages") as HTMLInputElement).innerHTML = "";
    setErrorMessages("");
    return false;
  };

  // When Submit button is clicked
  const onClickHandler = (event: any) => {
    event.preventDefault();
    switch (event.target.value) {
      case "Cancel":
        // event.preventDefault();
        openConfirmCancelDialog();
        break;
      case "Submit":
        if (submitVisible && !componentObjRef.current.processingForm) {
          // Open the transaction processing dialog
          openDialog();

          if (checkCreditCard()) {
            componentObjRef.current.processingForm = true;
            createCybersourceToken();
            // invokeNewOrder();
          }
        }
        break;
      case "Yes":
        if (event.target.id === "cancelOrder") {
          cancelOrder();
        }
        break;
      case "No":
        if (event.target.id === "closeConfirmCancel") {
          closeConfirmCancelDialog();
        }
        break;
    }
  };

  const windowMessageHandler = useCallback((event: MessageEvent) => {
    // console.log(event.data);
    // console.log(event.source);
    if (
      event.data &&
      (event.data as any).data !== undefined &&
      (event.data as any).data &&
      ((event.data as any).data as any).focusShift !== undefined &&
      ((event.data as any).data as any).focusShift &&
      (event.data as any).event !== undefined &&
      (event.data as any).event === "blur" &&
      event.currentTarget &&
      (event.currentTarget as any).name !== undefined &&
      (event.currentTarget as any).name &&
      (event.currentTarget as any).name === "creditCardFormIFrame"
    ) {
      if (((event.data as any).data as any).focusShift === "next") {
        if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
          componentObjRef.current.microFromFocusField = "";
        } else {
          componentObjRef.current.microFromFocusField = "";
          expirationMonthRef.current?.focus();
        }
      } else if (((event.data as any).data as any).focusShift === "previous") {
        if (componentObjRef.current.microFromFocusField === "securityCode") {
          componentObjRef.current.microFromFocusField = "";
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        } else {
          componentObjRef.current.microFromFocusField = "";
          submitButtonRef.current?.focus();
        }
      }
      // } else if (
      //   event.data &&
      //   event.data.type !== undefined &&
      //   event.data.type &&
      //   event.data.type === "INIT_INSTANCE" &&
      //   event.source &&
      //   (event.source as any).name !== undefined &&
      //   (event.source as any).name &&
      //   (event.source as any).name === "creditCardFormIFrame"
      // ) {
      //   if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
      //     creditCardMicroNumber.focus();
      //     setSubmitButtonEnable(true);
      //     setSubmitVisible(true);
      //   }
      // } else if (
      //   (isIE || isSafari || isFirefox || isEdge) &&
      //   event.data &&
      //   event.data.event !== undefined &&
      //   event.data.event &&
      //   event.data.event === "load"
      // ) {
      //   if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
      //     creditCardMicroNumber.focus();
      //     setSubmitButtonEnable(true);
      //     setSubmitVisible(true);
      //   }
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("cnaBODY");
    // (document.querySelector("#root") as HTMLDivElement).style.width = "100%";
    return () => {
      document.body.classList.remove("cnaBODY");
    };
  }, []);

  // useEffect for windows message
  useEffect(() => {
    // console.log("useEffect addEventListener...3");
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    // console.log("useEffect initialload...4");

    // setSubmitBackground("");

    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
    };

    const getPortNumber = (inputUrl: string) => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };

    //find port if it exits
    const urlPortNumber = getPortNumber(window.location.href);

    Object.keys(mydata).forEach((key) => {
      switch (key) {
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber(requestParameter.cpgmuiApiUrl);
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    dispatch(
      tceppsActions.initializeTceppsAction({
        app: requestParameter.app,
        token: requestParameter.token,
        sign: requestParameter.sign,
        serviceOperation: requestParameter.serviceOperation,
        encryptedRequest: requestParameter.encryptedRequest,
        encryptedRequestKey: requestParameter.encryptedRequestKey,
        enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
        cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
        cpgmuiEnv: requestParameter.cpgmuiEnv,
      })
    );

    try {
      getYearsFromToday(10, document.getElementById("expirationYear") as HTMLSelectElement, true);
      getCnaUsStates(document.getElementById("state") as HTMLSelectElement, true);
    } catch (error) {}

    // if (developmentEnv && isDevUrl(window.location.href)) {
    //   setExpirationMonth(TceppsTestData.ToBeAppTestData.CNA["to_be_app.expirationMonth"]);
    //   setExpirationYear(TceppsTestData.ToBeAppTestData.CNA["to_be_app.expirationYear"]);
    // } else {
    //   setExpirationMonth("");
    //   setExpirationYear("");
    // }

    setCreditCardType("");

    // decrypt the input parameters
    const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
      let decryptParamsResponse: any;

      try {
        const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
        decryptParamsResponse = response.data;
      } catch (err: any) {
        // console.log(err);
        if (axios.isAxiosError(err)) {
          // Access to config, request, and response
          setErrorMessages(INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ". Please check console..");
        } else {
          // Just a stock error
          setErrorMessages("Error in getDecryptRequest. " + err.message + ". Please check console..");
        }
        LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "PaymentService.getDecryptRequest call failed. " + err.message);
        // isBackgroundRed = true;
      }

      if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
        // check for proxy
        let iFrameUrl = document.referrer;
        if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
          let proxyUrlParam = decryptParamsResponse["tcepps.proxyUrl"];
          let successUrlParam = decryptParamsResponse["tcepps.successUrl"];
          if (proxyUrlParam && proxyUrlParam.trim().length > 0) {
            setProxyUrl(proxyUrlParam);
          } else if (successUrlParam && successUrlParam.trim().length > 0) {
            setProxyUrl(successUrlParam);
          }
        }

        setDecryptParams(decryptParamsResponse);
        let amount = decryptParamsResponse["pno.amount"];
        try {
          if (amount && amount.length < 1) {
            amount = "000";
          }
          if (amount && amount.length < 2) {
            amount = "00" + amount;
          }
          if (amount && amount.length === 2) {
            amount = "0" + amount;
          }
          if (amount && amount.length > 2) {
            amount = amount.substring(0, amount.length - 2) + "." + amount.substring(amount.length - 2);
          }
          setTransactionAmount(amount);
        } catch (Exception) {
          console.log(Exception);
        }
        parameter1Ref.current = decryptParamsResponse["app.parameter1"];
        parameter2Ref.current = decryptParamsResponse["app.parameter2"];
        parameter3Ref.current = decryptParamsResponse["app.parameter3"];
        partsOrderCureffRef.current = decryptParamsResponse["app.partsOrder.cureff"];
        partsOrderShipToIdRef.current = decryptParamsResponse["app.partsOrder.shipTo.id"];
        const poNumber = decryptParamsResponse["app.partsOrder.poNumber"];
        const avsName = decryptParamsResponse["pno.avsName"];
        const avsAddress1 = decryptParamsResponse["pno.avsAddress1"];
        const avsAddress2 = decryptParamsResponse["pno.avsAddress2"];
        const avsCity = decryptParamsResponse["pno.avsCity"];
        const avsState = decryptParamsResponse["pno.avsState"];
        const avsZip = decryptParamsResponse["pno.avsZip"];
        const tceppsCancelUrl = decryptParamsResponse["tcepps.cancelUrl"];
        orbitalConnectionUsernameRef.current = decryptParamsResponse["pno.orbitalConnectionUsername"];
        orbitalConnectionPasswordRef.current = decryptParamsResponse["pno.orbitalConnectionPassword"];
        switchSoloIssueNumRef.current = decryptParamsResponse["pno.switchSoloIssueNum"];
        switchSoloCardStartDateRef.current = decryptParamsResponse["pno.switchSoloCardStartDate"];
        authenticationECIIndRef.current = decryptParamsResponse["pno.authenticationECIInd"];
        if (!orbitalConnectionUsernameRef.current) {
          orbitalConnectionUsernameRef.current = decryptParamsResponse["orbitalConnectionUsername"];
        }
        if (!orbitalConnectionPasswordRef.current) {
          orbitalConnectionPasswordRef.current = decryptParamsResponse["orbitalConnectionPassword"];
        }
        if (!switchSoloIssueNumRef.current) {
          switchSoloIssueNumRef.current = decryptParamsResponse["switchSoloIssueNum"];
        }
        if (!switchSoloCardStartDateRef.current) {
          switchSoloCardStartDateRef.current = decryptParamsResponse["switchSoloCardStartDate"];
        }
        if (!authenticationECIIndRef.current) {
          authenticationECIIndRef.current = decryptParamsResponse["authenticationECIInd"];
        }
        setAvsName(avsName ? avsName.toUpperCase().trim() : "");
        setAddress1(avsAddress1 ? avsAddress1.toUpperCase().trim() : "");
        setAddress2(avsAddress2 ? avsAddress2.toUpperCase().trim() : "");
        setCity(avsCity ? avsCity.toUpperCase().trim() : "");
        setState(avsState ? avsState.toUpperCase().trim() : "");
        setZip(avsZip ? avsZip.toUpperCase().trim() : "");
        setPurchaseOrderNumber(poNumber ? poNumber.trim() : "");
        setTceppsCancelUrl(tceppsCancelUrl ? tceppsCancelUrl.trim() : "");

        // check if its called from testing app
        if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
          setExpirationMonth(TceppsTestData.ToBeAppTestData.CNA["to_be_app.expirationMonth"]);
          setExpirationYear(TceppsTestData.ToBeAppTestData.CNA["to_be_app.expirationYear"]);
        } else {
          setExpirationMonth("");
          setExpirationYear("");
        }

        logErrorRef.current = "CNA Order#" + decryptParamsResponse["pno.orderID"];

        return true;
      } else {
        setErrorMessages("Customer details not available. Please close and retry.");
        LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Customer details not available. Please close and retry.");
        return;
      }
    };

    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );

    if (dialogRef) dialogRef.current?.close();
    (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "none";
    (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid black";
    if (isFirefox) {
      (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "0px";
      (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #858585";
    }
  }, [appKey, dispatch]);

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    function selectGivenCardType(cardName: string, cardValid: boolean, cardEmpty: boolean) {
      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "visa";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "mastercard";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "amex";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            varCreditCardType = "discover";
            detectedCard = true;
            break;
        }
      }

      if (detectedCard) {
        setCreditCardType(varCreditCardType);
      } else {
        setCreditCardType("");
      }

      let errMessage = "Credit Card# needs to be entered.";
      if (!detectedCard) {
        errMessage = "Please enter a visa or a mastercard or amex or discover card#.";
      } else if (cardEmpty) {
        errMessage = "Credit Card# needs to be entered.";
      } else if (!cardValid) {
        errMessage = "Please enter a valid Credit card#.";
      } else if (cardValid) {
        errMessage = "";
      }
      setCreditcardValid(cardValid && detectedCard);
      if (cardValid && detectedCard) {
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.color = "#171719";
      } else if (!cardValid) {
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.color = "#a94442";
      }
      componentObjRef.current.cardMessage = errMessage;
    }

    const loadCybersourceJS = (captureContext: any, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = config.cybersource.microformJs;
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const initializeMicroform = async (captureContext: any) => {
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "" });
      await creditCardMicroNumber.load("#creditCardNumber");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          creditCardMicroNumber.focus();
          setSubmitButtonEnable(true);
          setSubmitVisible(true);
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "creditCardNumber";
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "none";
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid black";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
      }
    };

    // declare the data fetching function
    const getCaptureContext = async () => {
      await PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          const captureContext = resp.data.data;
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          // console.log(err);
          if (axios.isAxiosError(err)) {
            setErrorMessages(INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ". Please check console..");
          } else {
            setErrorMessages("Initialize microForm failed. " + err.message + ". Please check console..");
          }
          LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Initialize microForm failed. " + err.message);
          // isBackgroundRed = true;
        });
    };
    if (proxyUrl && proxyUrl.length > 0) {
      getCaptureContext();
    }
  }, [dispatch, proxyUrl]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonth":
        setExpirationMonth(event.target.value);
        break;
      case "expirationYear":
        setExpirationYear(event.target.value);
        break;
      case "avsName":
        setAvsName(event.target.value);
        break;
      case "address1":
        setAddress1(event.target.value);
        break;
      case "address2":
        setAddress2(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      case "state":
        setState(event.target.value);
        break;
      case "zip":
        setZip(event.target.value);
        break;
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonth":
        setExpirationMonth(event.target.value);
        break;
      case "expirationYear":
        setExpirationYear(event.target.value);
        break;
      case "avsName":
        setAvsName(event.target.value.toUpperCase());
        break;
      case "address1":
        setAddress1(event.target.value.toUpperCase());
        break;
      case "address2":
        setAddress2(event.target.value.toUpperCase());
        break;
      case "city":
        setCity(event.target.value.toUpperCase());
        break;
      case "state":
        setState(event.target.value.toUpperCase());
        break;
      case "zip":
        setZip(event.target.value.toUpperCase());
        break;
    }
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (event.key === "Tab") {
      if (identifier === "expirationMonth" && event.shiftKey) {
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
      } else if (identifier === "avsName" && !event.shiftKey) {
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
      } else if (identifier === "Submit" && !event.shiftKey) {
        address1Ref.current?.focus();
      }
    }
  };

  return (
    <>
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="lastFourCcAccountNum" name="lastFourCcAccountNum" type="hidden" value="" />
        <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
        <input id="lastFourDigits" name="lastFourDigits" type="hidden" value="" />
        <input id="successUrl" name="successUrl" type="hidden" value="" />
        <input id="cancelUrl" name="cancelUrl" type="hidden" value="" />
      </form>
      <form id="cs" name="cs">
        <input id="card.accountNumber" name="card.accountNumber" type="hidden" value="" />
        <input id="card.expirationMonth" name="card.expirationMonth" type="hidden" value="" />
        <input id="card.expirationYear" name="card.expirationYear" type="hidden" value="" />
        <input id="card.cardType" name="card.cardType" type="hidden" value="" />
        <input id="card.bin" name="card.bin" type="hidden" value="" />
      </form>
      <form id="ppa" name="ppa">
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
        <input id="ccExp" name="ccExp" type="hidden" value="" />
      </form>
      <form id="pno" name="pno">
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
        <input id="ccExp" name="ccExp" type="hidden" value="" />
        <input id="cardBrand" name="cardBrand" type="hidden" value="" />
        <input id="avsName" name="avsName" type="hidden" value="" />
        <input id="avsZip" name="avsZip" type="hidden" value="" />
        <input id="avsAddress1" name="avsAddress1" type="hidden" value="" />
        <input id="avsAddress2" name="avsAddress2" type="hidden" value="" />
        <input id="avsCity" name="avsCity" type="hidden" value="" />
        <input id="avsState" name="avsState" type="hidden" value="" />
        <input id="retryTrace" name="retryTrace" type="hidden" value="" />
        <input id="amount" name="amount" type="hidden" value="" />
        <input id="customerEmail" name="customerEmail" type="hidden" value="" />
        <input id="avsCountryCode" name="avsCountryCode" type="hidden" value="" />
        <input id="merchantID" name="merchantID" type="hidden" value="" />
        <input id="comments" name="comments" type="hidden" value="" />
        <input id="priorAuthCd" name="priorAuthCd" type="hidden" value="" />
        <input
          id="orbitalConnectionUsername"
          name="orbitalConnectionUsername"
          type="hidden"
          value={orbitalConnectionUsernameRef.current ? orbitalConnectionUsernameRef.current : ""}
        />
        <input
          id="orbitalConnectionPassword"
          name="orbitalConnectionPassword"
          type="hidden"
          value={orbitalConnectionPasswordRef.current ? orbitalConnectionPasswordRef.current : ""}
        />
        <input
          id="switchSoloIssueNum"
          name="switchSoloIssueNum"
          type="hidden"
          value={switchSoloIssueNumRef.current ? switchSoloIssueNumRef.current : ""}
        />
        <input
          id="switchSoloCardStartDate"
          name="switchSoloCardStartDate"
          type="hidden"
          value={switchSoloCardStartDateRef.current ? switchSoloCardStartDateRef.current : ""}
        />
        <input
          id="authenticationECIInd"
          name="authenticationECIInd"
          type="hidden"
          value={authenticationECIIndRef.current ? authenticationECIIndRef.current : ""}
        />
      </form>
      <form id="creditCard" name="creditCard" method="post" target="_self">
        <span id="durationMessage"></span>
        <input id="appTokenName" name="appTokenName" type="hidden" value="" />

        <input id="parameter1" name="parameter1" type="hidden" value={parameter1Ref.current} />
        <input id="parameter2" name="parameter2" type="hidden" value={parameter2Ref.current} />
        <input id="parameter3" name="parameter3" type="hidden" value={parameter3Ref.current} />
        <input id="partsOrder.cureff" name="partsOrder.cureff" type="hidden" value={partsOrderCureffRef.current} />
        <input id="partsOrder.poNumber" name="partsOrder.poNumber" type="hidden" value={purchaseOrderNumber} />
        <input id="partsOrder.shipTo.id" name="partsOrder.shipTo.id" type="hidden" value={partsOrderShipToIdRef.current} />

        <input id="tceppsEncryptedResponse" name="tceppsEncryptedResponse" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="tceppsEncryptedResponseKey" name="tceppsEncryptedResponseKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <div style={{ width: "100%" }} className={`${classesCna.cna} ${classesCna.BODY}`}>
          <div className={`${classesCna.cna} ${classesCna.BODY}`} style={tableMainStyle}>
            <textarea
              id="errorMessages"
              tabIndex={-1}
              className={`${classesCna.cna} ${classesCna.errmessage}`}
              style={{ color: "red", border: "0", width: "95%", resize: "none" }}
              value={errorMessages}
              ref={errorMessagesRef}
              rows={2}
              // cols={130}
              disabled={true}
              readOnly
            />
            <br />
          </div>
          <table style={tableMainStyle}>
            <tbody>
              <tr>
                <td className={`${classesCna.cna} ${classesCna.TD} ${classesCna.title}`} width="100%">
                  Payment Information
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table style={tableSecondStyle}>
            <tbody>
              <tr>
                <td>
                  <table style={tableThirdStyle}>
                    <colgroup>
                      <col style={{ width: "18%" }} />
                      <col style={{ width: "82%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td
                          style={{ whiteSpace: "nowrap", border: "1px solid rgb(0, 0, 0)" }}
                          className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}
                        >
                          Purchase Order Number:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          {purchaseOrderNumber}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: "nowrap" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          Billing Info.
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: "nowrap" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          *Street Address:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <input
                            id="address1"
                            name="address1"
                            type="text"
                            placeholder="Street Address *"
                            size={30}
                            maxLength={30}
                            tabIndex={1}
                            value={address1}
                            disabled={address1Disabled}
                            ref={address1Ref}
                            onChange={(event) => onChangeHandler("address1", event)}
                            onBlur={(event) => onBlurHandler("address1", event)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          Suite/apt#:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <input
                            id="address2"
                            name="address2"
                            type="text"
                            placeholder="Apt or Suite"
                            size={30}
                            maxLength={30}
                            tabIndex={2}
                            value={address2}
                            disabled={address2Disabled}
                            ref={address2Ref}
                            onChange={(event) => onChangeHandler("address2", event)}
                            onBlur={(event) => onBlurHandler("address2", event)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          *City/State:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <input
                            id="city"
                            name="city"
                            type="text"
                            placeholder="City *"
                            size={20}
                            maxLength={20}
                            tabIndex={3}
                            value={city}
                            disabled={cityDisabled}
                            ref={cityRef}
                            onChange={(event) => onChangeHandler("city", event)}
                            onBlur={(event) => onBlurHandler("city", event)}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          &nbsp;
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <select
                            id="state"
                            name="state"
                            tabIndex={4}
                            value={state}
                            disabled={stateDisabled}
                            ref={stateRef}
                            onChange={(event) => onChangeHandler("state", event)}
                            onBlur={(event) => onBlurHandler("state", event)}
                          ></select>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          *Zip code:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <input
                            id="zip"
                            name="zip"
                            type="text"
                            placeholder="Zip Code *"
                            size={10}
                            maxLength={10}
                            tabIndex={5}
                            value={zip}
                            disabled={zipDisabled}
                            ref={zipRef}
                            onChange={(event) => onChangeHandler("zip", event)}
                            onBlur={(event) => onBlurHandler("zip", event)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          Transaction Amount ($):
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <input
                            id="transactionAmount"
                            name="transactionAmount"
                            type="text"
                            placeholder="Transaction Amount *"
                            size={16}
                            maxLength={99}
                            tabIndex={-1}
                            readOnly
                            title="You can't change the transaction amount here."
                            value={transactionAmount}
                            disabled={transactionAmountDisabled}
                            ref={transactionAmountRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          Please enter your credit card data here:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          *Name as printed on card:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <input
                            id="avsName"
                            name="avsName"
                            type="text"
                            placeholder="First Name *"
                            size={30}
                            maxLength={30}
                            tabIndex={6}
                            value={avsName}
                            disabled={avsNameDisabled}
                            ref={avsNameRef}
                            onChange={(event) => onChangeHandler("avsName", event)}
                            onKeyDown={(event) => onKeyDownHandler("avsName", event)}
                            onBlur={(event) => onBlurHandler("avsName", event)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          *Please input your credit card # as a string of numbers. Do NOT include spaces or dashes:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <div
                            id="creditCardNumber"
                            style={creditCardStyle}
                            tabIndex={7}
                            autoFocus={true}
                            ref={creditCardNumberRef}
                            aria-disabled={creditCardNumberDisabled}
                          ></div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ wordWrap: "break-word" }} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerLabel}`}>
                          *Expiration Date:
                        </td>
                        <td style={tdInputStyle} className={`${classesCna.cna} ${classesCna.TD} ${classesCna.headerText}`}>
                          <select
                            id="expirationMonth"
                            name="expirationMonth"
                            tabIndex={8}
                            value={expirationMonth}
                            disabled={expirationMonthDisabled}
                            ref={expirationMonthRef}
                            onChange={(event) => onChangeHandler("expirationMonth", event)}
                            onKeyDown={(event) => onKeyDownHandler("expirationMonth", event)}
                            onBlur={(event) => onBlurHandler("expirationMonth", event)}
                            className={`${classesCna.cna} ${classesCna.Input__Input}`}
                          >
                            <option value="">MMM</option>
                            <option value="01">JAN</option>
                            <option value="02">FEB</option>
                            <option value="03">MAR</option>
                            <option value="04">APR</option>
                            <option value="05">MAY</option>
                            <option value="06">JUN</option>
                            <option value="07">JUL</option>
                            <option value="08">AUG</option>
                            <option value="09">SEP</option>
                            <option value="10">OCT</option>
                            <option value="11">NOV</option>
                            <option value="12">DEC</option>
                          </select>{" "}
                          <select
                            id="expirationYear"
                            name="expirationYear"
                            tabIndex={9}
                            value={expirationYear}
                            disabled={expirationYearDisabled}
                            ref={expirationYearRef}
                            onChange={(event) => onChangeHandler("expirationYear", event)}
                            onBlur={(event) => onBlurHandler("expirationYear", event)}
                            // className={`${classesCna.cna} ${classesCna.Input__Input}`}
                          ></select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table style={{ border: "0", width: "100%" }} align="center">
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>&nbsp;</td>
                        <td style={{ width: "25%" }}>&nbsp;</td>
                        <td style={{ width: "25%" }}>
                          <input
                            type="button"
                            value="Cancel"
                            tabIndex={10}
                            onClick={(event) => onClickHandler(event)}
                            disabled={!(!submitButtonEnable || !clickSubmit)}
                            hidden={!submitVisible}
                          />
                        </td>
                        <td style={{ width: "25%" }} align="center">
                          <input
                            type="button"
                            value="Submit"
                            tabIndex={11}
                            onClick={(event) => onClickHandler(event)}
                            onKeyDown={(event) => onKeyDownHandler("Submit", event)}
                            disabled={!(!submitButtonEnable || !clickSubmit)}
                            hidden={!submitVisible}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ border: "1px solid rgb(0, 0, 0)", width: "100%" }} align="right">
                    <tbody>
                      <tr>
                        <td style={{ width: "25%", borderColor: "white", border: "1px solid rgb(0, 0, 0)" }}>&nbsp;</td>
                        <td style={{ width: "25%", borderColor: "white", border: "1px solid rgb(0, 0, 0)" }}>&nbsp;</td>
                        <td style={{ width: "25%", borderColor: "white", border: "1px solid rgb(0, 0, 0)" }}>&nbsp;</td>
                        <td style={poStyleObj} align="right">
                          Click "Submit" to Complete Purchase Order
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <hr />

                  <p style={questionStyleObj}>
                    Any questions concerning Canon Parts, please contact
                    <a href="window.location"> canonparts@cusa.canon.com</a>.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  <div className={`${classesCna.cna} ${classesCna.copyWrite} ${classesCna.BODY}`}>
                    <div style={copyWriteStyleObj}>
                      <strong>
                        {copyWrite}.
                        <br />
                        Reproduction in whole or in part without permission is prohibited.
                      </strong>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <dialog id="transactionProcessingDialog" ref={dialogRef} style={{ verticalAlign: "middle", width: "350px", height: "22px" }}>
            <div id="transactionProcessing" style={{ verticalAlign: "middle", width: "350px", height: "22px" }}>
              <img style={{ verticalAlign: "middle" }} src={require("./img/transactionProcessing.gif")} alt="transactionProcessing" />
              Transaction in process. Please wait...
            </div>
          </dialog>
          <dialog id="confirmCancelDialog" style={{ width: "400", height: "52" }}>
            <div
              id="confirmCancel"
              style={{ verticalAlign: "middle", width: "400px", height: "52px", justifyContent: "center", alignItems: "center" }}
            >
              <p>Do you want to cancel your transaction? Please confirm.</p>
              <p style={{ textAlign: "center" }}>
                <input type="button" id="cancelOrder" value="Yes" tabIndex={1} onClick={(event) => onClickHandler(event)} />{" "}
                <input type="button" id="closeConfirmCancel" value="No" tabIndex={2} onClick={(event) => onClickHandler(event)} />
              </p>
            </div>
          </dialog>
        </div>
      </form>
    </>
  );
}
