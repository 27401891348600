// import {contentNav} from "./contentNav";
// import React from "react";
import {
  // BrowserRouter as Router,
  // Routes,
  // Route,
  // Link,
  Outlet,
} from "react-router-dom";

export default function ContentOutlet() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
