import { ApiConstants } from "../utils/ApiConstants";
import axios from "axios";



export default class CpgmuiService {
  public static submitServiceOperation(data: any) {
    return axios.post(ApiConstants.execServiceOperation, data);
  }
}
