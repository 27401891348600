export const componentVarObject: ComponentVarProps = {
  cardMessage: "",
  cvvMessage: "",
  glblCreditCardType: "",
  isBackgroundRed: false,
  microFromFocusField: "",
  otherErrMsg: "",
  processingForm: false,
  submitCount: 0,
  submitPage: false,
  tceppsStateChanged: false,
};

export interface ComponentVarProps {
  cardMessage?: string;
  cvvMessage?: string;
  glblCreditCardType?: string;
  isBackgroundRed: boolean;
  microFromFocusField?: string;
  otherErrMsg?: string;
  processingForm: boolean;
  submitCount: number;
  submitPage: boolean;
  tceppsStateChanged: boolean;
}
