import { FocusEvent, ChangeEvent, useCallback, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import PaymentService from "../../service/paymentService";
import { LogService, LOGINFOLEVEL, LOGERRORLEVEL } from "../../service/logService";
import { decryptParamType } from "../../utils/calendarUtil";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import { newOrder1 } from "../../resources/creditCardFormPage/js/tcepps";

import classesCpnetAch from "./CpnetAch.module.css";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

// let developmentEnv = false;
const dialogTimeoutInMins = 3; // 3 minutes
// Needed for older versions of IE (pre HTML 5) that do not support maxLength on text areas
const MAXCOMMENTLEN = 64; // max comments allowed

const copyWriteStyleObj = {
  fontSize: "x-small",
  fontFace: "Arial",
  color: "DarkGray",
  textAlign: "center",
  align: "center",
} as React.CSSProperties;
const tableMainStyle = {
  border: "0",
  borderCollapse: "collapse",
  borderSpacing: "0",
  padding: "0",
  cellspacing: "0",
  cellpadding: "0",
  width: "100%",
} as React.CSSProperties;
const tableSecondStyle = {
  border: "0",
  width: "100%",
} as React.CSSProperties;
const tableThirdStyle = { border: "0px", width: "100%" } as React.CSSProperties;
const tdInputStyle = { wordWrap: "break-word", width: "100%" } as React.CSSProperties;

const toDay = new Date();
const todayDate =
  toDay.toLocaleString("en-US", { month: "2-digit" }) +
  "/" +
  toDay.toLocaleString("en-US", { day: "2-digit" }) +
  "/" +
  toDay.toLocaleString("en-US", { year: "numeric" });
const currentYear = toDay.toLocaleString("en-US", { year: "numeric" });
const copyWrite = "Copyright&copy; 1999 - " + currentYear + " Canon U.S.A., Inc. All Rights Reserved.";
const appTokenName = "appToken";
const AGREEMENTTEXT1 =
  'By checking the box next to "I Agree" and then clicking "Submit", the party listed under "Customer Name" above ("Customer") hereby: (i) authorizes Canon U.S.A., Inc. ("Company") to initiate debit entries (withdrawals), in the Amount(s) listed above, by electronic funds transfer (EFT) through automated clearing house (ACH) processes, to Customer\'s account listed';
const AGREEMENTTEXT2 =
  " above at Customer's bank, for payment of goods and/or services; (ii) authorizes the Customer's bank to debit such Amount(s) to such account; (iii) agrees that the terms and conditions of Customer's separate agreement with Company concerning the method and timing of payment are amended to the extent necessary to permit the method and timing of payment provided above; (iv) agrees that Company is not responsible for any loss which may arise by reason of any error, mistake or fraud in the";
const AGREEMENTTEXT3 =
  ' information provided by Customer; and (v) consents to the use of the electronic signature process for the foregoing authorizations and agreements. If Customer does not agree to the terms set forth in (i) through (v) above, then Customer should not check the box next to "I Agree", and Customer will not be able to click "Submit".';
const POSTSUBMITTEXT =
  "After the click of Submit Button, please wait for Authorization Number confirmation. If you do not get the confirmation please do not process the same invoices again and notify your Canon Representative.";

const AMOUNTEMPTY = "Other Amount must be entered when the 'Other Amount' radio button is selected\n";
const AMOUNTLESSTHANZERO = "Other Amount entered must be greater than zero\n";
const AMOUNTNOTANUMBER = "You have entered an invalid Other Amount format. Please enter a numeric number\n";
const ECPCHECKRTEMPTY = "Your Bank Routing Number (ABA#) must be entered to continue\n";
const ECPCHECKRTLENGTH = "Your Bank Routing Number (ABA#) must contain exactly 9 numbers\n";
const ECPCHECKRTNOTANUMBER = "Your Bank Routing Number (ABA#) can only contain numbers\n";
const ECPCHECKDDAEMPTY = "Your Bank Account Number (Checking/DDA) must be entered to continue\n";
const ECPCHECKDDANOTANUMBER = "Your Bank Account Number (Checking/DDA) can only contain numbers\n";
const ECPCHECKDDACONFIRMEMPTY = "You must re-enter your Bank Account Number (Checking/DDA) to continue\n";
const ECPCHECKDDACONFIRMMISMATCH = "Bank Account Number's (Checking/DDA) do not match\n";
const COMMENTEMPTY = "Comments must be entered when paying an 'Other Amount'\n";
const REENTERBANKCODE = "Copy/Paste not allowed. Please Re-enter Bank Account Number\n";
const RESUBMITMESSAGE = "Please resubmit your payment again";
const EXCESSAMOUNTTEXT =
  "Total amount can not exceed $999,999.99. Please contact your Credit Manager if you need to make a payment larger than this amount.";

const INVALIDAXIOSMESSAGE = "AxiosError. ";
const thresholdAmount = 999999.99;

export function CpnetAchComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);

  const [errorMessages, setErrorMessages] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [userName, setUserName] = useState("");
  const [billToName, setBillToName] = useState("");
  const [billToCode, setBillToCode] = useState("");
  // const [transactionAmount, setTransactionAmount] = useState("");
  const [formatedAmount, setFormatedAmount] = useState("");
  const [payWhat, setPayWhat] = useState("payWhat");
  const [payWhatRadio, setPayWhatRadio] = useState("payWhat");
  const [otherRadio, setOtherRadio] = useState("other");
  const [tceppsSuccessUrl, setTceppsSuccessUrl] = useState("");
  const [tceppsCancelUrl, setTceppsCancelUrl] = useState("");
  const [tceppsSaveModifyUrl, setTceppsSaveModifyUrl] = useState("");
  const [tceppsCpnetHomeUrl, setTceppsCpnetHomeUrl] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [otherAmountInput, setOtherAmountInput] = useState("");
  const [ecpCheckRT, setEcpCheckRT] = useState("");
  const [ecpCheckDDA, setEcpCheckDDA] = useState("");
  const [ecpCheckDDAConfirm, setEcpCheckDDAConfirm] = useState("");
  const [comments, setComments] = useState("");
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [totalAmountMessage, setTotalAmountMessage] = useState("");
  const [otherAmountMessage, setOtherAmountMessage] = useState("");
  const [durationMessage, setDurationMessage] = useState("");
  const [confirmOtherAmountVerbiage, setConfirmOtherAmountVerbiage] = useState("");
  const [sessionTimeoutMins, setSessionTimeoutMins] = useState("0");

  const [otherAmountInputDisabled, setOtherAmountInputDisabled] = useState(true);
  const [ecpCheckRTDisabled, setEcpCheckRTDisabled] = useState(false);
  const [ecpCheckDDADisabled, setEcpCheckDDADisabled] = useState(false);
  const [ecpCheckDDAConfirmDisabled, setEcpCheckDDAConfirmDisabled] = useState(false);
  const [commentsDisabled, setCommentsDisabled] = useState(false);
  const [agreementCheckDisabled, setAgreementCheckDisabled] = useState(false);

  const errorMessagesRef = useRef<HTMLTextAreaElement>(null);
  const otherAmountInputRef = useRef<HTMLInputElement>(null);
  const ecpCheckRTRef = useRef<HTMLInputElement>(null);
  const ecpCheckDDARef = useRef<HTMLInputElement>(null);
  const ecpCheckDDAConfirmRef = useRef<HTMLInputElement>(null);
  const commentsRef = useRef<HTMLTextAreaElement>(null);
  const agreementCheckRef = useRef<HTMLInputElement>(null);

  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const countDownRef = useRef(-1);
  const durationMessageRef = useRef(-1);
  const sessionTimeoutRef = useRef(-1);

  const [processingForm, setProcessingForm] = useState(false);
  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);

  const transactionProcessingDialog = document.getElementById("transactionProcessingDialog") as HTMLDialogElement;
  const confirmCancelDialog = document.getElementById("confirmCancelDialog") as HTMLDialogElement;
  const confirmOtherAmountDialog = document.getElementById("confirmOtherAmountDialog") as HTMLDialogElement;
  const cpnetHomeDialog = document.getElementById("cpnetHomeDialog") as HTMLDialogElement;

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);

  const { appKey } = useParams();

  const logBatchIdRef = useRef<string>("CPNET ACH Not initialized");

  // Close the transaction processing dialog
  const openProcessingDialog = () => {
    if (transactionProcessingDialog) transactionProcessingDialog.showModal();
  };
  const openConfirmCancelDialog = () => {
    if (confirmCancelDialog) confirmCancelDialog.showModal();
  };
  const closeConfirmCancelDialog = () => {
    if (confirmCancelDialog) confirmCancelDialog.close();
  };
  const openConfirmOtherAmountDialog = () => {
    if (confirmOtherAmountDialog) confirmOtherAmountDialog.showModal();
  };
  const closeConfirmOtherAmountDialog = () => {
    if (confirmOtherAmountDialog) confirmOtherAmountDialog.close();
  };
  const openCpnetHomeDialog = () => {
    if (cpnetHomeDialog) cpnetHomeDialog.showModal();
  };
  const closeCpnetHomeDialog = () => {
    if (cpnetHomeDialog) cpnetHomeDialog.close();
  };

  const [unsavedChangesCallback] = useState(() => (e: any) => {
    LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "User exiting beforeunload");
    let message = "Please click Save/Modify or Cancel before leaving this page.";
    e.returnValue = message;
    return message;
  });

  useEffect(() => {
    window.addEventListener("beforeunload", unsavedChangesCallback);
    return () => {
      window.removeEventListener("beforeunload", unsavedChangesCallback);
      try {
        if (transactionProcessingDialog) {
          transactionProcessingDialog.close();
        }
      } catch (exception) {}
      try {
        if (countDownRef.current && countDownRef.current !== -1) clearInterval(countDownRef.current);
      } catch (exception) {}
      try {
        if (durationMessageRef.current && durationMessageRef.current !== -1) clearInterval(durationMessageRef.current);
      } catch (exception) {}
      try {
        if (sessionTimeoutRef.current && sessionTimeoutRef.current !== -1) clearInterval(sessionTimeoutRef.current);
      } catch (exception) {}
    };
  }, [unsavedChangesCallback, transactionProcessingDialog]);

  const disableOnBeforeUnload = useCallback(() => {
    window.removeEventListener("beforeunload", unsavedChangesCallback);
  }, [unsavedChangesCallback]);

  const closeDialog = useCallback(() => {
    if (transactionProcessingDialog) transactionProcessingDialog.close();

    // Always destroy the countdown timer object when the dialog is closed
    if (countDownRef.current && countDownRef.current !== -1) clearInterval(countDownRef.current);
    // $("#durationMessage").countdown("destroy");

    return false;
  }, [transactionProcessingDialog]);

  const expiredDuration = () => {
    closeDialog();
    // $("#errorMessages").text("Order was not processed. Please resubmit");
    setErrorMessages("Order was not processed. Please resubmit");
    setShowErrorMessage(true);
  };

  // Executed when the user clicks the Save/Modify button
  const saveModifyOrder = useCallback(
    (ach: any) => {
      // Never execute onbeforeunload when clicking Save/Modify
      disableOnBeforeUnload();

      // Post to the Save/Modify URL
      ach.action = tceppsSaveModifyUrl + "?achPayment.txBatchNo=" + batchNumber;
      ach.submit();
    },
    [batchNumber, tceppsSaveModifyUrl, disableOnBeforeUnload]
  );

  /*
   ** Countdown timer related code START
   */
  const getTimeRemaining = (e: Date) => {
    return e.getTime() - new Date().getTime();
  };

  const startTimer = (e: Date) => {
    let totalTimeLeft = getTimeRemaining(e);
    if (totalTimeLeft < 0) {
      // do action
      expiredDuration();
    }
  };

  const initiateTimerForClosingTransactionProcessingDialog = (e: Date) => {
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (countDownRef.current && countDownRef.current !== -1) clearInterval(countDownRef.current);
    const id = window.setInterval(() => {
      startTimer(e);
    }, 1000);
    countDownRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setMinutes(deadline.getMinutes() + dialogTimeoutInMins);
    return deadline;
  };

  /*
   ** DurationMessage Time work START
   */
  // Format duration with 2 digits and correct suffix
  const formatTwoDigits = (value: number) => {
    return (value < 10 ? "0" : "") + "" + value;
  };

  const formatDuration = useCallback((min: number, sec: number) => {
    // Always display 2 digits
    let minStr = formatTwoDigits(min);
    let secStr = formatTwoDigits(sec);

    // i.e. 01:45
    let duration = minStr + ":" + secStr;

    if (min > 0) {
      duration = duration + " minutes ";
    } else {
      duration = duration + " seconds ";
    }

    return duration;
  }, []);

  const getDurationMessageTimeRemaining = (e: Date) => {
    const totalTimeLeft = e.getTime() - new Date().getTime();
    const seconds = Math.floor((totalTimeLeft / 1000) % 60);
    const minutes = Math.floor((totalTimeLeft / 1000 / 60) % 60);
    // const hours = Math.floor((totalTimeLeft / 1000 / 60 / 60) % 24);
    return {
      totalTimeLeft,
      // hours,
      minutes,
      seconds,
    };
  };

  const pauseCountdown = () => {
    if (durationMessageRef.current && durationMessageRef.current !== -1) clearInterval(durationMessageRef.current);
  };

  const startDurationMessageTimer = useCallback(
    (e: Date) => {
      let { totalTimeLeft, minutes, seconds } = getDurationMessageTimeRemaining(e);
      if (totalTimeLeft < 0) {
        // do action
        enableOrDisableInput("submitTransaction", true);
        setDurationMessage("The duration process has expired, please click Save/Modify to save your work or Cancel to cancel. Thank you");
      } else {
        setDurationMessage("You have " + formatDuration(minutes, seconds) + "remaining to submit the payment");
      }
    },
    [formatDuration]
  );

  const initiateDurationMessageTimer = useCallback(() => {
    let deadline = new Date();
    // Countdown timer at 5 mins and session timeout. Minus 2 seconds to compensate for page load time
    const durationMessageInMins = 4; // 3 minutes
    const durationMessageInSecs = 58; // 58 sec minutes
    deadline.setMinutes(deadline.getMinutes() + durationMessageInMins);
    deadline.setSeconds(deadline.getSeconds() + durationMessageInSecs);

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (durationMessageRef.current && durationMessageRef.current !== -1) clearInterval(durationMessageRef.current);
    const id = window.setInterval(() => {
      startDurationMessageTimer(deadline);
    }, 1000);
    durationMessageRef.current = id;
  }, [startDurationMessageTimer]);

  /*
   ** DurationMessage Time work END
   */

  /*
   ** Session Timeout work START
   */
  // Format duration with 2 digits and correct suffix
  const getSessionTimeoutTimeRemaining = (e: Date) => {
    return e.getTime() - new Date().getTime();
  };

  const startSessionTimeoutTimer = useCallback(
    (e: Date) => {
      let totalTimeLeft = getSessionTimeoutTimeRemaining(e);
      if (totalTimeLeft < 0) {
        // do action
        // Automatically save the order if the session times out
        saveModifyOrder(document.getElementById("ach"));
      }
    },
    [saveModifyOrder]
  );

  const initiateSessionTimeoutTimer = useCallback(() => {
    let origDate = new Date();
    let deadline = new Date(origDate);
    deadline.setMinutes(origDate.getMinutes() + Number(sessionTimeoutMins));
    if (Number(sessionTimeoutMins) > 0) {
      if (sessionTimeoutRef.current && sessionTimeoutRef.current !== -1)
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        clearInterval(sessionTimeoutRef.current);
      const id = window.setInterval(() => {
        startSessionTimeoutTimer(deadline);
      }, 1000);
      sessionTimeoutRef.current = id;
    }
  }, [startSessionTimeoutTimer, sessionTimeoutMins]);

  /*
   ** Session Timeout work END
   */

  /*
   ** Countdown timer related code END
   */
  // Remove all characters ($,) except digits and decimal point

  const formatForCpnet = (value: string) => {
    if (value) {
      return value.replace(/[^0-9.]/g, "");
    } else {
      return 0.0;
    }
  };

  const enableOrDisableInput = (fieldName: string, disabledValue: boolean) => {
    switch (fieldName) {
      case "cancel":
      case "saveModify":
        setClickSubmit(!disabledValue);
        break;
      case "submitTransaction":
        setSubmitButtonEnable(!disabledValue);
        break;
      case "otherAmount":
        setOtherAmountInputDisabled(!disabledValue);
        break;
    }
  };

  const agreementClick = () => {
    if (totalAmountMessage === "" && otherAmountMessage === "") {
      if (agreementCheckRef.current?.checked) {
        if (durationMessage.indexOf("expired") <= 0) {
          enableOrDisableInput("submitTransaction", false);
        }
      } else {
        enableOrDisableInput("submitTransaction", true);
      }
    }
  };

  const resizeTextArea = () => {
    errorMessagesRef.current!.style.height = "auto";
    errorMessagesRef.current!.style.height = errorMessagesRef.current!.scrollHeight + "px";
  };

  useEffect(resizeTextArea, [errorMessages]);

  const checkAmount = () => {
    setTotalAmountMessage("");
    setOtherAmountMessage("");
    var amount: any = 0.0;
    var whichAmount = "total";
    if (payWhat !== "payWhat") {
      whichAmount = "other";
    }

    if (whichAmount === "total") {
      amount = formatForCpnet(formatedAmount);
    } else {
      amount = formatForCpnet(otherAmountInput);
    }

    if (parseFloat(amount) > thresholdAmount) {
      if (whichAmount === "total") {
        setTotalAmountMessage(EXCESSAMOUNTTEXT);
      } else {
        setOtherAmountMessage(EXCESSAMOUNTTEXT);
      }

      enableOrDisableInput("submitTransaction", true);
    }

    agreementClick();
  };

  const checkForm = useCallback(() => {
    let errorMessage = "";

    if (decryptParams && Object.keys(decryptParams).length > 0 && !processingForm) {
      let setFocusSet = false;

      if (payWhat !== "payWhat") {
        if (otherAmountInput === null || otherAmountInput.trim().length === 0) {
          errorMessage += AMOUNTEMPTY;
          if (!setFocusSet) otherAmountInputRef.current?.focus();
          setFocusSet = true;
        } else if (!otherAmountInput.match(/^\d{1,3}(?:(,?)\d{3}(?:\1\d{3})*)?(?:\.\d{2})?$/)) {
          errorMessage += AMOUNTNOTANUMBER;
          if (!setFocusSet) otherAmountInputRef.current?.focus();
          setFocusSet = true;
        } else if (Number(otherAmountInput) <= 0) {
          errorMessage += AMOUNTLESSTHANZERO;
          if (!setFocusSet) otherAmountInputRef.current?.focus();
          setFocusSet = true;
        }
      }

      if (ecpCheckRT.trim() === "") {
        errorMessage += ECPCHECKRTEMPTY;
        if (!setFocusSet) ecpCheckRTRef.current?.focus();
        setFocusSet = true;
      } else if (!ecpCheckRT.match(/^\d+$/)) {
        errorMessage += ECPCHECKRTNOTANUMBER;
        if (!setFocusSet) ecpCheckRTRef.current?.focus();
        setFocusSet = true;
      } else if (ecpCheckRT.trim().length !== 9) {
        errorMessage += ECPCHECKRTLENGTH;
        if (!setFocusSet) ecpCheckRTRef.current?.focus();
        setFocusSet = true;
      }

      if (ecpCheckDDA.trim() === "") {
        errorMessage += ECPCHECKDDAEMPTY;
        if (!setFocusSet) ecpCheckDDARef.current?.focus();
        setFocusSet = true;
      } else if (!ecpCheckDDA.match(/^\d+$/)) {
        errorMessage += ECPCHECKDDANOTANUMBER;
        if (!setFocusSet) ecpCheckDDARef.current?.focus();
        setFocusSet = true;
      } else {
        if (ecpCheckDDAConfirm.trim() === "") {
          errorMessage += ECPCHECKDDACONFIRMEMPTY;
          if (!setFocusSet) ecpCheckDDAConfirmRef.current?.focus();
          setFocusSet = true;
        } else if (ecpCheckDDAConfirm.trim() !== ecpCheckDDA.trim()) {
          errorMessage += ECPCHECKDDACONFIRMMISMATCH;
          if (!setFocusSet) ecpCheckDDAConfirmRef.current?.focus();
          setFocusSet = true;
        }
      }

      if (payWhat !== "payWhat" && comments.trim() === "") {
        errorMessage += COMMENTEMPTY;
        if (!setFocusSet) commentsRef.current?.focus();
        setFocusSet = true;
      }
    }

    if (errorMessage === "") {
      setErrorMessages("");
      setShowErrorMessage(false);
      return true;
    } else {
      setErrorMessages(errorMessage + RESUBMITMESSAGE);
      setShowErrorMessage(true);
      setProcessingForm(false);
      // Close the transaction processing dialog for all error conditions
      closeDialog();
      return false;
    }
  }, [decryptParams, processingForm, closeDialog, comments, ecpCheckDDA, ecpCheckDDAConfirm, ecpCheckRT, otherAmountInput, payWhat]);

  const clearMessages = () => {
    setShowErrorMessage(false);
    setErrorMessages("");
    setDurationMessage("");
  };

  const clearForm = (ach: any) => {
    ach.ecpCheckRT.value = "";
    ach.ecpCheckDDA.value = "";
    ach.comments.value = "";
    ach.otherAmount.value = "";
    pauseCountdown();
    clearMessages();
  };

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setOtherAmountInputDisabled(disabled);
    setEcpCheckRTDisabled(disabled);
    setEcpCheckDDADisabled(disabled);
    setEcpCheckDDAConfirmDisabled(disabled);
    setCommentsDisabled(disabled);
    setAgreementCheckDisabled(disabled);
    setClickSubmit(disabled);
  }, []);

  const decodeURIBill = (str: string) => {
    // This function decodes a URI by treating each escape sequence in the form %XX
    // as one UTF-8 code unit (one byte). In UTF-8, the number of leading 1 bits in the first byte,
    // which may be 0 (for 1-byte ASCII characters), 2, 3, or 4, indicates the number of bytes in the character.

    return decodeURIComponent(str.replace(/\+/g, " "));
  };

  const invokeNewOrder = useCallback(
    async (pno: any, tcepps: any, ach: any) => {
      // Remove all characters ($,.) except digits
      const formatForPaymentech = (value: any) => {
        // Amount must contain a decimal which is then stripped below, but the zeros are kept. If a decimal is found it means it has at least 2 digits after the decimal since that is part
        // of the regex form error check condition
        if (value.indexOf(".") <= 0) {
          value = value + ".00";
        }

        value = value.replace(/[^0-9]/g, "");

        return value;
      };

      if (!processingForm) {
        setProcessingForm(true);

        // Executing a newOrder disables all buttons because the control is handed back to ACH. In the event that the control cannot
        // be handed back to ACH (unknown exception or network error), the user is not allowed to click any buttons which will avoid
        // duplicate payment issues
        enableOrDisableInput("saveModify", true);
        enableOrDisableInput("submitTransaction", true);
        enableOrDisableInput("cancel", true);

        let commentsVar = comments;
        if (commentsVar !== null && commentsVar.trim().length > 0) {
          commentsVar = commentsVar.replace(/\r?\n|\r/g, " ");
        }

        let email = decryptParams["email"];
        let avsAddress1 = decryptParams["pno.avsAddress1"];
        let avsAddress2 = decryptParams["pno.avsAddress2"];
        let avsCity = decryptParams["pno.avsCity"];
        let avsState = decryptParams["pno.avsState"];
        let avsZip = decryptParams["pno.avsZip"];
        let avsCountryCode = decryptParams["pno.avsCountryCodeail"];
        let avsPhone = decryptParams["pno.avsPhone"];
        let email1 = decryptParams["pno.customerEmail"];
        let priorAuthCd = decryptParams["pno.priorAuthCd"];
        if (!(email && email.length > 0)) {
          email = email1;
        }
        if (!(email && email.length > 0)) {
          // email = DEFUSEREMAIL;
        }
        if (avsAddress1 && avsAddress1.length > 0) {
          avsAddress1 = decodeURIBill(avsAddress1);
        }
        if (avsAddress2 && avsAddress2.length > 0) {
          avsAddress2 = decodeURIBill(avsAddress2);
        }
        let orbitalConnectionUsername = decryptParams["pno.orbitalConnectionUsername"];
        let orbitalConnectionPassword = decryptParams["pno.orbitalConnectionPassword"];
        let switchSoloIssueNum = decryptParams["pno.switchSoloIssueNum"];
        let switchSoloCardStartDate = decryptParams["pno.switchSoloCardStartDate"];
        let authenticationECIInd = decryptParams["pno.authenticationECIInd"];
        if (!orbitalConnectionUsername) {
          orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
        }
        if (!orbitalConnectionPassword) {
          orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
        }
        if (!switchSoloIssueNum) {
          switchSoloIssueNum = decryptParams["switchSoloIssueNum"];
        }
        if (!switchSoloCardStartDate) {
          switchSoloCardStartDate = decryptParams["switchSoloCardStartDate"];
        }
        if (!authenticationECIInd) {
          authenticationECIInd = decryptParams["authenticationECIInd"];
        }
        pno.orbitalConnectionUsername.value = orbitalConnectionUsername ? orbitalConnectionUsername : "";
        pno.orbitalConnectionPassword.value = orbitalConnectionPassword ? orbitalConnectionPassword : "";
        pno.switchSoloIssueNum.value = switchSoloIssueNum ? switchSoloIssueNum : "";
        pno.switchSoloCardStartDate.value = switchSoloCardStartDate ? switchSoloCardStartDate : "";
        pno.authenticationECIInd.value = authenticationECIInd ? authenticationECIInd : "";

        pno.avsName.value = billToName != null && billToName.trim() != null ? billToName.trim().toUpperCase() : "";
        pno.avsAddress1.value = avsAddress1 != null && avsAddress1.trim() != null ? avsAddress1.trim().toUpperCase() : "";
        pno.avsAddress2.value = avsAddress2 != null && avsAddress2.trim() != null ? avsAddress2.trim().toUpperCase() : "";
        pno.avsCity.value = avsCity != null && avsCity.trim() != null ? avsCity.trim().toUpperCase() : "";
        pno.avsState.value = avsState != null && avsState.trim() != null ? avsState.trim().toUpperCase() : "";
        pno.avsZip.value = avsZip != null && avsZip.trim() != null ? avsZip.trim().toUpperCase() : "";
        pno.avsCountryCode.value = avsCountryCode != null && avsCountryCode.trim() != null ? avsCountryCode.trim().toUpperCase() : "";
        pno.avsPhone.value = avsPhone != null && avsPhone.trim() != null ? avsPhone.trim().toUpperCase() : "";
        pno.ecpCheckRT.value = ecpCheckRT != null && ecpCheckRT.trim() != null ? ecpCheckRT.trim() : "";
        pno.ecpCheckDDA.value = ecpCheckDDA != null && ecpCheckDDA.trim() != null ? ecpCheckDDA.trim() : "";
        pno.ecpBankAcctType.value = "X";
        pno.comments.value = commentsVar != null && comments.trim() != null ? commentsVar : "";
        pno.orderID.value = batchNumber != null && batchNumber != null ? batchNumber.trim() : "";
        pno.retryTrace.value = batchNumber != null && batchNumber != null ? batchNumber : "";
        pno.priorAuthCd.value = priorAuthCd != null && priorAuthCd != null ? priorAuthCd : "";
        pno.merchantID.value = decryptParams["pno.merchantID"];
        if (payWhat === "payWhat") {
          // Paying total amount due
          pno.amount.value = formatedAmount ? formatedAmount.replace("$", "") : "0.00";
        } else {
          // Paying other amount
          let formattedAmount = otherAmountInput;
          if (otherAmountInput) {
            formattedAmount = formatForPaymentech(otherAmountInput);
          } else {
            formattedAmount = "0.00";
          }
          if (formattedAmount && formattedAmount.length === 0) {
            formattedAmount = "0.00";
          }
          pno.amount.value = formattedAmount;
          ach.otherAmount.value = formattedAmount; // Formatted since the value is passed back to the success url
        }
        pno.customerEmail.value = email ? email.trim() : "";
        tcepps.serviceOperation.value = "newOrder";
        tcepps.successUrl.value = tceppsSuccessUrl !== null ? tceppsSuccessUrl : "";
        tcepps.cancelUrl.value = tceppsCancelUrl !== null ? tceppsCancelUrl : "";
        tcepps.app.value = tceppsState.app != null && tceppsState.app.toUpperCase().trim() ? tceppsState.app.toUpperCase().trim() : "";
        tcepps.token.value = tceppsState.token != null ? tceppsState.token : "";
        tcepps.sign.value = tceppsState.sign != null ? tceppsState.sign : "";

        toggleFields("false");
        // Cannot clear the form because the values are needed for sending back
        //clearForm();
        pauseCountdown();
        clearMessages();
        LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "calling newOrder1");

        try {
          newOrder1(tcepps, pno);
        } catch (err: any) {
          LogService.logMessage(
            logBatchIdRef.current,
            LOGINFOLEVEL,
            "Call to submit form NewOrder failed:" + (err ? JSON.stringify(err.message) : "null")
          );
          closeDialog();
          setErrorMessages("Call to submit form NewOrder failed. " + err.message);
          setShowErrorMessage(true);
          setProcessingForm(false);
        }
      }
    },
    [
      toggleFields,
      tceppsState.app,
      tceppsState.token,
      tceppsState.sign,
      decryptParams,
      closeDialog,
      batchNumber,
      billToName,
      comments,
      ecpCheckDDA,
      ecpCheckRT,
      otherAmountInput,
      tceppsCancelUrl,
      tceppsSuccessUrl,
      processingForm,
      payWhat,
      formatedAmount,
    ]
  );

  // Open the transaction processing dialog
  const openDialog = () => {
    openProcessingDialog();
    // If the dialog stays open for a certain amount of time, close it because it means an exception happened and user will then
    // be able to re-submit if it's within the token expiration timeframe
    initiateTimerForClosingTransactionProcessingDialog(getDeadTime());

    setErrorMessages("");
    setShowErrorMessage(false);
    return false;
  };

  const processTransaction = () => {
    disableOnBeforeUnload();
    openDialog();
    invokeNewOrder(document.getElementById("pno"), document.getElementById("tcepps"), document.getElementById("ach"));
    // Cannot clear the form because the values are needed for sending back
    pauseCountdown();
    clearMessages();
  };

  const invokeCancel = (ach: any) => {
    ach.action = tceppsCancelUrl + "?achPayment.txBatchNo=" + batchNumber;
    ach.submit();
  };

  const cancelOrder = () => {
    closeConfirmCancelDialog();
    clearForm(document.getElementById("ach"));
    invokeCancel(document.getElementById("ach"));
  };

  const tceppsResponseErrorUiCopyPaste = (event: any) => {
    setErrorMessages(REENTERBANKCODE);
    setShowErrorMessage(true);
    // return false;
    event.preventDefault();
    event.stopPropagation();
  };

  const onKeyDownHandler = () => {
    if (errorMessages === REENTERBANKCODE) {
      setErrorMessages("");
      setShowErrorMessage(false);
    }
  };

  // Executed when the user clicks the Canon icon
  const onImageClickHandler = () => {
    // if (window.confirm("Leave site?\n\nChanges you made may not be saved, Your transaction will be released in 15 minutes.")) {
    // Never execute onbeforeunload when clicking Save/Modify
    disableOnBeforeUnload();

    let ach: any = document.getElementById("ach");
    clearForm(ach);

    ach.action = tceppsCpnetHomeUrl + "?achPayment.txBatchNo=" + batchNumber;
    ach.submit();
    // }
  };

  const placeOrder = () => {
    LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "placeOrder");
    if (checkForm()) {
      if (payWhat !== "payWhat") {
        setConfirmOtherAmountVerbiage(
          "Are you sure you want to pay $" +
            parseFloat(otherAmountInput)
              .toFixed(2)
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") +
            "? Please confirm."
        );
        openConfirmOtherAmountDialog();
      } else {
        LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "processTransaction..1");
        processTransaction();
      }
    } else {
      LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "placeOrder..validation error");
    }
  };

  // When Submit button is clicked
  const onClickHandler = (event: any) => {
    LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "User Clicked id:" + event.target.id + " value:" + event.target.value);
    switch (event.target.value) {
      case "Save/Modify":
        event.preventDefault();
        LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "User Clicked return back.");
        saveModifyOrder(document.getElementById("ach"));
        break;
      case "Cancel":
        // event.preventDefault();
        disableOnBeforeUnload();
        openConfirmCancelDialog();
        break;
      case "Submit":
        event.preventDefault();
        if (!processingForm) {
          // Open the transaction processing dialog
          placeOrder();
        }
        break;
      case "total":
        enableOrDisableInput("otherAmount", true);
        checkAmount();
        break;
      case "Yes":
        if (event.target.id === "cancelOrder") {
          LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "User Clicked cancelOrder.");
          cancelOrder();
        } else if (event.target.id === "otherAmountConfirmed") {
          closeConfirmOtherAmountDialog();
          LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "processTransaction..2");
          processTransaction();
        } else if (event.target.id === "cpnetHomeConfirmed") {
          LogService.logMessage(logBatchIdRef.current, LOGINFOLEVEL, "clicked onImageClickHandler");
          onImageClickHandler();
        }
        break;
      case "No":
        if (event.target.id === "closeConfirmCancel") {
          closeConfirmCancelDialog();
        } else if (event.target.id === "otherAmountCancel") {
          closeConfirmOtherAmountDialog();
        } else if (event.target.id === "cpnetHomeCancel") {
          closeCpnetHomeDialog();
        }
        break;
    }
  };

  useEffect(() => {
    // (document.querySelector("#root") as HTMLDivElement).style.width = "100%";
    try {
      ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLBodyElement).style.margin = "0";
    } catch (exception) {}

    // start displaying durationMessage countdown
    initiateDurationMessageTimer();
    initiateSessionTimeoutTimer();
  }, [initiateDurationMessageTimer, initiateSessionTimeoutTimer]);

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    // console.log("useEffect initialload...4");

    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
      session_timeoutinmins: "",
    };

    const getPortNumber = (inputUrl: string) => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };

    //find port if it exits
    const urlPortNumber = getPortNumber(window.location.href);

    Object.keys(mydata).forEach((key) => {
      switch (key) {
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber(requestParameter.cpgmuiApiUrl);
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          // if (requestParameter.cpgmuiEnv.toLowerCase() === "development") {
          //   developmentEnv = true;
          // }
          break;
        case "ACH_SESSION_TIMEOUTINMINS":
          if (mydata[key] && mydata[key].trim().length > 0) setSessionTimeoutMins(mydata[key].trim());
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    dispatch(
      tceppsActions.initializeTceppsAction({
        app: requestParameter.app,
        token: requestParameter.token,
        sign: requestParameter.sign,
        serviceOperation: requestParameter.serviceOperation,
        encryptedRequest: requestParameter.encryptedRequest,
        encryptedRequestKey: requestParameter.encryptedRequestKey,
        enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
        cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
        cpgmuiEnv: requestParameter.cpgmuiEnv,
      })
    );

    // if (developmentEnv && isDevUrl(window.location.href)) {
    //   setEcpCheckRT(TceppsTestData.ToBeAppTestData.CPNET_ACH["to_be_app.routingNumber"]);
    //   setEcpCheckDDA(TceppsTestData.ToBeAppTestData.CPNET_ACH["to_be_app.bankAccountNumber"]);
    //   setEcpCheckDDAConfirm(TceppsTestData.ToBeAppTestData.CPNET_ACH["to_be_app.bankAccountNumber"]);
    // } else {
    //   setEcpCheckRT("");
    //   setEcpCheckDDA("");
    //   setEcpCheckDDAConfirm("");
    // }

    // decrypt the input parameters
    const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
      let decryptParamsResponse: any;

      try {
        const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
        decryptParamsResponse = response.data;
      } catch (err: any) {
        // console.log(err);
        if (axios.isAxiosError(err)) {
          // Access to config, request, and response
          setErrorMessages(INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ". Please check console..");
          setShowErrorMessage(true);
        } else {
          // Just a stock error
          setErrorMessages("Error in getDecryptRequest. " + err.message + ". Please check console..");
          setShowErrorMessage(true);
        }
        // isBackgroundRed = true;
        LogService.logMessage(logBatchIdRef.current, LOGERRORLEVEL, "Call to getDecryptRequest failed.");
      }

      if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
        setDecryptParams(decryptParamsResponse);
        // console.log(Object.keys(decryptParamsResponse));
        let amount = decryptParamsResponse["amount"];
        try {
          if (amount && amount.length < 1) {
            amount = "000";
          }
          if (amount && amount.length < 2) {
            amount = "00" + amount;
          }
          if (amount && amount.length === 2) {
            amount = "0" + amount;
          }
          amount = amount.substring(0, amount.length - 2) + "." + amount.substring(amount.length - 2);
        } catch (Exception) {
          console.log(Exception);
        }
        // setTransactionAmount(amount);
        let formatedAmount = "$" + amount; //formatAsCurrency(amount);
        setFormatedAmount(formatedAmount);
        const tceppsSuccessUrl = decryptParamsResponse["tcepps.successUrl"];
        const tceppsCancelUrl = decryptParamsResponse["tcepps.cancelUrl"];
        const tceppsSaveModifyUrl = decryptParamsResponse["tcepps.saveModifyUrl"];
        // const tceppsUpdateSubmitUrl = decryptParamsResponse["tcepps.updateSubmitUrl"];
        const tceppsCpnetHomeUrl = decryptParamsResponse["tcepps.cpnetHomeUrl"];
        const userName = decryptParamsResponse["app.userName"];
        let billToName = decryptParamsResponse["app.billToName"];
        const billToCode = decryptParamsResponse["app.billToCode"];
        const batchNumber = decryptParamsResponse["app.batchNo"];
        logBatchIdRef.current = "CPNET_ACH " + batchNumber;
        if (billToName && billToName.length > 0) {
          billToName = decodeURIBill(billToName);
        }
        setUserName(userName ? userName.toUpperCase().trim() : "");
        setBillToName(billToName ? billToName.toUpperCase().trim() : "");
        setBillToCode(billToCode ? billToCode.toUpperCase().trim() : "");
        setTceppsSuccessUrl(tceppsSuccessUrl ? tceppsSuccessUrl.trim() : "");
        setTceppsCancelUrl(tceppsCancelUrl ? tceppsCancelUrl.trim() : "");
        setTceppsSaveModifyUrl(tceppsSaveModifyUrl ? tceppsSaveModifyUrl.trim() : "");
        // setTceppsUpdateSubmitUrl(tceppsUpdateSubmitUrl ? tceppsUpdateSubmitUrl.trim() : "");
        setTceppsCpnetHomeUrl(tceppsCpnetHomeUrl ? tceppsCpnetHomeUrl.trim() : "");
        setBatchNumber(batchNumber ? batchNumber.trim() : "");

        // check if its called from testing app
        if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
          // developmentEnv = true;
          setEcpCheckRT(TceppsTestData.ToBeAppTestData.CPNET_ACH["to_be_app.routingNumber"]);
          setEcpCheckDDA(TceppsTestData.ToBeAppTestData.CPNET_ACH["to_be_app.bankAccountNumber"]);
          // setEcpCheckDDAConfirm(TceppsTestData.ToBeAppTestData.CPNET_ACH["to_be_app.bankAccountNumber"]);
        } else {
          setEcpCheckRT("");
          setEcpCheckDDA("");
          setEcpCheckDDAConfirm("");
        }

        return true;
      } else {
        setErrorMessages("Customer details not available. Please close and retry.");
        setShowErrorMessage(true);
        LogService.logMessage(logBatchIdRef.current, LOGERRORLEVEL, "Customer details not available. Please close and retry.");
        return;
      }
    };

    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );

    let transactionProcessingDialog = document.getElementById("transactionProcessingDialog") as HTMLDialogElement;
    let confirmCancelDialog = document.getElementById("confirmCancelDialog") as HTMLDialogElement;
    let confirmOtherAmountDialog = document.getElementById("confirmOtherAmountDialog") as HTMLDialogElement;
    let cpnetHomeDialogDialog = document.getElementById("cpnetHomeDialog") as HTMLDialogElement;

    if (transactionProcessingDialog) transactionProcessingDialog.close();
    if (confirmCancelDialog) confirmCancelDialog.close();
    if (confirmOtherAmountDialog) confirmOtherAmountDialog.close();
    if (cpnetHomeDialogDialog) cpnetHomeDialogDialog.close();
  }, [appKey, dispatch]);

  const checkCommentsLength = (commentsVal: string) => {
    // Needed for older versions of IE (pre HTML 5) that do not support maxLength on text areas
    if (commentsVal && commentsVal.length > MAXCOMMENTLEN) {
      commentsVal = commentsVal.substring(0, MAXCOMMENTLEN);
    }
    return commentsVal;
  };

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLTextAreaElement }) => {
    switch (identifier) {
      case "ecpCheckRT":
        setEcpCheckRT(event.target.value);
        break;
      case "ecpCheckDDA":
        setEcpCheckDDA(event.target.value);
        break;
      case "ecpCheckDDAConfirm":
        setEcpCheckDDAConfirm(event.target.value);
        break;
      case "comments":
        setComments(checkCommentsLength(event.target.value));
        break;
      case "otherAmount":
        setOtherAmountInput(event.target.value);
        break;
      case "agreement":
        setAgreementCheck(agreementCheckRef.current?.checked as any);
        agreementClick();
        break;
      case "payWhat":
      case "other":
        // setPayWhat(payWhatRef.current?.checked as any);
        // setOther(!payWhatRef.current?.checked as any);
        setPayWhat(identifier);
        if (identifier === "other") {
          setOtherAmountInputDisabled(false);
        } else {
          setOtherAmountInputDisabled(true);
        }
        break;
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLInputElement | HTMLTextAreaElement }) => {
    switch (identifier) {
      case "ecpCheckRT":
        setEcpCheckRT(event.target.value);
        break;
      case "ecpCheckDDA":
        setEcpCheckDDA(event.target.value);
        break;
      case "ecpCheckDDAConfirm":
        setEcpCheckDDAConfirm(event.target.value.toUpperCase());
        break;
      case "comments":
        setComments(checkCommentsLength(event.target.value));
        break;
      case "otherAmount":
        setOtherAmountInput(event.target.value);
        checkAmount();
        break;
      case "other":
        setOtherRadio(event.target.value);
        break;
      case "payWhat":
        setPayWhatRadio(event.target.value);
        break;
    }
  };

  return (
    <>
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
        <input id="successUrl" name="successUrl" type="hidden" value="" />
        <input id="cancelUrl" name="cancelUrl" type="hidden" value="" />
      </form>
      <form id="pno" name="pno">
        <input id="avsName" name="avsName" type="hidden" value="" />
        <input id="avsAddress1" name="avsAddress1" type="hidden" value="" />
        <input id="avsAddress2" name="avsAddress2" type="hidden" value="" />
        <input id="avsCity" name="avsCity" type="hidden" value="" />
        <input id="avsState" name="avsState" type="hidden" value="" />
        <input id="avsZip" name="avsZip" type="hidden" value="" />
        <input id="avsCountryCode" name="avsCountryCode" type="hidden" value="" />
        <input id="avsPhone" name="avsPhone" type="hidden" value="" />
        <input id="ecpCheckRT" name="ecpCheckRT" type="hidden" value="" />
        <input id="ecpBankAcctType" name="ecpBankAcctType" type="hidden" value="" />
        <input id="ecpCheckDDA" name="ecpCheckDDA" type="hidden" value="" />
        <input id="comments" name="comments" type="hidden" value="" />
        <input id="amount" name="amount" type="hidden" value="" />
        <input id="orderID" name="orderID" type="hidden" value="" />
        <input id="retryTrace" name="retryTrace" type="hidden" value="" />
        <input id="customerEmail" name="customerEmail" type="hidden" value="" />
        <input id="priorAuthCd" name="priorAuthCd" type="hidden" value="" />
        <input id="merchantID" name="merchantID" type="hidden" value="" />
        <input id="orbitalConnectionUsername" name="orbitalConnectionUsername" type="hidden" value="" />
        <input id="orbitalConnectionPassword" name="orbitalConnectionPassword" type="hidden" value="" />
        <input id="switchSoloIssueNum" name="switchSoloIssueNum" type="hidden" value="" />
        <input id="switchSoloCardStartDate" name="switchSoloCardStartDate" type="hidden" value="" />
        <input id="authenticationECIInd" name="authenticationECIInd" type="hidden" value="" />
      </form>
      <form id="ach" name="ach" method="post" target="_self">
        <input id="appToken" name="appToken" type="hidden" value={appTokenName} />
        <input id="userName" name="userName" type="hidden" value={userName} />
        <input id="otherAmount" name="userNotherAmountame" type="hidden" value="" />
        <input id="tceppsEncryptedResponse" name="tceppsEncryptedResponse" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="tceppsEncryptedResponseKey" name="tceppsEncryptedResponseKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="tceppsEncryptedRequest" name="tceppsEncryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <div style={{ width: "100%" }} className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.BODY}`}>
          <div>
            <img
              title="CPNET HOME"
              src={require("./img/Cpnet-img-logo.png")}
              alt="CPNET HOME"
              style={{ display: "block", margin: "10 auto", verticalAlign: "middle", cursor: "pointer" }}
              onClick={(event) => openCpnetHomeDialog()}
            />
          </div>
          <div className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.BODY}`} style={tableMainStyle}>
            {showErrorMessage}
            <textarea
              id="errorMessages"
              tabIndex={-1}
              // className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.errmessage}`}
              style={{ color: "red", border: "0", width: "95%", resize: "none", overflowY: "hidden", background: "white" }}
              value={errorMessages}
              ref={errorMessagesRef}
              rows={1}
              cols={180}
              disabled={true}
              readOnly
            />
            <br />
          </div>
          <table style={tableMainStyle}>
            <tbody>
              <tr>
                <td>
                  <div>
                    <span id="durationMessage">{durationMessage}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.title}`} width="100%">
                  ACH Payments
                </td>
              </tr>
            </tbody>
          </table>
          <table style={tableSecondStyle}>
            <tbody>
              <tr style={{ backgroundColor: "grey" }}>
                <td>
                  <table style={tableThirdStyle}>
                    <colgroup>
                      <col style={{ width: "23%" }} />
                      <col style={{ width: "77%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td
                          style={{ whiteSpace: "nowrap", border: "1px solid rgb(0, 0, 0)" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Date:
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          {todayDate}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ whiteSpace: "nowrap" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Customer Code:
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          {billToCode}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ whiteSpace: "nowrap" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Customer Name:
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          {billToName}, {userName}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ whiteSpace: "nowrap" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Total Amount:
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <input
                            name="payWhat"
                            type="radio"
                            checked={payWhat === "payWhat"}
                            tabIndex={1}
                            value={payWhatRadio}
                            onChange={(event) => onChangeHandler("payWhat", event)}
                            onBlur={(event) => onBlurHandler("payWhat", event)}
                          />
                          &nbsp;
                          {formatedAmount}
                          <div style={{ color: "red" }} id="totalAmountMessage">
                            {totalAmountMessage}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Other Amount:
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <input
                            name="payWhat"
                            type="radio"
                            checked={payWhat === "other"}
                            tabIndex={1}
                            value={otherRadio}
                            onChange={(event) => onChangeHandler("other", event)}
                            onBlur={(event) => onBlurHandler("other", event)}
                          />
                          &nbsp; $&nbsp;
                          <input
                            id="otherAmount"
                            name="otherAmount"
                            type="text"
                            size={16}
                            maxLength={16}
                            tabIndex={2}
                            value={otherAmountInput}
                            aria-disabled={otherAmountInputDisabled}
                            disabled={otherAmountInputDisabled}
                            ref={otherAmountInputRef}
                            onChange={(event) => onChangeHandler("otherAmount", event)}
                            onBlur={(event) => onBlurHandler("otherAmount", event)}
                          />
                          <div style={{ color: "red" }} id="otherAmountMessage">
                            {otherAmountMessage}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Bank Routing Number (ABA#):
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <input
                            id="ecpCheckRT"
                            name="ecpCheckRT"
                            type="text"
                            size={14}
                            maxLength={9}
                            tabIndex={3}
                            value={ecpCheckRT}
                            disabled={ecpCheckRTDisabled}
                            ref={ecpCheckRTRef}
                            onChange={(event) => onChangeHandler("ecpCheckRT", event)}
                            onBlur={(event) => onBlurHandler("ecpCheckRT", event)}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Bank Account Number (Checking/DDA):
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <input
                            id="ecpCheckDDA"
                            name="ecpCheckDDA"
                            type="text"
                            size={20}
                            maxLength={17}
                            tabIndex={4}
                            value={ecpCheckDDA}
                            disabled={ecpCheckDDADisabled}
                            ref={ecpCheckDDARef}
                            onChange={(event) => onChangeHandler("ecpCheckDDA", event)}
                            onBlur={(event) => onBlurHandler("ecpCheckDDA", event)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Re-enter Bank Account Number (Checking/DDA):
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <input
                            id="ecpCheckDDAConfirm"
                            name="ecpCheckDDAConfirm"
                            type="text"
                            size={20}
                            maxLength={17}
                            tabIndex={5}
                            value={ecpCheckDDAConfirm}
                            disabled={ecpCheckDDAConfirmDisabled}
                            ref={ecpCheckDDAConfirmRef}
                            onChange={(event) => onChangeHandler("ecpCheckDDAConfirm", event)}
                            onBlur={(event) => onBlurHandler("ecpCheckDDAConfirm", event)}
                            title="right click/paste is disabled"
                            onContextMenuCapture={(event) => tceppsResponseErrorUiCopyPaste(event)}
                            onPasteCapture={(event) => tceppsResponseErrorUiCopyPaste(event)}
                            onDropCapture={(event) => tceppsResponseErrorUiCopyPaste(event)}
                            autoComplete="off"
                            onCopyCapture={(event) => tceppsResponseErrorUiCopyPaste(event)}
                            onCutCapture={(event) => tceppsResponseErrorUiCopyPaste(event)}
                            onDragCapture={(event) => tceppsResponseErrorUiCopyPaste(event)}
                            onKeyDown={onKeyDownHandler}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          Comments:
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <textarea
                            id="comments"
                            name="comments"
                            cols={40}
                            rows={2}
                            maxLength={64}
                            style={{ border: "1" }}
                            tabIndex={6}
                            value={comments}
                            disabled={commentsDisabled}
                            ref={commentsRef}
                            onChange={(event) => onChangeHandler("comments", event)}
                            onBlur={(event) => onBlurHandler("comments", event)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerLabel}`}
                        >
                          &nbsp;
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <input
                            id="agreement"
                            name="agreement"
                            type="checkbox"
                            tabIndex={7}
                            checked={agreementCheck}
                            disabled={agreementCheckDisabled}
                            ref={agreementCheckRef}
                            className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.disclaimerCheck} ${classesCpnetAch.body}`}
                            onChange={(event) => onChangeHandler("agreement", event)}
                          />
                          <label htmlFor="agreement">I Agree</label>
                          <br />
                          <p
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              padding: "0",
                              marginTop: "0",
                              marginBottom: "0",
                              textAlign: "justify",
                            }}
                          >
                            {AGREEMENTTEXT1}
                            {AGREEMENTTEXT2}
                            {AGREEMENTTEXT3}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          &nbsp;
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        ></td>
                      </tr>

                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          &nbsp;
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        ></td>
                      </tr>

                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          &nbsp;
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <input
                            type="button"
                            id="saveModify"
                            value="Save/Modify"
                            tabIndex={8}
                            onClick={(event) => onClickHandler(event)}
                            disabled={clickSubmit}
                          />
                          <input
                            type="button"
                            id="submitTransaction"
                            value="Submit"
                            tabIndex={9}
                            onClick={(event) => onClickHandler(event)}
                            disabled={!submitButtonEnable}
                          />
                          <input
                            type="button"
                            id="cancel"
                            value="Cancel"
                            tabIndex={10}
                            onClick={(event) => onClickHandler(event)}
                            disabled={clickSubmit}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          &nbsp;
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        ></td>
                      </tr>

                      <tr>
                        <td
                          style={{ wordWrap: "break-word" }}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          &nbsp;
                        </td>
                        <td
                          style={tdInputStyle}
                          className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.TD} ${classesCpnetAch.headerText}`}
                        >
                          <b>{POSTSUBMITTEXT}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  <div className={`${classesCpnetAch.cpnetach} ${classesCpnetAch.copyWrite} ${classesCpnetAch.BODY}`}>
                    <div style={copyWriteStyleObj}>
                      <strong>
                        {copyWrite}.
                        <br />
                        Reproduction in whole or in part without permission is prohibited.
                      </strong>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <dialog id="transactionProcessingDialog" style={{ width: "350px", height: "22px" }}>
            <div id="transactionProcessing" style={{ verticalAlign: "middle", width: "350px", height: "22px" }}>
              <img
                style={{ verticalAlign: "middle" }}
                src={require("./img/cpnetach_transactionProcessing.gif")}
                alt="transactionProcessing"
              />
              Transaction in process. Please wait...
            </div>
          </dialog>
          <dialog id="confirmCancelDialog" style={{ width: "400", height: "52" }}>
            <div
              id="confirmCancel"
              style={{ verticalAlign: "middle", width: "400px", height: "52px", justifyContent: "center", alignItems: "center" }}
            >
              <p>Do you want to cancel your transaction? Please confirm.</p>
              <p style={{ textAlign: "center" }}>
                <input type="button" id="cancelOrder" value="Yes" tabIndex={1} onClick={(event) => onClickHandler(event)} />
                {"  "}
                <input type="button" id="closeConfirmCancel" value="No" tabIndex={2} onClick={(event) => onClickHandler(event)} />
              </p>
            </div>
          </dialog>
          <dialog id="confirmOtherAmountDialog" style={{ width: "400", height: "50" }}>
            <div
              id="confirmOtherAmount"
              style={{ verticalAlign: "middle", width: "400px", height: "50px", justifyContent: "center", alignItems: "center" }}
            >
              <p id="confirmOtherAmountVerbiage">{confirmOtherAmountVerbiage}</p>
              <p style={{ textAlign: "center" }}>
                <input type="button" id="otherAmountConfirmed" value="Yes" tabIndex={1} onClick={(event) => onClickHandler(event)} />
                {"  "}
                <input type="button" id="otherAmountCancel" value="No" tabIndex={2} onClick={(event) => onClickHandler(event)} />
              </p>
            </div>
          </dialog>
          <dialog id="cpnetHomeDialog" style={{ width: "400", height: "100" }}>
            <div
              id="cpnetHome"
              style={{ verticalAlign: "middle", width: "400px", height: "100px", justifyContent: "center", alignItems: "center" }}
            >
              <p id="cpnetHomeVerbiage" style={{ whiteSpace: "pre-wrap" }}>
                {`Leave site?\n\nChanges you made may not be saved, Your transaction will be released in 15 minutes.`}
              </p>
              <p style={{ textAlign: "center" }}>
                <input type="button" id="cpnetHomeConfirmed" value="Yes" tabIndex={1} onClick={(event) => onClickHandler(event)} />
                {"  "}
                <input type="button" id="cpnetHomeCancel" value="No" tabIndex={2} onClick={(event) => onClickHandler(event)} />
              </p>
            </div>
          </dialog>
        </div>
      </form>
    </>
  );
}
