

export function profileAdd(tcepps: any, ppa: any) {
  var form = getTceppsProxyForm(tcepps);

  appendHiddenInputs(form, tcepps);
  appendHiddenInputs(form, ppa);

  submitTceppsProxyForm(form, true);
}

export function newOrder(tcepps: any, ppa: any) {
  var form = getTceppsProxyForm(tcepps);

  appendHiddenInputs(form, tcepps);
  appendHiddenInputs(form, ppa);

  submitTceppsProxyForm(form, true);
}

function getTceppsProxyForm(tcepps:any) {
  addTceppsActionApp(tcepps);

  var form = generateTceppsProxyForm(tcepps.action, "tceppsProxyIFrame", "post");

  appendHiddenInput(form, "tcepps.app", tcepps.app.value);
  appendHiddenInput(form, "tcepps.token", tcepps.token.value);
  appendHiddenInput(form, "tcepps.sign", tcepps.sign.value);
  appendHiddenInput(form, "tcepps.serviceOperation", tcepps.serviceOperation.value);

  return form;
}

function appendHiddenInput(form:any, name:string, value:string) {
  var hiddenInput = document.createElement("input");
  hiddenInput.type = "hidden";
  hiddenInput.name = name;
  hiddenInput.value = value;
  form.appendChild(hiddenInput);
}

function addTceppsActionApp(tcepps: any) {
  if (tcepps.app) {
    addTceppsActionParameter(tcepps, "tceppsApp", tcepps.app.value);
  }
}
function addTceppsActionParameter(tcepps:any, parameterName:string, parameterValue:string) {
  if (tcepps.action.indexOf(parameterName + "=") < 0) {
    if (tcepps.action.indexOf("?") < 0) {
      tcepps.action += "?";
    } else {
      tcepps.action += "&";
    }
    tcepps.action += parameterName + "=" + parameterValue;
  }
}

function appendHiddenInputs(targetForm: any, sourceForm: any) {
  // for (let i = 0; i < sourceForm.elements.length; i++) {
  //   var sourceFormElement = sourceForm.elements[i];
  //   appendHiddenInput(targetForm, sourceForm.name + "." + sourceFormElement.name, sourceFormElement.value);
  // }
  for (let sidx = 0; sidx < sourceForm.elements.length; sidx++) {
    var sourceFormElement = sourceForm.elements[sidx];
    var foundElement = false;

    for (let cidx = 0; cidx < targetForm.elements.length; cidx++) {
      var targetFormElement = targetForm.elements[cidx];
      if (targetFormElement.name === sourceForm.name + "." + sourceFormElement.name) {
        foundElement = true;
      }
    }

    if (!foundElement) appendHiddenInput(targetForm, sourceForm.name + "." + sourceFormElement.name, sourceFormElement.value);
  }
}

function submitTceppsProxyForm(tceppsProxyForm: any, checkInvocationError: any) {
 // tceppsInvocationError = checkInvocationError;
  document.body.appendChild(tceppsProxyForm);
  tceppsProxyForm.submit();
  // console.log(tceppsProxyForm);
  document.body.removeChild(tceppsProxyForm);
}

function generateTceppsProxyForm(action:string, target:string, method:string) {
  generateTceppsProxyIFrame();

  var form = document.createElement("form");
  form.action = action;
  form.target = target;
  form.method = method;

  return form;
}

function generateTceppsProxyIFrame() {
  var tceppsProxyIFrame = document.createElement("iframe");
  document.body.appendChild(tceppsProxyIFrame);
  tceppsProxyIFrame.id = "tceppsProxyIFrame";
  tceppsProxyIFrame.name = "tceppsProxyIFrame";
  tceppsProxyIFrame.width = '10';
  tceppsProxyIFrame.height = '10';
  tceppsProxyIFrame.contentWindow!.name = "tceppsProxyIFrame";
  tceppsProxyIFrame.style.display = "none";
  addOnLoadEventListener(tceppsProxyIFrame);
  return tceppsProxyIFrame;
}


function addOnLoadEventListener(tceppsProxyIFrame: any) {
  if (tceppsProxyIFrame.addEventListener) {
    tceppsProxyIFrame.addEventListener("load", tceppsProxyIFrameOnLoad(), true);
  } else {
    tceppsProxyIFrame.attachEvent("onload", tceppsProxyIFrameOnLoad);
  }
}

function tceppsProxyIFrameOnLoad() {
  document.body.removeChild(document.getElementsByName("tceppsProxyIFrame")[0]);
  //setTimeout(checkTceppsInvocationError, 500);
}

