import { contentNav } from "./contentNav";
import ContentOutlet from "./ContentOutlet";
import { Routes, Route } from "react-router-dom";

export default function Content() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ContentOutlet />}>
          {contentNav.map((route, index) => (
            // Render more <Route>s with the same paths as
            // above, but different components this time.
            <Route key={index} path={route.path} element={<route.content />} />
          ))}
        </Route>
      </Routes>
    </div>
  );
}
