const moveUpPlaceholder = (floatingLabel: string, placeHolderValue: string) => {
  let y = document.getElementById(floatingLabel);
  (y as HTMLInputElement).innerHTML = placeHolderValue;
  (y as HTMLInputElement).style.color = "blue";
};

const moveDownPlaceholder = (inputElementValue: string, floatingLabel: string, placeHolderValue: string) => {
  //   let x = document.getElementById(inputElement);
  let y = document.getElementById(floatingLabel);
  if (!inputElementValue || inputElementValue.trim().length === 0) {
    (y as HTMLInputElement).innerHTML = placeHolderValue;
    (y as HTMLInputElement).style.color = "rgba(0, 0, 0, 0.35)";
  }
};

export { moveUpPlaceholder, moveDownPlaceholder };
