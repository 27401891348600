// import api from '../api-client';
import { ApiConstants } from "../utils/ApiConstants";
import axios from "axios";

export default class PaymentService {
  static getCaptureContext = (iFrameUrl: string) => {
    return axios.get(ApiConstants.captureContext, { params: { parentUrl: iFrameUrl } });
  };

  static getPermanentToken = (tempToken: string) => {
    return axios.post(ApiConstants.permToken, { tempToken });
  };

  static getDecryptRequest = async (appName: string, encryptedRequestKey: string, encryptedRequest: string) => {
    return await axios.post(ApiConstants.decryptRequest, {
      encryptedRequest,
      encryptedRequestKey,
      appName,
    });
  };

  static submitCusasm = (cusasm: any) => {
    return axios.post(ApiConstants.cusasm, cusasm);
  };

  static submitMycanoncpsap = (mycanoncps: any) => {
    return axios.post(ApiConstants.mycanoncpsap, mycanoncps);
  };

  static submitMycanon = (mycanon: any) => {
    return axios.post(ApiConstants.mycanon, mycanon);
  };

  static submitMycanoncpscc = (mycanoncps: any) => {
    return axios.post(ApiConstants.mycanoncpscc, mycanoncps);
  };

  static submitMycanonsnr = (mycanonsnr: any) => {
    return axios.post(ApiConstants.mycanonsnr, mycanonsnr);
  };
}
