export function Error404Component(this: any) {
  const error404TextCenter = {
    textAlign: "center",
  } as React.CSSProperties;

  const error404TableCenter = {
    margin: "0 auto",
  } as React.CSSProperties;

  const error404ErrorHeader = {
    fontWeight: "bold",
    fontSize: "25px",
  } as React.CSSProperties;

  const error404ErrorHeader2 = {
    fontWeight: "bold",
    fontSize: "19px",
  } as React.CSSProperties;
  return (
    <>
      <div style={error404TableCenter}>
        <div style={error404TextCenter}>
          <label style={error404ErrorHeader}>417 ERROR </label>
          <br />
          <label style={error404ErrorHeader2}> An error has occurred. </label>
        </div>
      </div>
    </>
  );
}
