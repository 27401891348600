import configSlice from "./configSlice";
import tceppsSlice from "./tcepps_slice";
import tceppsProxySlice from "./tceppsProxy_slice";
import paymentSlice from "./paymentSlice";
import s21CsaMyCsaSlice from "./s21CsaMyCsaSlice";
import s21CsaSlice from "./s21CsaSlice";
import rossPocSlice from "./rossPocSlice";
import cpgmuiSlice from "./cpgmuiSlice";

const rootReducer = () => {
  return {
    config: configSlice.reducer,
    tcepps: tceppsSlice.reducer,
    tceppsProxy: tceppsProxySlice.reducer,
    paymentInfo: paymentSlice.reducer,
    s21csamycsa: s21CsaMyCsaSlice.reducer,
    s21csa: s21CsaSlice.reducer,
    rosspoc: rossPocSlice.reducer,
    cpgmui: cpgmuiSlice.reducer
  };
};

export default rootReducer;
