// @ts-ignore
// "use strict";

//#**********************************************************
//#Function name:getSrcElement
//#The function: The element of the event generation origin is acquired.
//#Argument: Event
//#Return value: Element(element)
//#**********************************************************
function getSrcElement(event) {
  return event.srcElement ? event.srcElement : event.target;
}

//#**********************************************************
//#Function name:cancelEvent
//#The function: The event is invalidated.
//#Argument: Event
//#The return value: None
//#**********************************************************
function cancelEvent(event) {
  if (checkBrowser.isIe()) {
    event.returnValue = false;
  } else {
    event.preventDefault();
  }
}

//#**********************************************************
//#Function name:keyCheck()
//#Function  :The key to the keyboard is checked.BackSpace etc cannot be pushed
//#Argument  :Event
//#Return value :true / false
//#**********************************************************
export function keyCheck(e) {
  let code = e.keyCode;
  if (
    (112 <= code && code <= 123) ||
    code === 27 ||
    (e.ctrlKey &&
      (code === 9 ||
        code === 66 ||
        code === 68 ||
        code === 69 ||
        code === 72 ||
        code === 73 ||
        code === 74 ||
        code === 76 ||
        code === 78 ||
        code === 79 ||
        code === 82 ||
        code === 83 ||
        code === 84 ||
        code === 87))
  ) {
    if (e.ctrlKey && code === 79 && getSrcElement(e).type !== "file") {
      // e.keyCode = null;
    }
    cancelEvent(e);
    return false;
  } else if (e.altKey && code === 36) {
    // HOME
    alert("[Return to the home] shortcut key is invalid.");
    return false;
  } else if (e.altKey && (code === 37 || code === 39)) {
    return false;
  }

  let tagName = getSrcElement(e).tagName;
  let tagType = getSrcElement(e).type;

  let readOnlyFlg = getSrcElement(e).readOnly || getSrcElement(e).disabled;
  if (code === 8) {
    // BackSpace
    if (readOnlyFlg === true) {
      return false;
    } else {
      if (!isPermissionTag(tagName, tagType)) {
        return false;
      }
    }
  }
  invalidEntkey(e);
  return true;
}

//#**********************************************************
//#Function name :isPermissionTag()
//#Function  :Is it tag that permits BackSpace
//#Argument  :event.srcElement.tagName,event.srcElement.type
//#Return value :true / false
//#Remarks :It adds it to tag that password][file] permits the back space
//#**********************************************************
function isPermissionTag(tagName, tagType) {
  if (
    (tagName === "INPUT" && tagType === "text") ||
    (tagName === "INPUT" && tagType === "password") ||
    (tagName === "INPUT" && tagType === "file") ||
    tagName === "TEXTAREA"
  ) {
    return true;
  } else {
    return false;
  }
}

//#**********************************************************
//#Function name :invalidEntkey()
//#Function :Enter is invalidated.
//#Argument :Event
//#Return value :None
//#**********************************************************
function invalidEntkey(e) {
  if (e.keyCode === 13) {
    let myType = getSrcElement(e).type;
    if (myType !== "button") {
      if (myType !== "textarea") {
        if (myType !== "image") {
          //# add by K.Tajima 03/35/09 - START
          if (getSrcElement(e).tagName !== "A") {
            if (myType !== "select-one") {
              cancelEvent(e);
            }
          }
        }
      }
    }
  }
  return true;
}

document.oncontextmenu = showContextMenu;
var CONTEXT_MENU_WIDTH = 0;
var CONTEXT_MENU_SEPARATOR;
var CONTEXT_MENU_ITEM_NAME = [];
var CONTEXT_MENU_ACTION = [];
var CONTEXTMENU_OBJ;

CONTEXT_MENU_WIDTH = 105;
CONTEXT_MENU_SEPARATOR = 3;
CONTEXT_MENU_ITEM_NAME[0] = "Cut";
CONTEXT_MENU_ITEM_NAME[1] = "Copy";
CONTEXT_MENU_ITEM_NAME[2] = "Paste";
CONTEXT_MENU_ITEM_NAME[3] = "Print...";
CONTEXT_MENU_ITEM_NAME[4] = "Print Preview...";
CONTEXT_MENU_ACTION[0] = "cut(event)";
CONTEXT_MENU_ACTION[1] = "copy(event)";
CONTEXT_MENU_ACTION[2] = "paste(event)";
CONTEXT_MENU_ACTION[3] = "printf(event)";
CONTEXT_MENU_ACTION[4] = "printpreview(event)";

/******************************************************************************
 * function name:showContextMenu()
 * input        :none
 * output       :none
 ******************************************************************************/
function showContextMenu(e) {
  let selection = CrossBrowser.getSelectionText(e);
  let event = CrossBrowser.getBrowserEvent(e);
  let menuHtml = "";
  let menuHeight = 7;

  menuHtml += '<div style="border-bottom:1px solid ThreeDDarkShadow; border-right:1px solid ThreeDDarkShadow; "\n';
  menuHtml += '         oncontextmenu="return false">\n';
  menuHtml += '<div style="border:1px solid ButtonShadow; padding:2px; background-color:Menu;">\n';
  let readOnlyFlg = getSrcElement(event).readOnly || getSrcElement(event).disabled;
  if (selection === "") {
    if (
      (getSrcElement(event).tagName === "INPUT" && getSrcElement(event).type === "text") ||
      (getSrcElement(event).tagName === "INPUT" && getSrcElement(event).type === "password") ||
      (getSrcElement(event).tagName === "INPUT" && getSrcElement(event).type === "file") ||
      getSrcElement(event).tagName === "TEXTAREA"
    ) {
      CONTEXT_MENU_ACTION[0] = "";
      CONTEXT_MENU_ACTION[1] = "";
      CONTEXT_MENU_ACTION[2] = "paste(event)";
      if (readOnlyFlg === undefined || readOnlyFlg) {
        CONTEXT_MENU_ACTION[2] = "";
      }
    } else {
      CONTEXT_MENU_ACTION[0] = "";
      CONTEXT_MENU_ACTION[1] = "";
      CONTEXT_MENU_ACTION[2] = "";
    }
  } else {
    if (
      (getSrcElement(event).tagName === "INPUT" && getSrcElement(event).type === "text") ||
      (getSrcElement(event).tagName === "INPUT" && getSrcElement(event).type === "password") ||
      (getSrcElement(event).tagName === "INPUT" && getSrcElement(event).type === "file") ||
      getSrcElement(event).tagName === "TEXTAREA"
    ) {
      CONTEXT_MENU_ACTION[0] = "cut(event)";
      CONTEXT_MENU_ACTION[1] = "copy(event)";
      CONTEXT_MENU_ACTION[2] = "paste(event)";
      if (readOnlyFlg === undefined || readOnlyFlg) {
        CONTEXT_MENU_ACTION[0] = "";
        CONTEXT_MENU_ACTION[2] = "";
      }
    } else {
      CONTEXT_MENU_ACTION[0] = "";
      CONTEXT_MENU_ACTION[1] = "copy(event)";
      CONTEXT_MENU_ACTION[2] = "";
    }
  }
  if (window.name.indexOf("multiScreen") === 0 || !checkBrowser.isIe()) {
    CONTEXT_MENU_ACTION[4] = "";
    CONTEXT_MENU_ITEM_NAME[4] = "";
  }
  if (!checkBrowser.isIe() && !checkBrowser.isFirefox()) {
    CONTEXT_MENU_ACTION[0] = "";
    CONTEXT_MENU_ACTION[1] = "";
    CONTEXT_MENU_ACTION[2] = "";
  }
  for (let count = 0; count < CONTEXT_MENU_ITEM_NAME.length; count++) {
    if (CONTEXT_MENU_ITEM_NAME[count] !== "" && CONTEXT_MENU_ACTION[count] !== "") {
      menuHtml +=
        "<div style=\"padding-top:1px; padding-left:15px; font-family:'Arial', sans-serif; color:MenuText; background-color:Menu; font-size:8pt; height:16px; cursor:default;\"\n";
      menuHtml += "     onmouseover=\"this.style.background='Highlight'; this.style.color='HighlightText'; \"\n";
      menuHtml += "     onmouseout=\"this.style.background='Menu'; this.style.color='MenuText'; \"\n";
      menuHtml += '     onmousedown="parent.' + CONTEXT_MENU_ACTION[count] + '">\n';
      menuHtml += CONTEXT_MENU_ITEM_NAME[count] + "\n";
      menuHtml += "</div>\n";
      menuHeight += 16;
      if (count === CONTEXT_MENU_SEPARATOR - 1) {
        menuHtml += "<hr size=1 width=85% noshade color=silver>\n";
        menuHeight += 16;
      }
    } else if (CONTEXT_MENU_ITEM_NAME[count] !== "" && CONTEXT_MENU_ACTION[count] === "") {
      if (!checkBrowser.isIe() && !checkBrowser.isFirefox()) continue;
      menuHtml +=
        "<div style=\"padding-top:1px; padding-left:15px; font-family:'Arial', sans-serif; color:GrayText; background-color:Menu; font-size:8pt; height:16px; cursor:default;\"\n";
      menuHtml += "     onmouseover=\"this.style.background='Highlight'; this.style.color='GrayText'; \"\n";
      menuHtml += "     onmouseout=\"this.style.background='Menu'; this.style.color='GrayText'; \">\n";
      menuHtml += CONTEXT_MENU_ITEM_NAME[count] + "\n";
      menuHtml += "</div>\n";
      menuHeight += 16;
      if (count === CONTEXT_MENU_SEPARATOR - 1) {
        menuHtml += "<hr size=1 width=85% noshade color=silver>\n";
        menuHeight += 16;
      }
    } else {
      if (!checkBrowser.isIe()) continue;
      menuHtml +=
        '    <div style="margin-left:1px; margin-right:1px; border-bottom:1px solid ButtonShadow; background-color:Menu; font-size:1pt; height:4px; cursor:default;"></div>\n';
      menuHtml +=
        '    <div style="margin-left:1px; margin-right:1px; color:ButtonShadow; background-color:Menu; font-size:1pt; height:5px; cursor:default;"></div>\n';
      menuHeight += 9;
    }
  }
  menuHtml += "</div>\n";
  PopUp.initialize(menuHtml, event);
  PopUp.show(event, menuHeight);
  return false;
}

//#***************************************************************************
//#Class name:EZDDspAppOrder()
//#Function    :Script execution instruction class from EZD screen application program
//#Member :
//#***************************************************************************
var g_ezdDspAppOrder;
function EZDDspAppOrder() {}
EZDDspAppOrder.prototype.execFunctionInfos = [];

EZDDspAppOrder.prototype.addExecFunctionInfo = function (func, args, number) {
  this.execFunctionInfos[number] = [func, args];
};

EZDDspAppOrder.prototype.executeOrder = function (e) {
  for (let i in this.execFunctionInfos) {
    var _info = this.execFunctionInfos[i];
    _info[0].apply(this, _info[1]);
  }
};
g_ezdDspAppOrder = new EZDDspAppOrder();

/*
 *guidef_common.js Copyright FUJITSU LIMITED 2007-2007
 * System name : Common infrastructure component
 * Outline:
 *  [Name]It is GUI control commonness definition file.
 *  [Classification]111,Screen I/O item control
 *  [Attribute](public) (S)
 */
var gEZDGuiDef_common = {
  Version: "1.0.0",
  ArrowKeyAction: 1,
  EnterKeyAction: 1,
  AutoEscapeAction: 1,
  FocusItemHighlight: 1,
  ImeControl: [3, 1, 1, 0],
  FunctionKeyAction: [
    "ID:btn1",
    "ID:btn2",
    "ID:btn3",
    "ID:btn4",
    "ID:btn5",
    "ID:btn6",
    "ID:btn7",
    "ID:btn8",
    "ID:btn9",
    "ID:btn10",
    "",
    "",
  ],
};

var g_guiDefCommon = {
  ArrowKeyAction: null,
  EnterKeyAction: null,
  AutoEscapeAction: null,
  FocusItemHighlight: null,
  ImeControl: null,
  FunctionKeyAction: null,
};
var g_guiDefItem = {};

//#***************************************************************************
//#Function name  :ezd_mergeForCommon()
//#Function    :Merge only application of common operation definition information
//#Argument    :ScreenId:Screen ID
//#Return value  :None
//#***************************************************************************
export function ezd_mergeForCommon(ScreenId) {
  //------------------------------------------------------------
  // Merge only application of operation definition information on focus movement with cursor key
  //------------------------------------------------------------
  g_guiDefCommon.ArrowKeyAction = gEZDGuiDef_common.ArrowKeyAction;

  //------------------------------------------------------------
  // Merge only application of operation definition information on focus movement with 'Enter'
  //------------------------------------------------------------
  g_guiDefCommon.EnterKeyAction = gEZDGuiDef_common.EnterKeyAction;

  //------------------------------------------------------------
  // Merge only application of operation definition information on focus movement by the maximum digit input
  //------------------------------------------------------------
  g_guiDefCommon.AutoEscapeAction = gEZDGuiDef_common.AutoEscapeAction;

  //------------------------------------------------------------
  // Merge only application of focus item emphasis display information
  //------------------------------------------------------------
  g_guiDefCommon.FocusItemHighlight = gEZDGuiDef_common.FocusItemHighlight;

  //------------------------------------------------------------
  // Merge only application of IME automatic control information
  //------------------------------------------------------------
  g_guiDefCommon.ImeControl = gEZDGuiDef_common.ImeControl;

  //------------------------------------------------------------
  // Merge only application of button action information with function key
  //------------------------------------------------------------
  g_guiDefCommon.FunctionKeyAction = gEZDGuiDef_common.FunctionKeyAction;
}

//#***************************************************************************
//#Function name  :ezd_getMovementDefinition()
//#Function    :The operation definition is acquired.
//#Argument    :itemName  :Each item null item name-is not specified.
//#       dir     :0-Up, 1-Down, 2-Left, 3-Right, null-Excluding the arrow key
//#       defName :Operation definition name
//#Return value   :Operation definition
//#***************************************************************************
function ezd_getMovementDefinition(itemName, dir, defName) {
  var ret;

  if (itemName === null) {
    ret = g_guiDefCommon[defName];
  } else {
    var arrayNo = ezd_getDefinitionNo(dir, defName);
    if (arrayNo !== null) {
      if (g_guiDefItem[itemName] !== undefined) {
        ret = g_guiDefItem[itemName][arrayNo];
      }
    }
  }
  return ret === undefined ? null : ret;
}

//#***************************************************************************
//#Function name  :ezd_getDefinitionNo()
//#Function    :The array element number where the operation definition is stored is acquired.
//#Argument    :dir :0-Up, 1-Down, 2-Left, 3-Right
//#       defName:Operation definition name
//#Return value  :Number of array elements
//#***************************************************************************
function ezd_getDefinitionNo(dir, defName) {
  if (defName === "ArrowKeyAction" && dir !== null) {
    switch (dir) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      default:
        return null;
    }
  } else if (defName === "EnterKeyAction") {
    return 4;
  } else if (defName === "AutoEscapeAction") {
    return 5;
  } else if (defName === "ImeControl") {
    return 6;
  }
  return null;
}

//------------------------------------------------------------------------------
// Common processing
//------------------------------------------------------------------------------

//#***************************************************************************
//#Function name  :ezd_getElementFromEvent()
//#Function    :A generation former element of the event is specified.
//#Argument    :e:Event(event)
//#Return value  :Element(element)
//#***************************************************************************
function ezd_getElementFromEvent(e) {
  return e.srcElement ? e.srcElement : e.target;
}

//#***************************************************************************
//#Function name  :ezd_stopEventBubble()
//#Function    :Babring and the action of the event are stopped.
//#Argument    :e:Event(event)
//#Return value  :None
//#***************************************************************************
function ezd_stopEventBubble(e) {
  if (window.event) {
    window.event.cancelBubble = true;
    window.event.returnValue = false;
  } else {
    e.cancelBubble = true;
    e.preventDefault();
  }
}

//#***************************************************************************
//#Function name  :ezd_addListener()
//#Function    :Additional registration event handler is done to the listener for an arbitrary element.
//#Argument    :elem  :Element(element)
//#       eType :Event type
//#       funk  :Event handler
//#       cap :Capture
//#Return value  :None
//#***************************************************************************
export function ezd_addListener(elem, eType, func, cap) {
  // console.log("ezd_addListener .....elem000<" + elem.addEventListener + ">  func<" + func + "> cap<" + cap + ">");
  // console.log("elem:" + elem.id);
  if (elem.addEventListener) {
    if (eType === "onFocus" || eType === "onBlur") {
      // console.log("ezd_addListener .....eType111<" + eType + ">  func<" + func + "> cap<" + cap + ">");
    }
    // console.log("ezd_addListener .....eType111<" + eType + ">  func<" + func + "> cap<" + cap + ">");
    // console.log("ezd_addListener .....eType111<" + eType + ">");
    elem.addEventListener(eType, func, cap);
  } else if (elem.attachEvent) {
    // console.log("ezd_addListener .....eType2222<" + eType + ">  func<" + func + ">");
    // console.log("ezd_addListener .....eType2222<" + eType + ">");
    elem.attachEvent("on" + eType, func);
  } else {
    alert("It failed in adding the event handler. \n event handler[" + func + "]");
    return false;
  }
}

//#***************************************************************************
//#Function name  :ezd_removeListener()
//#Function    :The event handler that registers an arbitrary element is deleted from the listener.
//#Argument    :elem  :Element(element)
//#       eType :Event type
//#       func  :Function
//#       cap :Capture
//#Return value  :None
//#***************************************************************************
function ezd_removeListener(elem, eType, func, cap) {
  if (elem.removeEventListener) {
    elem.removeEventListener(eType, func, cap);
  } else if (elem.detachEvent) {
    elem.detachEvent("on" + eType, func);
  } else {
    alert("It failed in deleting the event handler. \n event handler[" + func + "]");
    return false;
  }
}

//#***************************************************************************
//#Function name  :ezd_getDynamicStyle()
//#Function    :Style information not specified by in-line for an arbitrary element is acquired.
//#Argument    :el  :Element(element)
//#       style :Style name(styleAttributeName)
//#Return value  :styleAttributeValue
//#***************************************************************************
function ezd_getDynamicStyle(el, style) {
  if (el.currentStyle) {
    return el.currentStyle[style];
  } else if (document.defaultView) {
    return document.defaultView.getComputedStyle(el, "").getPropertyValue(style);
  }
  return null;
}

//#***************************************************************************
//#Function name  :ezd_getActionInfo()
//#Function    :The action and the parameter of the operation definition are returned.
//#Argument    :el    :Element(element)
//#       dir :Type of arrow key(Excluding 0-Up, 1-Down, 2-Left, 3-Right, and null-arrow key. )
//#       difKey  :Operation definition key name
//#Return value  :Array 0 of action and parameter: Action 1: Parameter
//#***************************************************************************
function ezd_getActionInfo(el, dir, difKey) {
  var action = null;
  var param = null;
  var defInfo = null;
  var arrayNo = -1;
  var itemName = null;

  var els = null;
  if (el.name.length !== 0) {
    els = document.getElementsByName(el.name);
    itemName = el.name;
    for (var i = 0; i < els.length; i++) {
      if (els[i] === el) {
        arrayNo = i;
        break;
      }
    }
  }

  if (itemName !== null) {
    defInfo = ezd_getMovementDefinition("NM:" + itemName + "," + arrayNo, dir, difKey);
    if (defInfo === null) {
      defInfo = ezd_getMovementDefinition("NM:" + itemName, dir, difKey);
    }
  }

  if (defInfo === null) {
    if (el.id !== undefined && el.id.length !== 0) {
      els = document.getElementById(el.id);
      if (els.id !== undefined && els.id.length !== 0) {
        itemName = els.id;
      }
    }
    if (itemName !== null) {
      defInfo = ezd_getMovementDefinition("ID:" + itemName, dir, difKey);
    }
  }

  if (defInfo === null) {
    action = ezd_getMovementDefinition(null, dir, difKey);
  } else if (!isNaN(defInfo)) {
    action = defInfo.toString();
  } else {
    if (defInfo.charAt(0) === "2") {
      action = parseInt(defInfo.charAt(0));
      param = defInfo.substr(2);
    } else {
      if (defInfo.charAt(0) !== "0" && defInfo.charAt(0) !== "1" && defInfo.charAt(0) !== "3") {
        alert(
          "It is an operation definition error. Please confirm the operation definition. \n The operation definition of key[" +
            itemName +
            "]is illegal."
        );
        return null;
      }
      action = defInfo.charAt(0);
    }
  }
  return [action, param];
}

//#***************************************************************************
//#Function name  :ezd_isFocusElements
//#Function    :It is returned whether el of the argument is an element of the focus object.
//#Argument    :el:Element(element)
//#Return value  :true:Focus object false:Focus non-object
//#***************************************************************************
function ezd_isFocusElements(el) {
  var tagName = el.tagName;

  if (tagName === "INPUT") {
    if (el.type !== "hidden") {
      return true;
    }
  } else if (tagName === "A" || tagName === "TEXTAREA" || tagName === "SELECT" || tagName === "BUTTON") {
    return true;
  }
  return false;
}

//#***************************************************************************
//#Function name  :ezd_getAllElements()
//#Function    :All elements under the control of body are returned.
//#Argument    :None
//#Return value  :All elements under the control of body
//#***************************************************************************
function ezd_getAllElements() {
  var allElms = [];
  var bodyElm = document.getElementsByTagName("body")[0];

  if (bodyElm !== null) {
    ezd_pushChildNodesRecursively(bodyElm, allElms);
  }

  return allElms;
}

//#***************************************************************************
//#Function name  :ezd_pushChildNodesRecursively()
//#Function    :The child element that makes node a starting point is stored in array and returned
//#       recurrently (child element, grandchild element, and element).
//#       Storage order standard element directly child element grandchild element all trace
//#       standard element following child element order.
//#       *Output element (id=="_ezd_DebugConsoleDiv") for debugging is excluded and stored.
//#Argument    :node  :Starting point node
//#       array :Array at storage destination
//#Return value  :None
//#***************************************************************************
function ezd_pushChildNodesRecursively(node, array) {
  if (node.id === "_ezd_DebugConsoleDiv") {
    return;
  }
  var nodes = node.childNodes;
  if (nodes === null || nodes.length === 0) {
    return;
  }

  for (var i = 0; i < nodes.length; i++) {
    if (nodes[i].id === "_ezd_DebugConsoleDiv") {
      continue;
    }
    array[array.length] = nodes[i];
    ezd_pushChildNodesRecursively(nodes[i], array);
  }
}

//-------------------------------------------------------------------------------
// Focus movement relation
//-------------------------------------------------------------------------------
var g_ezdElmArray;

//#***************************************************************************
//#Function name  :ezd_getFocusableElementsList()
//#Function    :The element list where focus moves is extracted.
//#Argument    :None
//#Return value  :All element for focus movement list
//#***************************************************************************
function ezd_getFocusableElementsList() {
  if (!g_ezdElmArray) {
    g_ezdElmArray = ezd_getFocusElements();
  }
  return g_ezdElmArray;
}

//#***************************************************************************
//#Function name  :ezd_getFocusElements
//#Function    :All elements of the focus object are returned.
//#Argument    :None
//#Return value  :All elements of focus object
//#***************************************************************************
function ezd_getFocusElements() {
  var elms = ezd_getAllElements();
  var fcsElems = [];

  for (var i = 0; i < elms.length; i++) {
    if (ezd_isFocusElements(elms[i])) {
      fcsElems[fcsElems.length] = elms[i];
    }
  }
  return fcsElems;
}

//#***************************************************************************
//#Function name  :ezd_isEditableElement()
//#Function    :It is specified element visible.
//#       Whether it edits it (It is neither readOnly nor DISABLE) is judged.
//#Argument    :el:Element(element)
//#Return value  :True (It is possible to edit it)/false. (It is not possible to edit it. )
//#***************************************************************************
function ezd_isEditableElement(el) {
  if (ezd_getDynamicStyle(el, "display") === "none") {
    return false;
  }

  var chknode = el;
  while (chknode) {
    var chkVisible = ezd_getDynamicStyle(chknode, "visibility");
    if (chkVisible === "hidden") {
      return false;
    } else if (chkVisible === "inherit") {
      chknode = chknode.parentNode;
    } else {
      break;
    }
  }

  var rtn = !el.readOnly && !el.disabled;
  if (rtn) {
    var clsnames = el.className.split(" ");
    for (let i = 0; i < clsnames.length; i++) {
      if (clsnames[i] === "pPro") {
        rtn = false;
        break;
      }
    }
    // Add T.Tsuji START
    clsnames = el.className.split(".");
    for (let i = 0; i < clsnames.length; i++) {
      if (clsnames[i] === "pPro") {
        rtn = false;
        break;
      }
    }
    // Add T.Tsuji END
  }
  return rtn;
}

//#***************************************************************************
//#Function name  :ezd_isTextEditElement()
//#Function    :It is judged whether the specified element is an element that can be the text input.
//#Argument    :el:Element(element)
//#Return value  :true/false
//#***************************************************************************
function ezd_isTextEditElement(el) {
  if (el === null) {
    return false;
  }
  return el.type === "text" || el.type === "password" || el.tagName === "TEXTAREA";
}

//#***************************************************************************
//#Function name  :ezd_isMultiLineTextEditElement()
//#Function    :The specified element judges whether it is text of the multi line edit input element.
//#Argument    :el:Element(element)
//#Return value  :true/false
//#***************************************************************************
function ezd_isMultiLineTextEditElement(el) {
  if (el.tagName === "TEXTAREA") {
    return true;
  }
  return false;
}
//#***************************************************************************
//#Function name  :ezd_getTextCursorPos()
//#Function    :The cursor position of an active Text element is acquired.
//#Argument    :el:Element(element)
//#Return value  :Cursor position
//#***************************************************************************
function ezd_getTextCursorPos(el) {
  var pos = 0;
  if (checkBrowser.isIe()) {
    var range = document.selection.createRange();
    if (el.type !== "textarea") {
      range.moveStart("textedit", -1);
      pos = range.text.length;
    } else {
      var pEl = range.parentElement();
      var cRange = pEl.createTextRange();
      cRange = document.body.createTextRange();
      cRange.moveToElementText(pEl);

      if (cRange.text.match("\n") === null) {
      } else {
      }
      cRange.setEndPoint("EndToStart", range);
      pos = cRange.text.length;
    }
  } else {
    pos = el.selectionStart;
  }
  return pos;
}

//#***************************************************************************
//#Function name  :ezd_getNextElementForAutoEscape
//#Function    :The moving destination element when the TAB key is pressed to focus movable by the number of maximum digits is specified.
//#Argument    :cur:Element(input field with present focus)
//#Return value  :Element the next focus ahead
//#***************************************************************************
function ezd_getNextElementForAutoEscape(cur) {
  return ezd_getNextElement(cur, false, false);
}

//#***************************************************************************
//#Function name  :ezd_getNextElement
//#Function    :The following element is acquired.
//#Argument    :el  :Element of movement origin
//#       reverseFlag :The opposite direction flag of retrieval(true/false)
//#       textOnlyFlag  :Flag intended for only text item(true/false)
//#Return value  :The following element
//#***************************************************************************
function ezd_getNextElement(el, reverseFlag, textOnlyFlag) {
  var arTab = [];
  var ar = ezd_getFocusableElementsList();
  for (var i = 0; i < ar.length; i++) {
    if (ar[i].tabIndex < 0) {
      continue;
    }
    arTab[arTab.length] = ar[i];
  }

  if (!checkBrowser.isChrome()) {
    arTab.sort(function (_e1, _e2) {
      return _e1.tabIndex - _e2.tabIndex;
    });
  } else {
    // chrome 18.0
    arTab = utilFunc.margeSort(arTab, function (_e1, _e2) {
      return _e1.tabIndex - _e2.tabIndex;
    });
  }
  var els = arTab;
  if (reverseFlag === true) {
    els.reverse();
  }

  var curIdx;
  for (curIdx = 0; curIdx < els.length; curIdx++) {
    if (els[curIdx] === el) {
      break;
    }
  }

  var next = null;

  if (curIdx < els.length) {
    var startIdx = curIdx;
    while (true) {
      curIdx++;
      if (curIdx >= els.length) {
        // Start or End element
        curIdx = 0;
      }
      if (curIdx === startIdx) {
        break;
      }
      if (!ezd_isEditableElement(els[curIdx])) {
        continue;
      }
      if (textOnlyFlag === true) {
        if (ezd_isTextEditElement(els[curIdx])) {
          next = els[curIdx];
          break;
        }
      } else {
        next = els[curIdx];
        break;
      }
    }
  }
  return next;
}

//#***************************************************************************
//#Function name  :ezd_emulateTabkeyPress()
//#Function    :Operation equal with the TAB key pressing is emulated.
//#Argument    :e   :Event(event)
//#       direction :0=TAB, 1=SHIFT+TAB
//#       textOnlyFlag  :true=Only the text item is targeted. false=All items are targeted.
//#Return value  :None
//#***************************************************************************
function ezd_emulateTabkeyPress(e, direction, textOnlyFlag) {
  var el = ezd_getElementFromEvent(e);
  var reverseFlag = direction === 1 ? true : false;
  var next = ezd_getNextElement(el, reverseFlag, textOnlyFlag);
  if (next === null) {
    return;
  }
  ezd_stopEventBubble(e);
  if (ezd_isTextEditElement(el)) {
    el.select();
    // el.value = el.value;
  }
  next.focus();
  if (ezd_isTextEditElement(next)) {
    next.select();
  }
}

//#***************************************************************************
//#Function name  :ezd_keypressHandlerForAutoEscape()
//#Function       :Focus movable keypress event handler by number of maximum digits
//#       If this event handler is registered, an automatic escape by maxLength
//#       becomes effective in all input fields.
//#Argument       :e:Event(event)
//#Return value   :None
//#***************************************************************************
function ezd_keypressHandlerForAutoEscape(e) {
  var el = ezd_getElementFromEvent(e);
  // FireFox Only
  var code = e.keyCode;
  if (checkBrowser.isFirefox() && code !== 0) return false;
  if (checkBrowser.isWebkit() && code === 13) return false;
  if (!ezd_isTextEditElement(el) || ezd_isMultiLineTextEditElement(el)) {
    // console.log("ezd_keypressHandlerForAutoEscape ....return 1.........................");
    return;
  }
  if (el.name === null) {
    // console.log("ezd_keypressHandlerForAutoEscape ....return 2.........................");
    return;
  }
  if (el.readOnly === true) {
    // console.log("ezd_keypressHandlerForAutoEscape ....return 3.........................");
    return;
  }
  // var dataName = null;
  // let post = el.name.substr(el.name.length - 2);
  // if (post === "_g" || post === "_y" || post === "_m" || post === "_d") {
  //   dataName = el.name.substr(0, el.name.length - 2);
  // } else {
  //   dataName = el.name;
  // }
  var mLength = el.maxLength;
  // mLength -1 : FireFox
  // mLength 524288 Chrome/Safari
  // START -04/11/2012 M.Yaguchi for cross browser
  //var selectedText = ezd_getSelectedText(el);
  var selectedText = ezd_getSelectedText(el, e);
  // End   -04/11/2012 M.Yaguchi for cross browser
  if (selectedText === "" && el.value.length >= mLength - 1) {
    var actionInfo = ezd_getActionInfo(el, null, "AutoEscapeAction");
    if (actionInfo === null) {
      // console.log("ezd_keypressHandlerForAutoEscape ....return 4.........................");
      return;
    }
    if (typeof actionInfo[0] === "number") {
      actionInfo[0] = "" + actionInfo[0];
    }
    //------------------------------
    // Execution of action
    //------------------------------
    if (actionInfo[0] === "2") {
      if (actionInfo[1] === undefined) {
        alert(
          "It is an operation definition error. The ID attribute is not set. \n The operation definition of key[" +
            el.name +
            "]or [AutoEscapeAction] is illegal. "
        );
        // console.log("ezd_keypressHandlerForAutoEscape ....return 5.........................");
        return;
      }
      let next = document.getElementById(actionInfo[1].substr(3));
      if (next === null) {
        alert("It failed in moving the focus. \n The Field of ID attribute[" + actionInfo[1].substr(3) + "]was not found. ");
        // console.log("ezd_keypressHandlerForAutoEscape ....return 6.........................");
        return;
      } else {
        if (el.value.length <= mLength - 1) {
          let curPos = ezd_getTextCursorPos(el);
          if (code === 0) code = e.charCode;
          let c = String.fromCharCode(code);
          el.value = el.value.substring(0, curPos) + c + el.value.substring(curPos, el.value.length);
        }
        ezd_stopEventBubble(e);
        ezd_emulateButtonPress(actionInfo[1].substr(3), e);
      }
    } else if (actionInfo[0] === "1") {
      let next = ezd_getNextElementForAutoEscape(el);
      if (next === null) {
        // console.log("ezd_keypressHandlerForAutoEscape ....return 7.........................");
        return;
      } else {
        if (el.value.length <= mLength - 1) {
          let curPos = ezd_getTextCursorPos(el);
          if (code === 0) code = e.charCode;
          let c = String.fromCharCode(code);
          el.value = el.value.substring(0, curPos) + c + el.value.substring(curPos, el.value.length);
        }
        ezd_stopEventBubble(e);
        if (checkBrowser.isIe()) {
          // For IE Emurate EnterKey
          // e.keyCode = 13;
          e.srcElement.fireEvent("onkeydown", e);
          // console.log("ezd_keypressHandlerForAutoEscape ....return IIIEEE.........................");
        } else {
          var evt = document.createEvent("KeyboardEvent");
          if (checkBrowser.isFirefox()) {
            // FireFox
            var prop = {
              canBubble: true,
              cancelable: true, // false?
              view: null,
              ctrlKey: false,
              altKey: false,
              shiftKey: false,
              metaKey: false,
              keyCode: 13, // EnterKey
              charCode: 0,
            };
            evt.initKeyEvent(
              "keydown",
              prop.canBubble,
              prop.cancelable,
              prop.view,
              prop.ctrlKey,
              prop.altKey,
              prop.shiftKey,
              prop.metaKey,
              prop.keyCode,
              prop.charCode
            );
          } else {
            // Chrome, Safari
            // DOM Level 3
            let prop = {
              canBubble: true,
              cancelable: true, // false?
              view: null,
              // keyIdentifier: "Enter",
              keyIdentifier: "U+0009", // tabKey
              keyLocation: 0x00,
              modifiersList: "",
              repeat: false,
            };
            evt.initKeyboardEvent(
              "keydown",
              prop.canBubble,
              prop.cancelable,
              prop.view,
              prop.keyIdentifier,
              prop.location,
              prop.modifiersList,
              prop.repeat
            );
          }
          // console.log("ezd_keypressHandlerForAutoEscape ....return dispatchEventdispatchEvent.........................");
          e.target.dispatchEvent(evt);
        }
      }
    } else if (actionInfo[0] === "0") {
      // console.log("ezd_keypressHandlerForAutoEscape ....return 8.........................");
    } else {
      alert(
        "It is an operation definition error. Please confirm the operation definition. \n The operation definition of key[AutoEscapeAction]is illegal."
      );
      // console.log("ezd_keypressHandlerForAutoEscape ....return 9.........................");
    }
  } else if (selectedText.length === 1 && el.value.length === mLength) {
    // console.log("ezd_keypressHandlerForAutoEscape ....return 11.........................");
    ezd_addListener(document.body, "keyup", ezd_keyupHandlerForAutoEscapeAtOnce, false);
  }
}

//#***************************************************************************
//#Function name:ezd_getSelectedText
//#Function:The character string has been selected by the element of the argument is returned.
//#Argument:el:Element(element)
//#:e :Event(event)
//#Return value:Character string that has been selected
//#***************************************************************************
function ezd_getSelectedText(el, e) {
  if (checkBrowser.isIe()) {
    return document.selection.createRange().text;
  } else {
    return CrossBrowser.getSelectionText(e);
  }
}

//#***************************************************************************
//#Function name:ezd_keyupHandlerForAutoEscapeAtOnce
//#Function:Focus movement by the fact that the maximum digit is automatic
//#  (When the key improves, it is used. )
//#Argument:e:Event(event)
//#Return value:Character string that has been selected
//#***************************************************************************
function ezd_keyupHandlerForAutoEscapeAtOnce(e) {
  var el = ezd_getElementFromEvent(e);

  if (!ezd_isTextEditElement(el) || ezd_isMultiLineTextEditElement(el)) {
    return;
  }
  if (el.name === null) {
    return;
  }

  if (el.readOnly === true) {
    return;
  }
  // var dataName = null;
  // let post = el.name.substr(el.name.length - 2);
  // if (post === "_y" || post === "_m" || post === "_d") {
  //   dataName = el.name.substr(0, el.name.length - 2);
  // } else {
  //   dataName = el.name;
  // }

  var mLength = el.maxLength;
  var selectedText = ezd_getSelectedText(el, e);
  if (selectedText === "" && el.value.length === mLength) {
    var actionInfo = ezd_getActionInfo(el, null, "AutoEscapeAction");
    if (actionInfo === null) {
      return;
    }
    if (typeof actionInfo[0] === "number") {
      actionInfo[0] = "" + actionInfo[0];
    }

    //------------------------------
    // Execution of action
    //------------------------------
    if (actionInfo[0] === "2") {
      if (actionInfo[1] === undefined) {
        alert(
          "It is an operation definition error. The ID attribute is not set. \n" +
            "The operation definition of key[" +
            el.name +
            "]or [AutoEscapeAction] is illegal. "
        );
        return;
      }
      let next = document.getElementById(actionInfo[1].substr(3));
      if (next === null) {
        alert("It failed in moving the focus. \n The Field of ID attribute[" + actionInfo[1].substr(3) + "]was not found. ");
        return;
      } else {
        ezd_stopEventBubble(e);
        ezd_emulateButtonPress(actionInfo[1].substr(3), e);
      }
    } else if (actionInfo[0] === "1") {
      let next = ezd_getNextElementForAutoEscape(el);

      if (next === null) {
        return;
      } else {
        ezd_stopEventBubble(e);
        next.focus();
        if (ezd_isTextEditElement(next)) {
          next.select();
        }
      }
    } else if (actionInfo[0] === "0") {
    } else {
      alert(
        "It is an operation definition error. Please confirm the operation definition. \n The operation definition of key[AutoEscapeAction]is illegal."
      );
    }
  }
  ezd_removeListener(document.body, "keyup", ezd_keyupHandlerForAutoEscapeAtOnce, false);
}

//#***************************************************************************
//#Function name  :ezd_keydownHandlerForEnterKeyAction()
//#Function    :Keydown event handler for 'Enter'
//# The 'Enter' emulation set to register this
//# event handler becomes effective.
//#Argument    :e:Event(event)
//#Return value  :None
//#***************************************************************************
function ezd_keydownHandlerForEnterKeyAction(e) {
  if (!e) {
    e = window.event;
  }

  if (e.altKey || e.ctrlKey || e.shiftKey) {
    return;
  }
  switch (e.keyCode) {
    case 13:
      var el = ezd_getElementFromEvent(e);
      if (!ezd_isTextEditElement(el) || ezd_isMultiLineTextEditElement(el)) {
        break;
      }
      ezd_actionByEnterKeyPress(e, el);
      break;

    default:
      break;
  }
}

//#***************************************************************************
//#Function name  :ezd_actionByEnterKeyPress()
//#Function    :Focus movement with 'Enter'
//#Argument    :e:Event(event)
//# el :Element in event generation origin(element)
//#Return value  :None
//#***************************************************************************
function ezd_actionByEnterKeyPress(e, el) {
  var actionInfo = ezd_getActionInfo(el, null, "EnterKeyAction");
  if (actionInfo === null) {
    return;
  }
  if (typeof actionInfo[0] === "number") {
    actionInfo[0] = "" + actionInfo[0];
  }

  if (actionInfo[0] === "2") {
    if (actionInfo[1] === undefined) {
      alert(
        "It is an operation definition error. The ID attribute is not set. \n The operation definition of key[" +
          el.name +
          "]or[EnterKeyAction]is illegal. "
      );
      return;
    }
    ezd_emulateButtonPress(actionInfo[1].substr(3), e);
  } else if (actionInfo[0] === "1") {
    ezd_emulateTabkeyPress(e, 0, false);
  } else if (actionInfo[0] === "0") {
  } else {
    alert(
      "It is an operation definition error. Please confirm the operation definition. \n The operation definition of key[EnterKeyAction]is illegal."
    );
  }
}

//#***************************************************************************
//#Function name  :ezd_keydownHandlerForFunctioKeyEmulation()
//#Function    :The FANCTION key emulation to which the keydown event handler for the function
//# key is set to register this event handler
//# becomes effective.
//#Argument    :e:Event(event)
//#Return value  :None
//#***************************************************************************
function ezd_keydownHandlerForFunctioKeyEmulation(e) {
  if (!e) {
    e = window.event;
  }

  if (e.keyCode < 112 || 123 < e.keyCode) {
    return;
  }

  if (e.shiftKey && e.keyCode === 121) {
    return;
  }
  if (getSrcElement(e).type === "file") {
    return;
  }

  var FKeyNum = e.keyCode - 111;
  // e.keyCode = null;

  ezd_stopEventBubble(e);
  try {
    var fkeyDef = window.top.ezd_getEmulateButtonIDByFunctioKey(FKeyNum);
    if (fkeyDef === null) {
      return;
    }
    if (fkeyDef[0] === "ID") {
      var btnID = fkeyDef[1];
      ezd_emulateButtonPress(btnID, e);
    }
  } catch (e) {
    // invokeValidationError("[F" + FKeyNum + "] short cut key is invalid.");
    //invokeValidationError(FKeyNum);
  }
  return;
}

//#***************************************************************************
//#Function name  :ezd_emulateButtonPress()
//#Function    :The button pressing is emulated.
//#Argument    :btnID:ID attribute value of emulated button
//#Return value  :None
//#***************************************************************************
function ezd_emulateButtonPress(btnID, e) {
  var emulateButton = window.top.document.getElementById(btnID);
  if (!emulateButton) {
    //  alert("It failed in acquiring the related button. \n The Field of ID attribute["+btnID+"]was not found.");
    return;
  }
  if (window.top.ezd_isEditableElement(emulateButton)) {
    try {
      emulateButton.click();
      ezd_stopEventBubble(e);
    } catch (ex) {
      alert("It failed in acquiring the related button. \n ID attribute[" + btnID + "]is not a button Field.");
    }
  }
}

//-----------------------------------------------------------------------------
// Focus movement function with cursor key
//-----------------------------------------------------------------------------
//#***************************************************************************
//#Function name  :ezd_getElementABSCoord()
//#Function    :The absolute coordinate of the element to which it is given on the left of the browser drawing area as a starting point is requested.
//           Coordinates information is adding absolutely recorded in the element.
//#Argument    :el:Element(element)
//#Return value  :None
//#***************************************************************************
function ezd_getElementABSCoord(el) {
  var offX = 0,
    offY = 0;
  var parent = el.offsetParent;
  while (parent && parent.tagName !== "BODY") {
    offX += parent.offsetLeft;
    if (parent.scrollLeft !== undefined) {
      offX -= parent.scrollLeft;
    }
    offY += parent.offsetTop;
    if (parent.scrollTop !== undefined) {
      offY -= parent.scrollTop;
    }
    parent = parent.offsetParent;
  }
  el._absLeft = offX + el.offsetLeft;
  el._absTop = offY + el.offsetTop;
}

//#***************************************************************************
//#Function name  :ezd_calcMinimumDistance()
//#Function    :The beeline (2 drinking multiplication) between rectangles B is requested a certain point A.
//#Argument    :ax:X coordinates of movement origin
//#       ay:Y coordinates of movement origin
//#       bx:X coordinates in moving destination
//#       by:Y coordinates in moving destination
//#       bw:Width of element of moving destination
//#       bh:Height of element of moving destination
//#Return value  :true(It comes in succession.), false(It doesn't come in succession.)
//#***************************************************************************
function ezd_calcMinimumDistance(ax, ay, bx, by, bw, bh) {
  var w;
  var h;
  if (ax < bx) {
    w = bx - ax;
  } else if (ax <= bx + bw) {
    w = 0;
  } else {
    w = ax - bx - bw;
  }
  if (ay < by) {
    h = by - ay;
  } else if (ay <= by + bh) {
    h = 0;
  } else {
    h = ay - by - bh;
  }
  return w * w + h * h;
}

//#***************************************************************************
//#Function name  :ezd_moveFocusByShortestDistance()
//#Function    :Focus moves in the direction where cursor key (Up Down) is specified.
//#Argument    :e:Event
//#            :dir:0-Up, 1-Down
//#Return value  :None
//#***************************************************************************
var g_ezdIsDistanceCalced = false;
function ezd_moveFocusByShortestDistance(e, dir) {
  var ar = ezd_getFocusableElementsList();

  if (!g_ezdIsDistanceCalced) {
    for (var i = 0; i < ar.length; i++) {
      ezd_getElementABSCoord(ar[i]);
    }
    g_ezdIsDistanceCalced = true;
  }
  var iel = ezd_getElementFromEvent(e);
  var DD = 99999999;
  if (typeof iel._absLeft === "undefined") {
    ezd_getElementABSCoord(iel);
  }

  var x0, y0;
  x0 = iel._absLeft + iel.offsetWidth / 2;
  if (dir === 0) {
    y0 = iel._absTop;
  } else {
    y0 = iel._absTop + iel.offsetHeight;
  }
  var pri_target = null;
  var pri_DD = DD;
  var target = null;

  for (let i = 0; i < ar.length; i++) {
    var el = ar[i];
    if (el === iel || !ezd_isEditableElement(el)) {
      continue;
    }

    var y1 = el._absTop + el.offsetHeight / 2;
    var Y = y0 - y1;
    if ((dir === 0 && Y >= 0) || (dir === 1 && Y <= 0)) {
      var dist = ezd_calcMinimumDistance(x0, y0, el._absLeft, el._absTop, el.offsetWidth, el.offsetHeight);
      if (dist < DD) {
        target = el;
        DD = dist;
      }

      //------------------------------------------------------------------------------------
      // Does the movement candidate in B area exist?
      // In the movement direction coordinates, it gives priority as a moving destination when it is included in the movement origin.
      //------------------------------------------------------------------------------------
      var iel_left = iel._absLeft;
      var iel_right = iel._absLeft + iel.offsetWidth - 1;
      var el_left = el._absLeft;
      var el_right = el._absLeft + el.offsetWidth - 1;

      if (
        (iel_left <= el_left && el_left <= iel_right) ||
        (iel_left <= el_right && el_right <= iel_right) ||
        (el_left <= iel_left && iel_right <= el_right)
      ) {
        if (dist < pri_DD) {
          pri_target = el;
          pri_DD = dist;
        }
      }
    }
  }

  ezd_stopEventBubble(e);

  if (pri_target !== null) {
    target = pri_target;
  }

  if (target !== null) {
    target.focus();
    if (ezd_isTextEditElement(target)) {
      target.select();
    }

    // if (ezd_isTextEditElement(iel)) {
    //   iel.value = iel.value;
    // }
  }
}

//#***************************************************************************
//#Function name  :ezd_actionByArrowKeyPress()
//#Function    :The moving destination of pressing the arrow key is decided, and focus is moved.
//#Argument    :e :Event(event)
//#            :dir:0-Up, 1-Down, 2-Left, 3-Right
//#Return value  :None
//#***************************************************************************
function ezd_actionByArrowKeyPress(e, dir) {
  var el = ezd_getElementFromEvent(e);
  var itemName = null;

  if (el.name === undefined) {
    return;
  }

  if (el.name.length !== 0) {
    itemName = el.name;
  } else {
    if (el.id !== undefined) {
      itemName = el.id;
    }
  }

  if (el.type === "radio" || el.tagName === "SELECT") {
    return;
  }

  var actionInfo = ezd_getActionInfo(el, dir, "ArrowKeyAction");
  if (actionInfo === null) {
    return;
  }
  if (typeof actionInfo[0] === "number") {
    actionInfo[0] = "" + actionInfo[0];
  }

  if (actionInfo[0] === "2") {
    if (actionInfo[1] === undefined) {
      alert(
        "It is an operation definition error. The ID attribute is not set. \n The operation definition of key[" +
          itemName +
          "]or[ArrowKeyAction]is illegal."
      );
      return;
    }
    var param = actionInfo[1].split(",");

    var next;
    var candidate = null;
    if (param[0].substr(0, 2) === "NM") {
      candidate = document.getElementsByName(param[0].substr(3));
    } else {
      candidate = document.getElementById(param[0].substr(3));
    }

    if (candidate !== null && candidate.length !== 0) {
      if (param[1] === undefined) {
        if (candidate.type === "button") {
          next = candidate;
        } else {
          next = candidate[0];
        }
      } else {
        next = candidate[param[1]];
      }
    }

    if (next === null) {
      alert(
        "The Field in the moving destination is not found. \n The Fieldof " +
          param[0].substr(0, 2) +
          " attribute[" +
          param[0].substr(3) +
          "]was not found. "
      );
      return;
    } else {
      ezd_stopEventBubble(e);
      next.focus();
      if (ezd_isTextEditElement(next)) {
        next.select();
      }
    }
  } else if (actionInfo[0] === "1") {
    switch (dir) {
      case 0:
        ezd_moveFocusByShortestDistance(e, 0);
        break;
      case 1:
        ezd_moveFocusByShortestDistance(e, 1);
        break;
      case 2:
        ezd_emulateTabkeyPress(e, 1, false);
        break;
      case 3:
        ezd_emulateTabkeyPress(e, 0, false);
        break;
      default:
    }
  } else if (actionInfo[0] === "0") {
  } else {
    alert(
      "It is an operation definition error. Please confirm the operation definition. \n The operation definition of key[ArrowKeyAction]is illegal. "
    );
  }
}

//#***************************************************************************
//#Function name  :ezd_keydownHandlerForCursorFreeAndTabEmulation()
//#Function    :Keydown event handler for arrow key cursor control
//#       This event handler register right and left arrow key key emulation
//#       upper and lower key free cursor effective become.
//#Argument    :e:Event(event)
//#Return value  :None
//#***************************************************************************
function ezd_keydownHandlerForCursorFreeAndTabEmulation(e) {
  if (!e) {
    e = window.event;
  }
  if (e.altKey || e.ctrlKey || e.shiftKey) {
    return;
  }
  var el = ezd_getElementFromEvent(e);
  var alive = ezd_isEditableElement(el);

  switch (e.keyCode) {
    case 37:
      if (alive && ezd_isTextEditElement(el) && (ezd_getTextCursorPos(el) !== 0 || ezd_getSelectedText(el, e) !== "")) {
        break;
      }
      ezd_actionByArrowKeyPress(e, 2);
      break;
    case 39:
      if (alive && ezd_isTextEditElement(el) && (ezd_getTextCursorPos(el) !== el.value.length || ezd_getSelectedText(el, e) !== "")) {
        break;
      }
      ezd_actionByArrowKeyPress(e, 3);
      break;
    case 38:
      if (alive && el.tagName === "SELECT") {
        break;
      }
      if (alive && ezd_isMultiLineTextEditElement(el)) {
        break;
      }
      ezd_actionByArrowKeyPress(e, 0);
      break;
    case 40:
      if (alive && el.tagName === "SELECT") {
        break;
      }
      if (alive && ezd_isMultiLineTextEditElement(el)) {
        break;
      }
      ezd_actionByArrowKeyPress(e, 1);
      break;
    default:
      break;
  }
}

//#***************************************************************************
//#Function name  :ezd_throughFunctionKeyDownHandler()
//#Function    :Evasion of registered function key event
//#Argument    :None
//#Return value  :None
//#***************************************************************************
function ezd_throughFunctionKeyDownHandler(e) {
  e = e || window.event;
  if (!g_ezdOrgKeyDownHandler) {
    return false;
  }

  if (e.keyCode < 112 || 123 < e.keyCode) {
    return g_ezdOrgKeyDownHandler(e);
  }
}

//#***************************************************************************
//#Function name  :ezd_customLoadHandler()
//#Function    :Onload event handler for customJS
//#          When HTML is initialized, each event handler is registered.
//#Argument    :e:Event(event)
//#Return value  :None
//#***************************************************************************
var g_ezdOrgKeyDownHandler;
export function ezd_customLoadHandler() {
  var _body = document.body;
  //------------------------------------------------------
  // Setting of various event handlers
  //------------------------------------------------------
  //  ezd_elementEmphasis();
  ezd_addListener(_body, "keypress", ezd_keypressHandlerForAutoEscape, false);
  if (_body.onkeydown) {
    g_ezdOrgKeyDownHandler = _body.onkeydown;
    _body.onkeydown = ezd_throughFunctionKeyDownHandler;
  }
  ezd_addListener(document, "keydown", ezd_keydownHandlerForFunctioKeyEmulation, false);
  ezd_addListener(document, "keydown", ezd_keydownHandlerForEnterKeyAction, false);
  ezd_addListener(document, "keydown", ezd_keydownHandlerForCursorFreeAndTabEmulation, false);
}
g_ezdDspAppOrder.addExecFunctionInfo(ezd_customLoadHandler, [], 10);

/* js#10
 * balloontip.js Copyright FUJITSU LIMITED 2007-2007
 * System name : Common infrastructure component
 * Outline:
 *  [Name]It is JavaScript for the balloon chip processing file.
 *  [Classification]112, screen application control
 *  [Attribute](public) (S)
 *Release:
 * NO  Date      Ver.  Updated By  Content
 *  1  04/30/08  1.0   EZD         English localization
 *  2  05/14/09        K.Tajima    [S08138]
 */
var g_first_balloontip = true;
var g_balloontipWidth = 300;
var g_balloontip;
var EZD_IFRM = "ezd_ifrm";
var EZD_BTC = "ezd_btc";

//#***************************************************************************
//#Function Name:ezd_balloonTipLoadHandler()
//#Functionality:The event handler is registered to balloon chip display Element in the listener.
//#Argument     :None
//#Return Code  :None
//#***************************************************************************
function ezd_balloonTipLoadHandler() {
  ezd_addBalloonTipLoadHandler(document.getElementsByTagName("input"));
  ezd_addBalloonTipLoadHandler(document.getElementsByTagName("select"));
  ezd_addBalloonTipLoadHandler(document.getElementsByTagName("TEXTAREA"));
  ezd_addScrollHandler(document.getElementsByTagName("DIV"));
}

//#***************************************************************************
//#Function Name:ezd_addScrollHandler()
//#Functionality:The scroll event handler is registered in the listener.
//#Argument     :None
//#Return Code  :None
//#***************************************************************************
function ezd_addScrollHandler(eles) {
  for (var i = 0; i < eles.length; i++) {
    ezd_addListener(eles[i], "scroll", ezd_onscrollHandler, false);
  }
}

//#***************************************************************************
//#Function Name:ezd_addBalloonTipLoadHandler()
//#Functionality:If it is balloon chip display Element, the event handler is registered in the listener.
//#Argument     :elems  :Element array
//#Return Code  :None
//#***************************************************************************
export const ezd_addBalloonTipLoadHandler = (elems) => {
  // console.log("ezd_addBalloonTipLoadHandler ..length<" + elems.length + ">");
  for (var i = 0; i < elems.length; i++) {
    // START -09/16/2011 Sumida for cross browser
    //        if(elems[i].ezd_title !== undefined) {
    // console.log("ezd_addBalloonTipLoadHandler ..elems[i].getElementById()<" + elems[i].getElementById + ">");
    // console.log("ezd_addBalloonTipLoadHandler ..elems[i].getElementByName()<" + elems[i].getElementByName + ">");
    // console.log("ezd_addBalloonTipLoadHandler ..elems[i].getAttribute(ezd_title)<" + elems[i].getAttribute("ezd_title") + ">");
    if (elems[i].getAttribute("ezd_title") !== null) {
      // END   -09/16/2011 Sumida for cross browser
      // console.log("ezd_addBalloonTipLoadHandler ..length<" + elems[i] + ">  ezd_balloonFocusinHandler");
      // ezd_addListener(elems[i], "focus", ezd_balloonFocusinHandler, false);
      // ezd_addListener(elems[i], "blur", ezd_balloonFocusoutHandler, false);
      ezd_addListener(elems[i], "onFocus", ezd_balloonFocusinHandler, false);
      ezd_addListener(elems[i], "onBlur", ezd_balloonFocusoutHandler, false);
    }
  }
};

//#***************************************************************************
//#Function Name:ezd_balloonFocusinHandler
//#Functionality:Event handler for balloon chip at focus Inn
//#Argument     :e    :Event
//#Return Code  :None
//#***************************************************************************
function ezd_balloonFocusinHandler(e) {
  var el = ezd_getElementFromEvent(e);
  // console.log("ezd_balloonFocusinHandler......e1<" + el + ">");
  ezd_showBalloonTooTip(el);
}

//#***************************************************************************
//#Function Name:ezd_balloonFocusoutHandler
//#Functionality:Event handler for balloon chip at focus out
//#Argument     :e    :Event
//#Return Code  :None
//#***************************************************************************
function ezd_balloonFocusoutHandler(e) {
  var el = ezd_getElementFromEvent(e);
  ezd_hideBalloonTooTip(el);
}

//#***************************************************************************
//#Function Name:ezd_showBalloonTooTip
//#Functionality:Balloon chip display processing(method of opening to the public)
//#          If the message (The format is Msg code: Msg text) is set to the ezd_title
//#          attribute of Element, the balloon chip is displayed.
//#Argument     :el   :Event generation former Element
//#Return Code  :None
//#***************************************************************************
export function ezd_showBalloonTooTip(el) {
  var titleMsg = el.getAttribute("ezd_title");
  // console.log("ezd_showBalloonTooTip.....titleMsg<" + titleMsg + ">");
  if (!titleMsg) {
    return;
  }

  var idxOfDelimit = titleMsg.indexOf(":");
  // console.log("ezd_showBalloonTooTip.....idxOfDelimit<" + idxOfDelimit + ">");
  if (idxOfDelimit < 0) {
    return;
  }

  var code = titleMsg.substring(0, idxOfDelimit);
  var msg = titleMsg.substring(idxOfDelimit + 1);
  // console.log("ezd_showBalloonTooTip.....code<" + code + ">");
  // console.log("ezd_showBalloonTooTip.....msg<" + msg + ">");
  // console.log("ezd_showBalloonTooTip.....g_first_balloontip<" + g_first_balloontip + ">");

  if (g_first_balloontip) {
    g_first_balloontip = false;
    initBalloontip();
  }

  if (isIE6()) {
    // console.log("ezd_showBalloonTooTip.....block");
    document.getElementById(EZD_IFRM).style.display = "block";
  }

  // console.log("ezd_showBalloonTooTip.....showBalloontip");
  showBalloontip(el, code, msg);
}

//#***************************************************************************
//#Function Name:ezd_hideBalloonTooTip
//#Functionality:Balloon chip non-display processing(method of opening to the public)
//#Argument     :el   :Event generation former Element
//#Return Code  :None
//#***************************************************************************
export function ezd_hideBalloonTooTip(el) {
  var d = document.getElementById(EZD_BTC);
  while (d !== null && d.childNodes !== null && d.childNodes.length > 0) {
    d.removeChild(d.firstChild);
  }

  if (isIE6()) {
    document.getElementById(EZD_IFRM).style.display = "none";
  }
}

export function ezd_hideBalloonTooTipWithoutEvent() {
  var d = document.getElementById(EZD_BTC);
  while (d !== null && d.childNodes !== null && d.childNodes.length > 0) {
    d.removeChild(d.firstChild);
  }

  if (isIE6()) {
    document.getElementById(EZD_IFRM).style.display = "none";
  }
}

//#***************************************************************************
//#Function Name:initBalloontip
//#Functionality:Balloon chip initialization
//#Argument     :None
//#Return Code  :None
//#***************************************************************************
function initBalloontip() {
  addBalloontipCss();

  if (isIE6()) {
    var f = document.createElement(
      "<iframe scrolling='no' frameborder='0' " +
        "style='position:absolute; top:8px; left:8px;" +
        "display:block; width: 300px; height: 500px;'>" +
        "</iframe>"
    );
    f.id = EZD_IFRM;
    f.setAttribute("id", EZD_IFRM);
    document.getElementsByTagName("body")[0].appendChild(f);
  }

  let h = document.createElement("span");
  h.id = EZD_BTC;
  h.setAttribute("id", EZD_BTC);
  h.style.position = "absolute";
  document.getElementsByTagName("body")[0].appendChild(h);

  g_balloontip = createBalloontip("code", "text");
}

//#***************************************************************************
//#Function Name:createBalloontip
//#Functionality:Balloon chip generation
//#Argument     :code   :Character string of text node
//#         :text       :Character string of text node
//#Return Code  :balloontip
//#***************************************************************************
function createBalloontip(code, text) {
  var balloontip, b, s;
  balloontip = CreateEl("span", "balloontip");

  s = CreateEl("span", "RTupper");
  s.appendChild(document.createTextNode(code));
  balloontip.appendChild(s);

  b = CreateEl("span", "RTlower");
  b.appendChild(document.createTextNode(text));
  balloontip.appendChild(b);

  setOpacity(balloontip);

  return balloontip;
}

//#***************************************************************************
//#Function Name:showBalloontip
//#Functionality:Balloon chip display
//#Argument     :el :Element
//#        :code    :Message code
//#        :text    :Message information character string
//#Return Code  :None
//#***************************************************************************
function showBalloontip(el, code, text) {
  g_balloontip._target = el;

  g_balloontip.childNodes[0].innerText = code;
  g_balloontip.childNodes[1].innerText = text;
  g_balloontip.childNodes[0].textContent = code;
  g_balloontip.childNodes[1].textContent = text;

  ezd_getABSCoord(el);
  var btcEl = document.getElementById(EZD_BTC);
  btcEl.appendChild(g_balloontip);
  var classString = calcRightOrLeft(el, g_balloontip);
  classString += calcTopOrBottom(el, g_balloontip);

  g_balloontip.childNodes[0].className = classString + "upper";
  g_balloontip.childNodes[1].className = classString + "lower";

  btcEl.style.top = g_balloontip._top + "px";
  btcEl.style.left = g_balloontip._left + "px";

  if (isIE6()) {
    var frEl = document.getElementById(EZD_IFRM);
    frEl.style.top = g_balloontip._top + "px";
    frEl.style.left = g_balloontip._left + "px";
    frEl.style.height = g_balloontip.offsetHeight + "px";
  }
}

//#***************************************************************************
//#Function Name:ezd_getABSCoord
//#Functionality:Positional calculation of Element
//#Argument     :el      :Element
//#Return Code  :None
//#***************************************************************************
function ezd_getABSCoord(ele) {
  var offX = 0,
    offY = 0;
  var parent = ele.offsetParent;

  while (parent && parent.tagName !== "BODY") {
    offX += parent.offsetLeft;
    if (parent.scrollLeft !== undefined) {
      offX -= parent.scrollLeft;
    }
    offY += parent.offsetTop;
    if (parent.scrollTop !== undefined) {
      offY -= parent.scrollTop;
    }
    parent = parent.offsetParent;
  }
  ele._absLeft = offX + ele.offsetLeft;
  ele._absTop = offY + ele.offsetTop;
}

//#***************************************************************************
//#Function Name:calcRightOrLeft
//#Functionality:Right and left positional calculation
//#Argument     :el     :Element
//#        :balloontip  :Balloon chip
//#Return Code  :The right side:"R" The left side:"L"
//#***************************************************************************
function calcRightOrLeft(el, balloontip) {
  var bodyWidth = document.getElementsByTagName("body")[0].offsetWidth;
  // console.log("!!!bodyWidth<" + bodyWidth + ">");
  var posX = el._absLeft + el.offsetWidth * 0.5;
  var classString = "R";

  if (posX + g_balloontipWidth > bodyWidth && bodyWidth - posX < posX) {
    posX = posX - g_balloontipWidth;
    classString = "L";
  }
  balloontip._left = posX;

  return classString;
}

//#***************************************************************************
//#Function Name:calcTopOrBottom
//#Functionality:Upper and lower positional calculation
//#Argument     :el     :Element
//#        :balloontip  :Balloon chip
//#Return Code  :The upper part:"T" The lower side:"B"
//#***************************************************************************
function calcTopOrBottom(el, balloontip) {
  var balloontipHeight = balloontip.offsetHeight;
  var posY = el._absTop - balloontipHeight;
  var bodyHeight = document.getElementsByTagName("body")[0].offsetHeight;
  // console.log("!!!  bodyHeight<" + bodyHeight + ">!!!");
  var classString = "T";

  if (posY < 0 && bodyHeight - (el._absTop + el.offsetHeight) > posY) {
    posY = el._absTop + el.offsetHeight;
    classString = "B";
  }
  balloontip._top = posY;

  return classString;
}

//#***************************************************************************
//#Function Name:setOpacity
//#Functionality:Transparency degree setting
//#Argument     :el     :Element
//#Return Code  :None
//#***************************************************************************
function setOpacity(el) {
  el.style.filter = "alpha(opacity:88)";
  el.style.KHTMLOpacity = "0.88";
  el.style.MozOpacity = "0.88";
  el.style.opacity = "0.88";
}

//#***************************************************************************
//#Function Name:CreateEl
//#Functionality:Generation of block element
//#Argument     :t      :Made element name
//#             :c      :Class name
//#Return Code  :element
//#***************************************************************************
function CreateEl(t, c) {
  var x = document.createElement(t);
  x.className = c;
  x.style.display = "block";

  return x;
}

//#***************************************************************************
//#Function Name:addBalloontipCss
//#Functionality:Addition of Style Sheet for balloon chip
//#Argument     :None
//#Return Code  :None
//#***************************************************************************
function addBalloontipCss() {
  var l = CreateEl("link");
  l.setAttribute("type", "text/css");
  l.setAttribute("rel", "stylesheet");
  l.setAttribute("media", "screen");
  document.getElementsByTagName("head")[0].appendChild(l);
}

//#***************************************************************************
//#Function Name:isIE6()
//#Functionality:It is returned whether it is IE6.
//#Argument     :None
//#Return Code  :true:IE6 false:Excluding IE6
//#***************************************************************************
function isIE6() {
  return navigator.userAgent.indexOf("MSIE 6.") >= 0;
}

ezd_addListener(window, "load", ezd_balloonTipLoadHandler, false);
ezd_addListener(window, "scroll", ezd_onscrollHandler, false);

//#***************************************************************************
//#Function Name:ezd_onscrollHandler()
//#Functionality:Scroll event handler.
//#Argument     :e:Event
//#Return Code  :None
//#***************************************************************************
function ezd_onscrollHandler(e) {
  if (g_balloontip !== undefined && g_balloontip !== null && g_balloontip._target !== undefined && g_balloontip._target !== null) {
    ezd_hideBalloonTooTip(g_balloontip._target);
  }
}
// balloonTip

// clossBowser
//#**********************************************************
//# CrossBrowser Functions
//# Usage : CrossBrowser.functionName(val)
//#**********************************************************
var CrossBrowser = {
  //#**********************************************************
  //# Function name:getBrowserEvent
  //# Argument     :e : event
  //#**********************************************************
  getBrowserEvent: function (e) {
    var event = window.event;
    if (!event) {
      event = e;
    }
    return event;
  },
  //#**********************************************************
  //# Function name:getSelectionText
  //# Argument     :e : event
  //# Return value: Selection String
  //#**********************************************************
  getSelectionText: function (e) {
    var selection = checkBrowser.isIe() ? document.selection.createRange().text : window.getSelection().toString();
    if (e && (e.target.selectionStart || e.target.selectionEnd)) {
      var start = e.target.selectionStart;
      var end = e.target.selectionEnd;
      if (start !== end) {
        selection = e.target.value.substr(start, end - start);
      }
    }
    return selection;
  },
  //#**********************************************************
  //# Function name:doCut
  //# Argument     :event:  event
  //#   Cut and Copy to CripBord
  //#**********************************************************
  doCut: function (event) {
    if (checkBrowser.isIe()) {
      if (document.selection.type === "Text") {
        document.selection.createRange().execCommand("Cut");
      }
    } else {
      var ev = PopUp.selectval;
      var start = ev.target.value.substr(0, ev.target.selectionStart);
      var end = ev.target.value.slice(ev.target.selectionEnd);
      var text = CrossBrowser.getSelectionText(ev);
      CrossBrowser.copyFfClipboard(text);
      ev.target.value = start + end;
      cancelEvent(event);
    }
    PopUp.hide();
  },
  //#**********************************************************
  //# Function name:doCopy
  //# Argument     :event:  event
  //#   Copy to CripBord
  //#**********************************************************
  doCopy: function (event) {
    if (checkBrowser.isIe()) {
      if (document.selection.type === "Text") {
        document.selection.createRange().execCommand("Copy");
      }
    } else {
      var text = CrossBrowser.getSelectionText(PopUp.selectval);
      CrossBrowser.copyFfClipboard(text);
      cancelEvent(event);
    }
    PopUp.hide();
  },
  //#**********************************************************
  //# Function name:doPaste
  //# Argument     :event :event
  //#   Copy from CripBord
  //#**********************************************************
  doPaste: function (event) {
    if (checkBrowser.isIe()) {
      document.selection.createRange().execCommand("Paste");
    } else {
      // For FF Only
      var clip = CrossBrowser.getFfClipboard();
      if (!clip) return;
      var ev = PopUp.selectval;
      var start = ev.target.value.substr(0, ev.target.selectionStart);
      var end = ev.target.value.slice(ev.target.selectionEnd);
      ev.target.value = start + clip + end;
      cancelEvent(event);
    }
    PopUp.hide();
  },
  //#**********************************************************
  //# Function name:doPrint
  //# Argument     :event : event
  //#   Print
  //#**********************************************************
  doPrint: function (event) {
    PopUp.hide();
    window.print();
  },
  //#**********************************************************
  //# Function name:doPrintpreview
  //# Argument     :event : event
  //#   Open print preview window
  //#   This work IE only
  //#**********************************************************
  doPrintpreview: function (event) {
    PopUp.hide();
    if (checkBrowser.isIe()) {
      //  For Internet Exproler
      /*
       *  OLECMDID values:
       * 6 - print
       * 7 - print preview
       * 1 - open window
       * 4 - Save As
       */
      var OLECMDID = 7;
      var PROMPT = 1; // 2 DONTPROMPTUSER
      var WebBrowser = '<OBJECT ID="WebBrowser1" WIDTH=0 HEIGHT=0 CLASSID="CLSID:8856F961-340A-11D0-A96B-00C04FD705A2"></OBJECT>';

      document.body.insertAdjacentHTML("beforeEnd", WebBrowser);
      WebBrowser.ExecWB(OLECMDID, PROMPT);
      WebBrowser.outerHTML = "";
    } else {
      //CrossBrowser.printpv();
    }
  },
  //#**********************************************************
  //# Function name:copyFfClipboard
  //# Argument     :text  : String
  //#   Private function to use Firefox Clipbord
  //#**********************************************************
  copyFfClipboard: function (text) {
    // try {
    //   netscape.security.PrivilegeManager.enablePrivilege("UniversalXPConnect");
    // } catch (e) {
    //   //alert("You need set 'signed.applets.codebase_principal_support=true'at about:config'");
    //   return false;
    // }
    // var clip = Components.classes["@mozilla.org/widget/clipboard;1"].createInstance(Components.interfaces.nsIClipboard);
    // if (!clip) return;
    // var trans = Components.classes["@mozilla.org/widget/transferable;1"].createInstance(Components.interfaces.nsITransferable);
    // if (!trans) return;
    // trans.addDataFlavor("text/unicode");
    // var str = {};
    // // var len = {};
    // str = Components.classes["@mozilla.org/supports-string;1"].createInstance(Components.interfaces.nsISupportsString);
    // var copytext = text;
    // str.data = copytext;
    // trans.setTransferData("text/unicode", str, copytext.length * 2);
    // var clipid = Components.interfaces.nsIClipboard;
    // if (!clip) return false;
    // clip.setData(trans, null, clipid.kGlobalClipboard);

    // Select the text field
    text.select();
    text.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(text);
  },
  //#**********************************************************
  //# Function name:getFfClipboard
  //# Argument     :text  String
  //#   Private function to use Firefox Clipbord
  //#**********************************************************
  getFfClipboard: function () {
    // try {
    //   netscape.security.PrivilegeManager.enablePrivilege("UniversalXPConnect");
    // } catch (e) {
    //   //
    //   //alert("You need set 'signed.applets.codebase_principal_support=true'at about:config'");
    //   return false;
    // }
    // var clip = Components.classes["@mozilla.org/widget/clipboard;1"].createInstance(Components.interfaces.nsIClipboard);
    // if (!clip) return;
    // var trans = Components.classes["@mozilla.org/widget/transferable;1"].createInstance(Components.interfaces.nsITransferable);
    // if (!trans) return;
    // trans.addDataFlavor("text/unicode");
    // clip.getData(trans, clip.kGlobalClipboard);
    // var str = {};
    // var len = {};
    // trans.getTransferData("text/unicode", str, len);
    // if (str) {
    //   str = str.value.QueryInterface(Components.interfaces.nsISupportsString);
    //   pastetext = str.data.substring(0, len.value / 2);
    // }
    return navigator.clipboard.readText().then((clipText) => (document.querySelector(".cliptext").innerText = clipText));
    // return pastetext;
  },
  //#**********************************************************
  //# Function name:setZoom
  //# Argument     :elm element
  //# Argument     :rate  zoom rate(String)
  //#**********************************************************
  setZoom: function (elm, rate) {
    if (checkBrowser.isIe()) {
      elm.style.zoom = rate;
    } else if (checkBrowser.isFirefox()) {
      // FF
      elm.style.MozTransform = "scale(" + rate + ", " + rate + " )";
      elm.style.MozTransformOrigin = "0 0";
    } else {
      // WebKit
      elm.style.WebkitTransform = "scale(" + rate + ", " + rate + " )";
      elm.style.WebkitTransformOrigin = "0 0";
    }
  },
  //#**********************************************************
  //# Function name:setZoom
  //# Argument     :elm   element
  //# Return Value :String  zoom rate
  //#**********************************************************
  getZoom: function (elm) {
    if (checkBrowser.isIe()) {
      return elm.style.zoom;
    } else {
      var str = null;
      if (checkBrowser.isFirefox() && elm.style.MozTransform) {
        // FF
        str = elm.style.MozTransform;
      } else if (checkBrowser.isWebkit() && elm.style.WebkitTransform) {
        // WebKit
        str = elm.style.WebkitTransform;
      }
      if (str) {
        var index = str.indexOf("scale(");
        var scale = str.substring(index + 6);
        var zoomStr = scale.split(",")[0];
        return parseFloat(zoomStr);
      }
      return null;
    }
  },
};
// END Cross browser functions

//START PopUp ContextMenu functions
//#**********************************************************
//# PopUp Functions
//# Usage : PopUp.functionName(val)
//#**********************************************************
var PopUp = {
  selectval: null, // before action event
  //#**********************************************************
  //# Function name:getPopUpElement
  //# Return Value :Element ID:popupMenu element
  //#**********************************************************
  getPopUpElement: function () {
    return document.getElementById("popupMenu");
  },

  //#**********************************************************
  //# Function name:initialize
  //# Argument     :menuHtml  PopUpMenuHTMLString (String)
  //# Argument     :privev    (event)
  //# Return Value :null
  //#**********************************************************
  initialize: function (menuHtml, privev) {
    if (privev) PopUp.selectval = privev;
    if (checkBrowser.isIe()) {
      // For InternetExplorer
      CONTEXTMENU_OBJ = window.createPopup();
      CONTEXTMENU_OBJ.document.body.innerHTML = menuHtml;
      return;
    }
    // For FireFox
    var old = PopUp.getPopUpElement();
    if (!old) {
      var div = document.createElement("div");
      div.id = "popupMenu";
      div.innerHTML = menuHtml;
      document.body.appendChild(div);
    } else {
      old.removeChild(old.childNodes.item(0));
      old.innerHTML = menuHtml;
    }
  },

  //#**********************************************************
  //# Function name:show
  //# Argument     :e (event)
  //# Argument     :menuHeight
  //#   show PopUpMenu
  //#**********************************************************
  show: function (e, menuHeight) {
    if (checkBrowser.isIe()) {
      // For InternetExplorer
      CONTEXTMENU_OBJ.show(e.clientX + 2, e.clientY + 2, CONTEXT_MENU_WIDTH, menuHeight, document.body);
      return;
    }
    // For FireFox
    var d = PopUp.getPopUpElement();
    if (!d) return;
    ezd_addListener(document, "click", PopUp.isPopUp, false);
    var zoom = CrossBrowser.getZoom(document.getElementsByTagName("body")[0]);
    if (!zoom || zoom === "") zoom = 1;
    d.style.left = parseInt(e.clientX / zoom) + 2 + "px";
    d.style.top = parseInt(e.clientY / zoom) + 2 + "px";
    d.style.zindex = 9996;
    d.style.width = CONTEXT_MENU_WIDTH + "px";
    d.style.display = "block";
    d.style.position = "absolute";
  },

  //#**********************************************************
  //# Function name:hide
  //#   hide PopUpMenu
  //#**********************************************************
  hide: function () {
    if (checkBrowser.isIe()) {
      // For InternetExplorer
      CONTEXTMENU_OBJ.hide();
      return;
    }
    // For FireFox
    var d = PopUp.getPopUpElement();
    if (!d) return;
    ezd_removeListener(document, "click", PopUp.isPopUp, false);
    d.style.display = "none";
  },

  //#**********************************************************
  //# Function name:isPopUp
  //#**********************************************************
  isPopUp: function (event) {
    if (PopUp.isOutOfPopup(event)) {
      PopUp.hide();
    }
    return false;
  },

  //#**********************************************************
  //# Function name:isOutOfPopup
  //#
  //#**********************************************************
  isOutOfPopup: function (e) {
    return true;
  },
};
// END   PopUp contextMenu functions

// START ScreenCover functions
var ScreenCover = {
  getCoverElement: function () {
    return document.getElementById("screenCover");
  },
  show: function () {
    var cover = ScreenCover.getCoverElement();
    if (!cover) {
      cover = document.createElement("div");
      cover.id = "screenCover";
      document.body.appendChild(cover);
    }
    if (checkBrowser.isiPad()) {
      cover.style.width = document.body.scrollWidth + "px";
      cover.style.height = document.body.scrollHeight + 52 + "px";
    } else {
      cover.style.width = "100%";
      cover.style.height = "100%";
    }
    cover.style.position = "absolute";
    cover.style.zindex = "999";
    cover.style.background = "#0c0c0c";
    cover.style.top = "0px";
    cover.style.left = "0px";
    cover.style.opacity = "0.6";
    cover.style.filter = "alpha(opacity=60)";
    cover.style.display = "block";
  },
  hide: function () {
    var cover = ScreenCover.getCoverElement();
    if (!cover) return;
    cover.style.display = "none";
  },
  isCover: function () {},
};
// End   ScreenCover functions

// START checkBrowser functions
export var checkBrowser = {
  isFirefox: function () {
    var str = navigator.userAgent.toLowerCase();
    return str.indexOf("firefox") !== -1 ? true : false;
  },
  isChrome: function () {
    var str = navigator.userAgent.toLowerCase();
    return str.indexOf("chrome/") !== -1 ? true : false;
  },
  isSafari: function () {
    var str = navigator.userAgent.toLowerCase();
    return str.indexOf("safari") !== -1 ? true : false;
  },
  isWebkit: function () {
    var str = navigator.userAgent.toLowerCase();
    return str.indexOf("webkit") !== -1 ? true : false;
  },
  isiPad: function () {
    var str = navigator.userAgent.toLowerCase();
    return str.indexOf("ipad") !== -1 ? true : false;
  },
  isIe: function () {
    // var str = navigator.userAgent.toLowerCase();
    return document.all ? true : false;
  },
};
// END checkBrowser functions
// END   -09/28/2011 M.Yaguchi for cross browser

var utilFunc = {
  //#***************************************************************************
  //#Function utilFunc.setIdTagName()
  //#Functionality:Add id
  //#Return Code  :None
  //#***************************************************************************
  setIdTagName: function (name) {
    try {
      var elms = document.getElementsByName(name);
      if (elms.length < 1) return;
      var elm = elms[0];
      elm.id = name;
    } catch (e) {
      alert(e);
      return;
    }
  },
  //#***************************************************************************
  //#Function utilFunc.getPageId()
  //#Functionality:Get Page id
  //#Return Code  :None
  //#***************************************************************************
  getPageId: function () {
    return document.getElementById("pageIDArea").textContent;
  },
  //#***************************************************************************
  //#Function utilFunc.margeSort
  //#Functionality:margesort
  //#Argument: array - Array
  //#          cfunc - compareFunction
  //#ReturnValue  :Array
  //#***************************************************************************
  margeSort: function (array, cfunc) {
    if (!cfunc)
      cfunc = function (a, b) {
        return a - b;
      };
    return (function self(list) {
      if (list.length < 2) return list;
      var left = self(list.splice(0, list.length >>> 1)),
        right = self(list.splice(0));
      while (left.length && right.length) list.push(cfunc(left[0], right[0]) <= 0 ? left.shift() : right.shift());
      return list.concat(left, right);
    })(array);
  },
};

//BalloonTip for TCEPPS
export var tceppsBalloonTipHandler = {
  allCheckElement: [],
  validateElements: [],
  validateError: function (elementName, message, code, level) {
    var elm = document.getElementById(elementName);
    elm.setAttribute("ezd_title", code + ":" + message);
    var classStr = "pErr";
    if (level === "warn") {
      classStr = "pWar";
    }
    var classNames = elm.className;
    if (classNames !== undefined && classNames && classNames !== "") {
      classNames = classNames + " " + classStr;
    } else {
      classNames = classStr;
    }
    elm.className = classNames;
    // console.log("elm<" + elm + "> classNames<" + classNames + "> ezd_title<" + code + ":" + message + "> elementName<" + elementName + ">");
    tceppsBalloonTipHandler.validateElements.push(elm);
  },
  clearBalloonTips: function () {
    var elms = tceppsBalloonTipHandler.allCheckElement;
    tceppsBalloonTipHandler.validateElements = [];
    for (var i = 0; i < elms.length; i++) {
      var id = elms[i];
      var elm = document.getElementById(id);
      elm.removeAttribute("ezd_title");
      var classNames = elm.className;
      if (classNames !== undefined && classNames && classNames !== "" && classNames !== "undefined") {
        classNames = classNames.replace("pErr", "");
        classNames = classNames.replace("pWar", "");
        elm.className = classNames;
      }
    }
  },
  addToCheckElements: function (elementName) {
    tceppsBalloonTipHandler.allCheckElement.push(elementName);
  },
};
