import client from '../api-client';

const apiHost = window.location.origin;

export interface IUIConfigInterface {
    common: {
        env: string;
        apiUrl: string;
    },
    logging: {
        level: string;
        enabled: string;
    },
    cybersource: {
        microformJs: string;
    },
    cpgmui: {
        publicKey: string;
        expire: number;
    }
}

let config: IUIConfigInterface;


export default class ConfigService {
    static loadConfig = () => {
        const url = apiHost + '/api/config/all';
        //  console.log( 'fetching config from ' + url);
        const resp = client.get(url);
        resp.then( resp => {
            const temp = resp.data.config as unknown;
            config = temp as IUIConfigInterface;
            //  console.log( 'config initialized');
        });
        return client.get(url);
    }

    static setConfig(newConfig:IUIConfigInterface) {
        config = newConfig;
    }
}
export {
    config
} ;
