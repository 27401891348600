// import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { TceppsStateInterface } from "../../interface/redux-state/TceppsStateInterface";

const initialState: TceppsStateInterface = {
  app: "",
  encryptedRequest: "",
  encryptedRequestKey: "",
  unencryptedRequest: undefined,
  token: "",
  sign: "",
  response: null,
  error: "",
  serviceOperation: "",
  enableNewCrCardFlag: "",
  cpgmuiApiUrl: "",
  cpgmuiEnv: "",
  backgroundColor: "",
};

export const tceppsSlice = createSlice({
  name: "tcepps",
  initialState,
  reducers: {
    initializeTceppsAction: (state: Draft<TceppsStateInterface>, action) => {
      state.app = action.payload.app;
      state.encryptedRequest = action.payload.encryptedRequest;
      state.encryptedRequestKey = action.payload.encryptedRequestKey;
      state.unencryptedRequest = action.payload.unencryptedRequest;
      state.token = action.payload.token;
      state.sign = action.payload.sign;
      state.serviceOperation = action.payload.serviceOperation;
      state.enableNewCrCardFlag = action.payload.enableNewCrCardFlag;
      state.cpgmuiApiUrl = action.payload.cpgmuiApiUrl;
      state.cpgmuiEnv = action.payload.cpgmuiEnv;
      state.backgroundColor = action.payload.backgroundColor;
    },
    serviceOperationAction: (state: Draft<TceppsStateInterface>, action) => {
      state.serviceOperation = action.payload.serviceOperation;
    },
    // completedInitialize: (state: Draft<TceppsStateInterface>, action) => {
    //   state.app = action.payload.app;
    //   state.encryptedRequest = action.payload.encryptedRequest;
    //   state.encryptedRequestKey = action.payload.encryptedRequestKey;
    //   state.token = action.payload.token;
    //   state.sign = action.payload.sign;
    //   state.serviceOperation = action.payload.serviceOperation;
    //   state.enableNewCrCardFlag = action.payload.enableNewCrCardFlag;
    // },
    // updateTceppsAction: (state: Draft<TceppsStateInterface>, action) => {
    //   if (action.payload.sign !== undefined) {
    //     if (state.sign === "") state.sign = action.payload.sign;
    //   } else {
    //     state.sign = state.sign;
    //     console.log("payload.sign not available.");
    //   }
    //   if (action.payload.encryptedRequest) {
    //     state.encryptedRequest = action.payload.encryptedRequest;
    //   } else {
    //     state.encryptedRequest = state.encryptedRequest;
    //     console.log("payload.encryptedRequest not available.");
    //   }

    //   if (action.payload.encryptedRequestKey) {
    //     state.encryptedRequestKey = action.payload.encryptedRequestKey;
    //   } else {
    //     state.encryptedRequestKey = state.encryptedRequestKey;
    //     console.log("payload.encryptedRequestKey not available.");
    //   }

    //   if (action.payload.token) {
    //     state.token = action.payload.token;
    //   } else {
    //     state.token = state.token;
    //     console.log("payload.token not available.");
    //   }
    //   if (action.payload.enableNewCrCardFlag) {
    //     state.enableNewCrCardFlag = action.payload.enableNewCrCardFlag;
    //   } else {
    //     state.enableNewCrCardFlag = state.enableNewCrCardFlag;
    //     console.log("payload.enableNewCrCardFlag not available.");
    //   }
    //   state.serviceOperation = action.payload.serviceOperation;
    // },
    // failureAction: (state: any, action: PayloadAction<string>) => {
    //   state.error = action.payload;
    //   state.initialized = state.initialized;
    // },
    resetTceppsAction: (state: Draft<TceppsStateInterface>) => {
      state.app = initialState.app;
      state.encryptedRequest = initialState.encryptedRequest;
      state.encryptedRequestKey = initialState.encryptedRequestKey;
      state.token = initialState.token;
      state.sign = initialState.sign;
      state.error = initialState.error;
      state.response = initialState.response;
      state.serviceOperation = initialState.serviceOperation;
      state.enableNewCrCardFlag = initialState.enableNewCrCardFlag;
      state.cpgmuiApiUrl = initialState.cpgmuiApiUrl;
      state.cpgmuiEnv = initialState.cpgmuiEnv;
      state.backgroundColor = initialState.backgroundColor;
    },
    // responseAction: (state: any, action: PayloadAction<string | null>) => {
    //   state.response = action.payload;
    //   if (action.payload == null) state.error = initialState.error;
    // },
  },
});

export const tceppsActions = tceppsSlice.actions;

export default tceppsSlice;
