import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RossPocInterface } from "../../interface/redux-state/RossPocInterface";

const initialState: RossPocInterface = {
  loading: false,
  cardBrand: "",
  cardValid: true,
  creditCardHasFocus: false,
  ccExp: "",
  customerName: "",
  customerAddress1: "",
  customerAddress2: "",
  customerCity: "",
  customerState: "",
  customerZIP: "",
  customerCountryCode: "US",
  merchantID: "",
  profileOverrideFlag: "",
  newCreditCardProfileCreation: true,
  amount: "",
  orderID: "",
  error: {
    ccExpMissing: false,
    ccExpMonthInvalid: false,
    ccExpExpired: false,
    customerNameMissing: false,
    customerAddress1Missing: false,
    cardBrandMissing: false,
    customerAddress2Missing: false, // always false.
    customerCityMissing: false,
    customerStateMissing: false,
    customerZIPMissing: false,
    customerCountryCodeMissing: false,
    creditCardMissing: false,
    creditCardInvalid: false,
    merchantIDMissing: false,
  },
};

export const rossPocSlice = createSlice({
  name: "rosspoc",
  initialState,
  reducers: {
    resetRossPoc: (state: Draft<RossPocInterface>) => {
      state.loading = initialState.loading;
      state.cardBrand = initialState.cardBrand;
      state.cardValid = initialState.cardValid;
      state.creditCardHasFocus = initialState.creditCardHasFocus;
      state.ccExp = initialState.ccExp;
      state.customerAddress1 = initialState.customerAddress1;
      state.customerAddress2 = initialState.customerAddress2;
      state.customerCity = initialState.customerCity;
      state.customerState = initialState.customerState;
      state.customerZIP = initialState.customerZIP;
      state.customerCountryCode = initialState.customerCountryCode;
      state.merchantID = initialState.merchantID;
      state.profileOverrideFlag = initialState.profileOverrideFlag;
      state.newCreditCardProfileCreation = initialState.newCreditCardProfileCreation;
      state.error = initialState.error;
      state.amount = initialState.amount;
      state.orderID = initialState.orderID;
    },
    changeRossPoc: (state: Draft<RossPocInterface>, action) => {
      state.loading = action.payload.loading;
      state.cardBrand = action.payload.cardBrand;
      state.cardValid = action.payload.cardValid;
      state.ccExp = action.payload.ccExp;
      state.customerName = action.payload.customerName;
      state.customerAddress1 = action.payload.customerAddress1;
      state.customerAddress2 = action.payload.customerAddress2;
      state.customerCity = action.payload.customerCity;
      state.customerState = action.payload.customerState;
      state.customerZIP = action.payload.customerZIP;
      state.customerCountryCode = action.payload.customerCountryCode;
      state.merchantID = action.payload.merchantID;
      state.profileOverrideFlag = action.payload.profileOverrideFlag;
      state.newCreditCardProfileCreation = action.payload.newCreditCardProfileCreation;
      state.error = action.payload.error;
      state.amount = action.payload.amount;
      state.orderID = action.payload.orderID;
    },
    cardTypeRossPoc: (state: Draft<RossPocInterface>, action) => {
      state.cardValid = action.payload.cardValid;
      state.cardBrand = action.payload.cardBrand;
    },
    creditCardFocusRossPoc: (state: Draft<RossPocInterface>, action) => {
      state.creditCardHasFocus = action.payload;
    },
    loading: (state: Draft<RossPocInterface>, action) => {
      state.loading = action.payload;
    },
    toggleCreditCardProfile: (state: Draft<RossPocInterface>) => {
      state.newCreditCardProfileCreation = !state.newCreditCardProfileCreation;
      if (!state.newCreditCardProfileCreation) {
        state.ccExp = "";
        state.customerName = "";
        state.customerAddress1 = "";
        state.customerAddress2 = "";
        state.customerCity = "";
        state.customerState = "";
        state.customerZIP = "";
        state.customerCountryCode = "";
      }
    },
    errorRossPoc: (state: Draft<RossPocInterface>, action) => {
      state.error = action.payload;
    },
  },
});

export const { resetRossPoc, changeRossPoc, cardTypeRossPoc, creditCardFocusRossPoc, loading, errorRossPoc, toggleCreditCardProfile } =
  rossPocSlice.actions;

export default rossPocSlice;
