import { S21SrScreen } from "../s21sr/S21SrScreen";
import { S21RossScreen } from "../s21ross/S21RossScreen";
import { CusasmScreen } from "../cusasm/CusasmScreen";
import { MyCanonScreen } from "../mycanon/MyCanonScreen";
import { MyCanoncpsapScreen } from "../mycanoncpsap/MyCanoncpsapScreen";
import { MyCanoncpsccScreen } from "../mycanoncpscc/MyCanoncpsccScreen";
import { MyCanonSnrScreen } from "../mycanonsnr/MyCanonSnrScreen";
import { S21CsaMyCsaScreen } from "../s21csamycsa/S21CsaMyCsaScreen";
import { S21CsaScreen } from "../s21csa/S21CsaScreen";
import { CnaScreen } from "../cna/CnaScreen";
import { CnaAchScreen } from "../cnaach/CnaAchScreen";
import { CpnetAchScreen } from "../cpnetach/CpnetAchScreen";
import { S21CsaAsccScreen } from "../s21csaascc/S21CsaAsccScreen";
import { Error404Component } from "../error404/Error404Component";
import {AuthErrorScreen} from "../error/AuthErrorScreen";
import {RossPocScreen} from "../rosspoc/RossPocScreen";

export const contentNav = [
  {
    path: "/cna/:appKey",
    content: () => <CnaScreen />,
  },
  {
    path: "/cna_ach/:appKey",
    content: () => <CnaAchScreen />,
  },
  {
    path: "/cpnet_ach/:appKey",
    content: () => <CpnetAchScreen />,
  },
  {
    path: "/s21sr/:appKey",
    content: () => <S21SrScreen />,
  },
  {
    path: "/s21ross/:appKey",
    content: () => <S21RossScreen />,
  },
  {
    path: "/cusasm/:appKey",
    content: () => <CusasmScreen />,
  },
  {
    path: "/mycanon/:appKey",
    content: () => <MyCanonScreen />,
  },
  {
    path: "/mycanoncpsap/:appKey",
    content: () => <MyCanoncpsapScreen />,
  },
  {
    path: "/mycanoncpscc/:appKey",
    content: () => <MyCanoncpsccScreen />,
  },
  {
    path: "/mycanonsnr/:appKey",
    content: () => <MyCanonSnrScreen />,
  },
  {
    path: "/s21csa_mycsa/:appKey",
    content: () => <S21CsaMyCsaScreen />,
  },
  {
    path: "/s21csa/:appKey",
    content: () => <S21CsaScreen />,
  },
  {
    path: "/s21csa_ascc/:appKey",
    content: () => <S21CsaAsccScreen />,
  },
  {
    path: "/rosspoc/:appKey",
    content: () => <RossPocScreen />,
  },
  {
    path:"/authError",
    content: ()=> <AuthErrorScreen/>
  },
  {
    path: "/",
    content: () => <div>Welcome to CPGMUI</div>,
  },
  {
    path: "*",
    content: () => <Error404Component />,
  },
];
