// import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { CpgmuiStateInterface } from "../../interface/redux-state/CpgmuiStateInterface";

const initialState: CpgmuiStateInterface = {
  app: "",
  encryptedRequest: "",
  encryptedRequestKey: "",
  unencryptedRequest: undefined,
  token: "",
  sign: "",
  response: null,
  error: "",
  serviceOperation: "",
  enableNewCrCardFlag: "",
  orbitalConnectionUsername: "",
};

export const cpgmuiSlice = createSlice({
  name: "cpgmui",
  initialState,
  reducers: {
    initializeCpgmui: (state: Draft<CpgmuiStateInterface>, action) => {
      state.app = action.payload.app;
      state.encryptedRequest = action.payload.encryptedRequest;
      state.encryptedRequestKey = action.payload.encryptedRequestKey;
      state.unencryptedRequest = action.payload.unencryptedRequest;
      state.token = action.payload.token;
      state.sign = action.payload.sign;
      state.serviceOperation = action.payload.serviceOperation;
      state.enableNewCrCardFlag = action.payload.enableNewCrCardFlag;
      state.orbitalConnectionUsername = action.payload.orbitalConnectionUsername;
    },
    serviceOperation: (state: Draft<CpgmuiStateInterface>, action) => {
      state.serviceOperation = action.payload.serviceOperation;
    },
    resetCpgmui: (state: Draft<CpgmuiStateInterface>) => {
      state.app = initialState.app;
      state.encryptedRequest = initialState.encryptedRequest;
      state.encryptedRequestKey = initialState.encryptedRequestKey;
      state.token = initialState.token;
      state.sign = initialState.sign;
      state.error = initialState.error;
      state.response = initialState.response;
      state.serviceOperation = initialState.serviceOperation;
      state.enableNewCrCardFlag = initialState.enableNewCrCardFlag;
      state.orbitalConnectionUsername = initialState.orbitalConnectionUsername;
    },
  },
});

export const { initializeCpgmui, serviceOperation, resetCpgmui } = cpgmuiSlice.actions;

export default cpgmuiSlice;
